import { SeatDetailsData } from "../../../models/tableApiTypes"
import ToolPaletSeatGuestDetails from "./ToolPaletSeatGuestDetails"
import ToolPaletSeatGuestList from "./ToolPaletSeatGuestList"

interface ToolPaletSeatProps {
  tenant: string | undefined
  seatDetails: SeatDetailsData
}

export default function ToolPaletSeat({ tenant, seatDetails }: ToolPaletSeatProps) {
  return (
    <div className="tool_palet_element">
      {seatDetails.guest !== null ? (
        <ToolPaletSeatGuestDetails tenant={tenant} seatDetails={seatDetails} />
      ) : (
        <ToolPaletSeatGuestList tenant={tenant} seatDetails={seatDetails} />
      )}
    </div>
  )
}
