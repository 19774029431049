import { WeddingInfo } from "../../../models/weddingApiTypes"
import CountDown from "./CountDown"

interface WeddingEventRecapTileProps {
  wedding: WeddingInfo | undefined
}

export default function WeddingEventRecapTile({ wedding }: WeddingEventRecapTileProps) {
  const weddingDate = wedding && wedding.weddingDate ? wedding.weddingDate : new Date()
  const weddingDay = new Date(weddingDate).toLocaleDateString("it-IT", { day: "2-digit" })
  const weddingMonth = new Date(weddingDate).toLocaleDateString("it-IT", { month: "2-digit" })
  const weddingYear = new Date(weddingDate).toLocaleDateString("it-IT", { year: "numeric" })

  return (
    <>
      {wedding && (
        <div
          style={{
            color: "#FFFFFF",
            backgroundColor: "#4C6FFF",
            fontSize: "18px",
            fontWeight: "normal",
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            height: "100%",
          }}
        >
          <div style={{ flex: "2", display: "flex", justifyContent: "center" }}>
            <img
              style={{ width: 140, borderRadius: "50%" }}
              src="https://www.matrimoniodasogno.com/wp-content/uploads/location-matrimonio.jpg"
              alt="location"
            />
          </div>
          <div style={{ flex: "1", textAlign: "center", fontWeight: "bold", fontSize: "18px", marginTop: "20px" }}>
            {wedding.location}
            <p style={{ textAlign: "center", fontSize: "12px", fontWeight: "normal" }}>
              {weddingDay}.{weddingMonth}.{weddingYear}
            </p>
          </div>
          <div
            style={{
              flex: "2",
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CountDown targetDate={new Date(weddingDate).getTime()} />
          </div>
          {wedding.frontendUrl || wedding.photosAlbumUrl ? (
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {wedding.frontendUrl ? (
                <a
                  style={{ marginBottom: "10px", width: "148px" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={wedding.frontendUrl}
                >
                  <button className="btn btn-secondary" style={{ fontWeight: "bold", width: "100%" }}>
                    Visita sito web
                  </button>
                </a>
              ) : null}

              {wedding.photosAlbumUrl ? (
                <a target="_blank" rel="noopener noreferrer" href={wedding.photosAlbumUrl}>
                  <button className="btn btn-secondary" style={{ fontWeight: "bold" }}>
                    Apri album foto
                  </button>
                </a>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}
