import { AddGuestTypeData, UpdateGuestTypeData } from "../models/guestTypeApiTypes"
import axiosDashboard from "./clientApi"

export async function addGuestTypeAPI(tenant: string | undefined, payload: AddGuestTypeData) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .post(`/dashboard/${tenant}/guesttype`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getGuestTypeDetailsAPI(tenant: string | undefined, onlyAssignable: boolean) {
  return axiosDashboard
    .get(`/dashboard/${tenant}/guesttype?onlyAssignable=${onlyAssignable}`)
    .then((response) => response.data)
}

export async function updateGuestTypeAPI(tenant: string | undefined, id: string, payload: UpdateGuestTypeData) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .put(`/dashboard/${tenant}/guesttype/${id}`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function deleteGuestTypeAPI(tenant: string | undefined, id: string) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .delete(`/dashboard/${tenant}/guesttype/${id}`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}
