import { deleteSeatGuestTableAPI } from "../../../api/tableApi"
import { useAppDispatch } from "../../../hook/useReduxHooks"
import { SeatDetailsData } from "../../../models/tableApiTypes"
import { deleteTableSeatGuest } from "../tableSlice"

interface TToolPaletSeatGuestDetailsProps {
  tenant: string | undefined
  seatDetails: SeatDetailsData
}

export default function ToolPaletSeatGuestDetails({ tenant, seatDetails }: TToolPaletSeatGuestDetailsProps) {
  const dispatch = useAppDispatch()

  const hanldeClick = () => {
    deleteSeatGuestTableAPI(tenant, seatDetails.id).then(() => {
      dispatch(deleteTableSeatGuest({ tableShapeId: seatDetails.shape.parentShapeId, tableSeatId: seatDetails.id }))
    })
  }

  return (
    <>
      {seatDetails.guest !== null ? (
        <>
          <div style={{ marginBottom: "20px" }}>Posto numero {seatDetails.order}</div>
          <div style={{ marginBottom: "20px" }}>
            <div>Occupato da:</div>
            <div>
              {seatDetails.guest.name} {seatDetails.guest.surname}
            </div>
          </div>
          {seatDetails.guest.rsvp.intolerances.find((x) => x.length > 0) !== undefined ? (
            <div style={{ marginBottom: "20px" }}>
              <div>Intolleranze:</div>
              <div>
                {seatDetails.guest.rsvp.intolerances.map((intolerance, index) => {
                  return seatDetails.guest !== null && seatDetails.guest.rsvp.intolerances.length - 1 === index
                    ? intolerance
                    : intolerance + ", "
                })}
              </div>
            </div>
          ) : null}
          <div style={{ marginBottom: "20px" }}>
            <div>Nome invito:</div>
            <div>{seatDetails.guest.invitation}</div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div>Stato:</div>
            <div>{seatDetails.guest.status}</div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div>Tipo:</div>
            <div>{seatDetails.guest.type}</div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div>Gruppo:</div>
            <div>{seatDetails.guest.group}</div>
          </div>
          <button
            type="button"
            className="btn btn-danger button-style"
            style={{ marginRight: "10px" }}
            onClick={hanldeClick}
          >
            Libera posto
          </button>
        </>
      ) : null}
    </>
  )
}
