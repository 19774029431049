import { RsvpRaw } from "./guestRsvpApiTypes"

export enum TableType {
  TABLE_RECT = "TABLE_RECT",
  TABLE_CIRCLE = "TABLE_CIRCLE",
}

export type TableBaseData = {
  name: string
  description: string
}

type ShapeBaseData = {
  x: number
  y: number
  rotationDegrees: number
  width: number
  height: number
}

export type ShapeBaseDataWithId = ShapeBaseData & {
  id: string
}

type ShapeDetailsData = ShapeBaseDataWithId & {
  type: string
  title: string
  parentShapeId: string
}

export type GuestData = {
  id: string
  name: string
  surname: string
  type: string
  group: string
  status: string
  invitation: string
  rsvp: RsvpRaw
}

export type SeatDetailsData = {
  id: string
  tableId: string
  order: number
  shape: ShapeDetailsData
  guest: GuestData | null
}

export type AddNewTableData = TableBaseData & {
  seatsNumber: number
  tableType: string
}

export type TableDetailsData = TableBaseData & {
  id: string
  layoutId: string
  shape: ShapeDetailsData
  seats: SeatDetailsData[]
}

export type UpdateShapeData = ShapeBaseData & {
  childs: ShapeBaseDataWithId[]
}

export type TablesLayoutInfo = {
  id: string
  name: string
  description: string
  approved: boolean
  sharingEnabled: boolean
}

export type TablesLayoutShareInfo = {
  title: string
}

export type TablesLayoutDetails = TablesLayoutInfo & {
  shapeId: string
  tables: TableDetailsData[]
}

export type TablesLayoutShareDetails = TablesLayoutShareInfo & {
  shapeId: string
  tables: TableDetailsData[]
}

export type TablesLayoutShareEnable = {
  enabled: boolean
}

export type TablesLayoutSvgReport = {
  svg: string | undefined
}
