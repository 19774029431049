import useCheckMobileResolution from "../hook/useCheckMobileResolution"
import menuCollapseIcon from "../icon/menu-collapse-icon.svg"

interface MenuIconProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function MenuIcon({ sidebar, showSidebar }: MenuIconProps) {
  const isMobile = useCheckMobileResolution()

  return (
    <>
      {isMobile && (
        <img
          alt="menuCollapseIcon"
          src={menuCollapseIcon}
          style={{ marginRight: "20px", cursor: "pointer" }}
          onClick={() => showSidebar(!sidebar)}
        />
      )}
    </>
  )
}
