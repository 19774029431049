import { useEffect, useMemo, useState } from "react"
import { getInvitationsAPI } from "../../api/invitationsApi"
import { InvitationInfoData } from "../../models/invitationsApiTypes"
import Select from "react-select"
import InvitationsState, { INVITATION_STATE_LABEL, INVITATION_STATE_VALUE } from "./InvitationsState"
import CustomTable from "./../CustomTable"
import "../../style/custom_style.css"
import InvitationsActions from "./InvitationsActions"

enum TABLE_HEADER {
  ID = "ID",
  NAME = "NOME INVITO",
  ACCEPTED = "SI",
  DECLINED = "NO",
  WAIT = "IN ATTESA",
  QRCODE_SCAN = "SCANSIONI QRCODE",
  PIN = "PIN",
  STATUS = "STATO INVITO",
  ACTION = "AZIONI",
}

interface SelectOptions {
  value: string
  label: string
}

interface InvitationsInfoProps {
  tenant: string | undefined
  page: string | undefined
}

export default function InvitationsInfo({ tenant, page }: InvitationsInfoProps) {
  const [actualPage, setActualPage] = useState<number>(page ? parseInt(page, 10) : 0)
  const [invitations, setInvitations] = useState<InvitationInfoData[]>()

  useEffect(() => {
    getInvitationsAPI(tenant)
      .then((result: InvitationInfoData[]) => {
        const invitations = result.map((i: InvitationInfoData) => {
          if (i.status === INVITATION_STATE_VALUE.TOBEDELIVER) i.status = INVITATION_STATE_LABEL.TOBEDELIVER
          else if (i.status === INVITATION_STATE_VALUE.DELIVERED) i.status = INVITATION_STATE_LABEL.DELIVERED
          else if (i.status === INVITATION_STATE_VALUE.PENDING) i.status = INVITATION_STATE_LABEL.PENDING
          return i
        })
        setInvitations(invitations)
      })
      .catch(() => console.error("ERROR"))
  }, [tenant])

  const SelectColumnFilter = (columns: { column: any }) => {
    const customStyles = {
      container: (provided: any) => ({
        ...provided,
      }),
      control: (provided: any) => ({
        ...provided,
        border: "1px solid #ebf2fa",
        borderRadius: 10,
        boxShadow: "none",
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        borderRadius: 16,
        color: state.selectProps.menuColor,
        padding: 8,
        fontSize: 12,
      }),
      option: (provided: any) => ({
        ...provided,
        borderRadius: 10,
        fontWeight: 100,
        letterSpacing: 0,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        height: 40,
      }),
      singleValue: (provided: any) => ({
        ...provided,
        fontSize: 12,
        fontWeight: 100,
        letterSpacing: 0,
      }),
      placeholder: (provided: any) => ({
        ...provided,
        fontSize: 12,
        fontWeight: 100,
        letterSpacing: 0,
        color: "#b5b5bd",
      }),
    }
    const options: any = useMemo(() => {
      const sets = new Set()
      columns.column.preFilteredRows.forEach((row: any) => {
        sets.add(row.values[columns.column.id])
      })
      return [...sets.values()]
    }, [columns.column.id, columns.column.preFilteredRows])

    const selectOptions: SelectOptions[] = []
    options.forEach(function (option: string) {
      const selectOption: SelectOptions = {
        value: option,
        label: option,
      }
      selectOptions.push(selectOption)
    })

    return (
      <Select
        isClearable={true}
        styles={customStyles}
        options={selectOptions}
        value={selectOptions.find((e) => e.value === columns.column.filterValue) ?? null}
        onChange={(e) => {
          columns.column.setFilter(e !== null ? e.value : "")
        }}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: TABLE_HEADER.NAME,
        accessor: "name",
        width: "25%",
        disableSortBy: true,
        Cell: (row: { cell: { value: string } }) => <div style={{ fontWeight: "bold" }}>{row.cell.value}</div>,
      },
      {
        Header: TABLE_HEADER.ACCEPTED,
        accessor: "guestsAccepted",
        width: "9%",
        disableSortBy: true,
        Cell: (row: { cell: { value: boolean } }) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <svg width="15" height="15">
              <circle cx="7" cy="6" r="4" fill="#abe098" />
            </svg>
            {row.cell.value}
          </div>
        ),
      },
      {
        Header: TABLE_HEADER.DECLINED,
        accessor: "guestsDeclined",
        width: "9%",
        disableSortBy: true,
        Cell: (row: { cell: { value: boolean } }) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <svg width="15" height="15">
              <circle cx="7" cy="6" r="4" fill="#fe5b5c" />
            </svg>
            {row.cell.value}
          </div>
        ),
      },
      {
        Header: TABLE_HEADER.WAIT,
        width: "9%",
        disableSortBy: true,
        Cell: (props: { row: { original: InvitationInfoData } }) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <svg width="15" height="15">
              <circle cx="7" cy="6" r="4" fill="#fea424" />
            </svg>
            {props.row.original.guestsInvited - props.row.original.guestsAccepted - props.row.original.guestsDeclined}
          </div>
        ),
      },
      {
        Header: TABLE_HEADER.QRCODE_SCAN,
        accessor: "qrCodeScansCounter",
        width: "10%",
        sortType: (a: any, b: any) => {
          if (a.original.qrCodeScansCounter !== null || b.original.qrCodeScansCounter !== null) {
            if (a.original.qrCodeScansCounter < b.original.qrCodeScansCounter) return 1
            else if (a.original.qrCodeScansCounter > b.original.qrCodeScansCounter) return -1
            else return 0
          }
        },
      },
      {
        Header: TABLE_HEADER.PIN,
        accessor: "reservationCode",
        width: "10%",
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.STATUS,
        accessor: "status",
        width: "18%",
        Cell: (props: { row: { original: InvitationInfoData } }) => (
          <InvitationsState
            tenant={tenant}
            invitation={props.row.original}
            invitations={invitations}
            setInvitations={setInvitations}
          />
        ),
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.ACTION,
        accessor: "",
        width: "10%",
        disableSortBy: true,
        Cell: (props: { row: { original: InvitationInfoData } }) => (
          <InvitationsActions
            tenant={tenant}
            reservationCode={props.row.original.reservationCode}
            invitation={props.row.original}
            actualPage={actualPage}
            invitations={invitations}
            setInvitations={setInvitations}
          />
        ),
      },
    ],
    [invitations, actualPage, tenant]
  )

  const data = useMemo(() => invitations, [invitations])

  return (
    <>
      {data && (
        <CustomTable
          tableWidth="100%"
          columns={columns}
          data={data}
          actualPage={actualPage}
          setActualPage={setActualPage}
          isColumnFilterEnabled={true}
          hiddenColumns={[""]}
          sortBy={[]}
          filters={[]}
        />
      )}
    </>
  )
}
