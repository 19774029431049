import { useState, WheelEvent, MouseEvent, useRef } from "react"
import { TableDetailsData } from "../../../models/tableApiTypes"
import TablesLayout from "./TablesLayout"
import "../table_style.css"

const MIN_ZOOM_VALUE = 0.3
const MAX_ZOOM_VALUE = 1.6

interface TablesGridProps {
  isMobile: boolean
  tables: TableDetailsData[]
  isPublicViewer: boolean
}

export default function TablesGrid({ isMobile, tables, isPublicViewer }: TablesGridProps) {
  const [childActive, setChildActive] = useState(false)
  const [mousePressed, setMousePressed] = useState(false)
  const [mouseDeltaPos, setMouseDeltaPos] = useState({ x: 0, y: 0 })
  const [posZoom, setPosZoom] = useState({ a: 0.45, b: 0, c: 0, d: 0.45, tx: 0, ty: 0 })
  const [posTranslate, setPosTranslate] = useState({ a: 1, b: 0, c: 0, d: 1, tx: -800, ty: -800 })
  const boxRef = useRef<HTMLDivElement>(null)

  const onMouseScrollHandler = (e: WheelEvent) => {
    const rec = boxRef.current?.getBoundingClientRect()
    const pointerX = e.clientX - (rec !== undefined ? rec.x : 0)
    const pointerY = e.clientY - (rec !== undefined ? rec.y : 0)
    if ((posZoom.a < MAX_ZOOM_VALUE && e.deltaY < 0) || (posZoom.a >= MIN_ZOOM_VALUE && e.deltaY > 0)) {
      setPosZoom({
        a: posZoom.a - e.deltaY / 2000,
        b: posZoom.b,
        c: posZoom.c,
        d: posZoom.a - e.deltaY / 2000,
        tx: posZoom.tx - pointerX * 0.25 * (-e.deltaY > 0 ? 1 : -1),
        ty: posZoom.ty - pointerY * 0.25 * (-e.deltaY > 0 ? 1 : -1),
      })
    }
  }

  const onMouseDownHandler = (e: MouseEvent) => {
    setMousePressed(true)
    setMouseDeltaPos({ x: e.clientX - posTranslate.tx, y: e.clientY - posTranslate.ty })
  }

  const onMouseUpHandler = () => {
    setMousePressed(false)
  }

  const onMouseMoveHandler = (e: MouseEvent) => {
    e.preventDefault()
    if (mousePressed && !childActive) {
      setPosTranslate({
        a: posTranslate.a,
        b: posTranslate.b,
        c: posTranslate.c,
        d: posTranslate.a,
        tx: e.clientX - mouseDeltaPos.x,
        ty: e.clientY - mouseDeltaPos.y,
      })
    }
  }

  const onTouchStartHandler = (e: React.TouchEvent) => {
    setMousePressed(true)
    const touch = e.touches[0] || e.changedTouches[0]
    setMouseDeltaPos({ x: touch.clientX - posTranslate.tx, y: touch.clientY - posTranslate.ty })
  }

  const onTouchEndHandler = () => {
    setMousePressed(false)
  }

  const onTouchMoveHandler = (e: React.TouchEvent) => {
    const touch = e.touches[0] || e.changedTouches[0]
    setPosTranslate({
      a: posTranslate.a,
      b: posTranslate.b,
      c: posTranslate.c,
      d: posTranslate.a,
      tx: touch.clientX - mouseDeltaPos.x,
      ty: touch.clientY - mouseDeltaPos.y,
    })
  }

  return (
    <div
      ref={boxRef}
      className="table_grid"
      onWheelCapture={onMouseScrollHandler}
      onMouseDown={!isMobile ? onMouseDownHandler : undefined}
      onMouseUp={!isMobile ? onMouseUpHandler : undefined}
      onMouseMove={!isMobile ? onMouseMoveHandler : undefined}
      onTouchStart={isMobile ? onTouchStartHandler : undefined}
      onTouchEnd={isMobile ? onTouchEndHandler : undefined}
      onTouchMove={isMobile ? onTouchMoveHandler : undefined}
    >
      <svg version="1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" style={{ cursor: "move" }}>
        <defs>
          <pattern id="smallGrid" width="5" height="5" patternUnits="userSpaceOnUse">
            <path d="M 5 0 L 0 0 0 5" fill="none" stroke="gray" strokeWidth="0.1" />
          </pattern>
          <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
            <rect width="40" height="40" fill="url(#smallGrid)" />
            <path d="M 40 0 L 0 0 0 40" fill="none" stroke="gray" strokeWidth="0.2" />
          </pattern>
        </defs>
        <g
          style={{
            transform: `matrix(
                  ${posTranslate.a},
                  ${posTranslate.b},
                  ${posTranslate.c},
                  ${posTranslate.d},
                  ${posTranslate.tx},
                  ${posTranslate.ty})`,
          }}
        >
          <g
            id="tablesLayoutZoom"
            style={{
              transform: `matrix(${posZoom.a}, ${posZoom.b}, ${posZoom.c}, ${posZoom.d}, ${posZoom.tx}, ${posZoom.ty})`,
            }}
          >
            <TablesLayout
              tables={tables}
              setChildActive={(isActive) => setChildActive(isActive)}
              isPublicViewer={isPublicViewer}
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
