import { AddGuestGroupData, UpdateGuestGroupData } from "../models/guestGroupApiTypes"
import axiosDashboard from "./clientApi"

export async function addGuestGroupAPI(tenant: string | undefined, payload: AddGuestGroupData) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .post(`/dashboard/${tenant}/guestgroups`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getGuestGroupsDetailsAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/guestgroups`).then((response) => response.data)
}

export async function updateGroupTypeAPI(tenant: string | undefined, id: string, payload: UpdateGuestGroupData) {
  return new Promise<boolean>((resolve, reject) => {
    axiosDashboard
      .put(`/dashboard/${tenant}/guestgroups/${id}`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function deleteGroupTypeAPI(tenant: string | undefined, id: string) {
  return new Promise<boolean>((resolve, reject) => {
    axiosDashboard
      .delete(`/dashboard/${tenant}/guestgroups/${id}`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}
