import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit"
import { AccountInfo } from "@azure/msal-browser"

type UserInfo = {
  email?: string
  firstName?: string
  lastName?: string
  displayName: string
}

interface SliceState {
  isLoading: boolean
  account?: AccountInfo
  user?: UserInfo
  error?: string
}

const initState: SliceState = {
  isLoading: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true
      state.account = undefined
      state.user = undefined
      state.error = undefined
    },
    loginSuccess: (state, action: PayloadAction<{ account: AccountInfo; user: UserInfo }>) => {
      state.isLoading = false
      state.account = action.payload.account
      state.user = action.payload.user
      state.error = undefined
    },
    loginError: {
      reducer: (state, action: PayloadAction<string>) => {
        state.isLoading = false
        state.error = action.payload
        state.account = undefined
        state.user = undefined
      },
      prepare: (error: Error) => {
        return {
          payload: error.message,
          meta: {
            error: true,
          },
        }
      },
    },
    logout: (state) => {
      state.account = undefined
      state.user = undefined
    },
    noLogin: (state) => {
      state.isLoading = false
      state.error = undefined
      state.account = undefined
      state.user = undefined
    },
  },
})

export const login = createAction("auth/startLogin")

export const { loginSuccess, loginStart, loginError, logout, noLogin } = authSlice.actions

export default authSlice.reducer
