import { WeddingInfo } from "../../../models/weddingApiTypes"
import counteInfoIcon from "../../../icon/counter_info_icon.svg"

interface WeddingCounterInfoTableTileProps {
  wedding: WeddingInfo | undefined
}

export default function WeddingCounterInfoTableTile({ wedding }: WeddingCounterInfoTableTileProps) {
  return (
    <div style={{ height: "100%", padding: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}>
      <img alt="counteInfoIcon" src={counteInfoIcon} style={{ marginRight: "24px" }} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ color: "#718096", fontWeight: "700", fontSize: "12px" }}>Totale Tavoli</div>
        <div style={{ color: "#16192C", fontWeight: "600", fontSize: "20px" }}>{wedding?.counters.tables.tables}</div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", marginLeft: "24px" }}>
        <div style={{ color: "#718096", fontWeight: "700", fontSize: "12px" }}>Invitati seduti</div>
        <div style={{ color: "#16192C", fontWeight: "600", fontSize: "20px" }}>
          {wedding?.counters.tables.sittingGuests}
        </div>
      </div>
    </div>
  )
}
