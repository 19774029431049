import axios from "axios"
import authService from "../components/auth/authService"

const axiosDashboard = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
})

axiosDashboard.interceptors.request.use(
  async (config: any) => {
    const isDashboard = config.url?.includes("dashboard")
    if (isDashboard) {
      try {
        const token = await authService.getApiToken()
        config.headers.Authorization = `Bearer ${token}`
      } catch (e) {
        console.error(e)
        Promise.reject(e)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosDashboard
