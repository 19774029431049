import { useAppSelector } from "../../../hook/useReduxHooks"
import { RootState } from "../../../store"
import { getLayoutSvgReportApi } from "../../../api/tableApi"
import { TablesLayoutSvgReport } from "../../../models/tableApiTypes"
import downloadIcon from "../../../icon/download_icon.svg"

const ZOOM_LAYOUT_COMPENSATION = 120
const LEFT_TOP_MIN_LAYOUT_COMPENSATION = 150

interface TableSvgReportCtaProps {
  tenant: string | undefined
}

export default function TableSvgReportCta({ tenant }: TableSvgReportCtaProps) {
  const tablesLayout = useAppSelector((state: RootState) => state.table.layout)

  const handleClick = () => {
    /* Create an svg to send to the backend where the tables transform:translate css property are replaced.
    For each table_cursor and table_seat_cursor html element the new coordinate are equals to the actual
    coordinate minus the coordinate of the table with the lower value of x and y */
    const tablesElements = document.getElementsByClassName("table_cursor")
    let elementsMatrixTxMin = 1000000
    let elementsMatrixTyMin = 1000000
    let leftLayoutCompensation = 0
    let topLayoutCompensation = 0
    /* Get the table coordinate with the lower value and set the left and top layout compensation */
    for (const element of tablesElements) {
      const matrix = window.getComputedStyle(element).transform.match(/matrix.*\((.+)\)/)
      const matrixValues = matrix !== null ? matrix[1].split(", ") : ""
      const radius = element.firstElementChild?.getAttribute("r")
      if (parseFloat(matrixValues[4]) < elementsMatrixTxMin) {
        elementsMatrixTxMin = parseFloat(matrixValues[4])
        if (element.id === "tableRect") {
          leftLayoutCompensation = LEFT_TOP_MIN_LAYOUT_COMPENSATION
        } else {
          leftLayoutCompensation =
            LEFT_TOP_MIN_LAYOUT_COMPENSATION +
            parseFloat(radius !== null && radius !== undefined ? (parseInt(radius) > 50 ? radius : "0") : "0")
        }
      }
      if (parseFloat(matrixValues[5]) < elementsMatrixTyMin) {
        elementsMatrixTyMin = parseFloat(matrixValues[5])
        if (element.id === "tableRect") {
          topLayoutCompensation = LEFT_TOP_MIN_LAYOUT_COMPENSATION
        } else {
          topLayoutCompensation =
            LEFT_TOP_MIN_LAYOUT_COMPENSATION +
            parseFloat(radius !== null && radius !== undefined ? (parseInt(radius) > 50 ? radius : "0") : "0")
        }
      }
    }

    /* Replace the coordinate and build the svg string to send */
    const tablesSeatElements = document.getElementsByClassName("table_seat_cursor")
    let newTableLayoutSvg = ""
    for (const element of tablesSeatElements) {
      const matrix = window.getComputedStyle(element).transform.match(/matrix.*\((.+)\)/)
      const matrixValues = matrix !== null ? matrix[1].split(", ") : ""
      const newTx = parseFloat(matrixValues[4]) - elementsMatrixTxMin + leftLayoutCompensation
      const newTy = parseFloat(matrixValues[5]) - elementsMatrixTyMin + topLayoutCompensation
      const indexStart = element.outerHTML.indexOf("translate")
      const indexEnd = element.outerHTML.substring(indexStart, 1000).indexOf(")") + indexStart + 1
      const stringToReplace = element.outerHTML.substring(indexStart, indexEnd)
      const newString = `translate(${newTx}, ${newTy})`
      newTableLayoutSvg += element.outerHTML.replace(stringToReplace, newString)
    }
    for (const element of tablesElements) {
      const matrix = window.getComputedStyle(element).transform.match(/matrix.*\((.+)\)/)
      const matrixValues = matrix !== null ? matrix[1].split(", ") : ""
      const newTx = parseFloat(matrixValues[4]) - elementsMatrixTxMin + leftLayoutCompensation
      const newTy = parseFloat(matrixValues[5]) - elementsMatrixTyMin + topLayoutCompensation
      const indexStart = element.outerHTML.indexOf("translate")
      const indexEnd = element.outerHTML.substring(indexStart, 1000).indexOf(")") + indexStart + 1
      const stringToReplace = element.outerHTML.substring(indexStart, indexEnd)
      const newString = `translate(${newTx}, ${newTy})`
      newTableLayoutSvg += element.outerHTML.replace(stringToReplace, newString)
    }

    const tablesLayoutZoomElement = document.getElementById("tablesLayoutZoom")
    const tablesLayoutZoomElementMatrix =
      tablesLayoutZoomElement !== null
        ? window.getComputedStyle(tablesLayoutZoomElement).transform.match(/matrix.*\((.+)\)/)
        : null
    const tablesLayoutZoom =
      tablesLayoutZoomElementMatrix !== null ? tablesLayoutZoomElementMatrix[1].split(", ")[0] : ""

    const tablesLayoutElement = document.getElementById("tablesLayout")?.getBoundingClientRect()
    const tablesLayoutWidth =
      tablesLayoutElement !== undefined
        ? (tablesLayoutElement.width + parseFloat(tablesLayoutZoom) * ZOOM_LAYOUT_COMPENSATION).toString()
        : 0
    const tablesLayoutHeight =
      tablesLayoutElement !== undefined
        ? (tablesLayoutElement.height + parseFloat(tablesLayoutZoom) * ZOOM_LAYOUT_COMPENSATION).toString()
        : 0

    const svg: TablesLayoutSvgReport = {
      svg:
        `<svg version="1" xmlns="http://www.w3.org/2000/svg" width="${tablesLayoutWidth}" height="${tablesLayoutHeight}" viewBox="0 0 ${tablesLayoutWidth} ${tablesLayoutHeight}">` +
        `<g style="transform: matrix(${tablesLayoutZoom}, 0, 0, ${tablesLayoutZoom}, 0, 0);">` +
        newTableLayoutSvg +
        "</g></svg>",
    }

    getLayoutSvgReportApi(tenant, tablesLayout.id, svg).then((response) => {
      const url = window.URL.createObjectURL(response)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      a.download = "tables.pdf"
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary button-style"
        style={{ marginRight: "10px" }}
        onClick={handleClick}
      >
        <img alt="downloadIcon" src={downloadIcon} style={{ marginRight: "10px" }} />
        Scarica mappa
      </button>
    </>
  )
}
