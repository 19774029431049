import {
  AddNewTableData,
  TableBaseData,
  TableDetailsData,
  TablesLayoutShareEnable,
  TablesLayoutSvgReport,
  UpdateShapeData,
} from "../models/tableApiTypes"
import { toastPromise } from "../utils/toastMethod"
import axiosDashboard from "./clientApi"
import axiosDashboardPublic from "./clientApiPublic"

export async function getTablesLayoutsAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/tableslayouts`).then((response) => response.data)
}

export async function addTableAPI(tenant: string | undefined, payload: AddNewTableData) {
  return new Promise<TableDetailsData>((resolve, reject) => {
    axiosDashboard
      .post(`/dashboard/${tenant}/tables`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function updateTablesCanvaShapesAPI(tenant: string | undefined, id: string, payload: UpdateShapeData) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .put(`/dashboard/${tenant}/tablescanvashapes/${id}`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function updateTableInfoAPI(tenant: string | undefined, id: string, payload: TableBaseData) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .put(`/dashboard/${tenant}/tables/${id}`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getTablesLayoutsDetailsAPI(tenant: string | undefined, tableLayoutId: string) {
  return axiosDashboard.get(`/dashboard/${tenant}/tableslayouts/${tableLayoutId}`).then((response) => response.data)
}

export async function getTablesLayoutsDetailsPublicAPI(
  tenant: string | undefined,
  tableLayoutId: string,
  code: string
) {
  return axiosDashboardPublic
    .get(`/dashboard/${tenant}/tableslayouts/${tableLayoutId}/share/${code}`)
    .then((response) => response.data)
}

export async function deleteTableAPI(tenant: string | undefined, tableId: string) {
  return new Promise<boolean>((resolve, reject) => {
    axiosDashboard
      .delete(`/dashboard/${tenant}/tables/${tableId}`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getTableSeatGuestsAPI(tenant: string | undefined, tableLayout: string) {
  return axiosDashboard
    .get(`/dashboard/${tenant}/tableslayouts/${tableLayout}/guests`)
    .then((response) => response.data)
}

export async function updateSeatGuestTableAPI(tenant: string | undefined, guestId: string, tableSeatId: string) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .patch(`/dashboard/${tenant}/tableseats/${tableSeatId}/guest/${guestId}`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function deleteSeatGuestTableAPI(tenant: string | undefined, tableSeatId: string) {
  return new Promise<boolean>((resolve, reject) => {
    axiosDashboard
      .delete(`/dashboard/${tenant}/tableseats/${tableSeatId}/guest`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function downloadTablesLayoutFile(
  tenant: string | undefined,
  tableLayoutId: string,
  printSurname: boolean,
  extension: string
) {
  return new Promise<Blob>((resolve) => {
    toastPromise(
      axiosDashboard
        .get(
          `/dashboard/${tenant}/tableslayouts/${tableLayoutId}/file?printSurname=${printSurname}&extension=${extension}`,
          {
            responseType: "blob",
            timeout: 10000,
          }
        )
        .then((response) => resolve(response.data)),
      "Download in corso",
      "Download completato",
      "Qualcosa è andato storto"
    )
  })
}

export async function updateTablesLayoutShareLinkEnable(
  tenant: string | undefined,
  layoutId: string,
  payload: TablesLayoutShareEnable
) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .patch(`/dashboard/${tenant}/tableslayouts/${layoutId}/share/enabled`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getTablesLayoutShareLink(tenant: string | undefined, tableLayoutId: string) {
  return axiosDashboard
    .get(`/dashboard/${tenant}/tableslayouts/${tableLayoutId}/share/link`)
    .then((response) => response.data)
}

export async function getLayoutSvgReportApi(
  tenant: string | undefined,
  tableLayoutId: string,
  payload: TablesLayoutSvgReport
) {
  return new Promise<Blob>((resolve) => {
    toastPromise(
      axiosDashboard
        .post(`/dashboard/${tenant}/tableslayouts/${tableLayoutId}/report`, payload, {
          responseType: "blob",
          timeout: 100000,
        })
        .then((response) => resolve(response.data)),
      "Download in corso",
      "Download completato",
      "Qualcosa è andato storto"
    )
  })
}
