import { TableDetailsData } from "../../../models/tableApiTypes"
import TableMobileCard from "./TableMobileCard"

interface TableMobileViewProps {
  tables: TableDetailsData[]
}

export default function TableMobileView({ tables }: TableMobileViewProps) {
  return (
    <div
      style={{
        width: "100%",
        maxHeight: "650px",
        backgroundColor: "#ffffff",
        padding: "20px",
        borderRadius: "10px",
        overflowY: "scroll",
      }}
    >
      <div style={{ height: "inherit" }}>
        {tables.map((table, index) => {
          return (
            <div key={index} className="col-sm-12" style={index < tables.length - 1 ? { marginBottom: "10px" } : {}}>
              <TableMobileCard table={table} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
