import InvitationForm from "../components/invitation/InvitationForm"
import { useEffect, useState } from "react"
import { getInvitationDetailsAPI } from "../api/invitationsApi"
import { useParams } from "react-router-dom"
import { InvitationDetailsData } from "../models/invitationsApiTypes"
import { GuestFormReducer, InvitationFormReducer } from "../components/invitation/reducer/invitationFormReducer"
import Sidebar from "../components/Sidebar"

interface UpdateInvitationProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function UpdateInvitation({ sidebar = true, showSidebar }: UpdateInvitationProps) {
  const [invitation, setInvitation] = useState<InvitationFormReducer>()
  const [invitationDetails, setInvitationDetails] = useState<InvitationDetailsData>()
  const { tenant } = useParams<string>()
  const { invitationId } = useParams<string>()

  const InvitationDetailsMap = (invitationDetails: InvitationDetailsData): InvitationFormReducer => {
    const invitationDefaultValue: InvitationFormReducer = {
      name: "",
      id: undefined,
      guests: [],
    }
    invitationDefaultValue.name = invitationDetails.name
    invitationDefaultValue.id = invitationDetails.id
    invitationDetails.guests.forEach(function (g) {
      const tempGuest: GuestFormReducer = {
        name: "",
        surname: "",
        id: undefined,
        isModified: false,
        typeId: "",
        groupId: undefined,
        rsvpSettings: {
          showBusTransferNeeded: false,
          showArrivalDate: false,
          showEmail: false,
          showIntolerances: false,
        },
        isDraft: g.status === "Draft",
      }
      tempGuest.id = g.id
      tempGuest.name = g.name
      tempGuest.surname = g.surname
      tempGuest.typeId = g.type.id
      tempGuest.groupId = g.group?.id
      tempGuest.rsvpSettings = g.rsvpSettings
      invitationDefaultValue.guests.push(tempGuest)
    })
    return invitationDefaultValue
  }

  useEffect(() => {
    invitationId &&
      getInvitationDetailsAPI(tenant, invitationId)
        .then((result) => {
          setInvitationDetails(result)
          setInvitation(InvitationDetailsMap(result))
        })
        .catch(() => console.error("ERROR"))
  }, [invitationId, tenant])

  return (
    <>
      <div className="wrapper">
        <Sidebar sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
        <div id="content">
          {invitationDetails && invitation && (
            <InvitationForm tenant={tenant} invitation={invitation} isUpdate={true} />
          )}
        </div>
      </div>
    </>
  )
}
