import { useEffect, useMemo, useState } from "react"
import { getContributorsAPI } from "../../api/contributorsApi"
import { ContributorDetailsData, RolesData } from "../../models/contributorsApi"
import "../../style/custom_style.css"
import CustomTable from "../CustomTable"

enum TABLE_HEADER {
  EMAIL = "EMAIL",
  ROLES = "RUOLI",
}

interface RolesProps {
  roles: RolesData[] | undefined
}

const Roles = ({ roles }: RolesProps) => {
  const rolesStyle = {
    backgroundColor: "#f5f5f5",
    color: "#4c8e9d",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "6px",
    padding: "5px",
    marginRight: "10px",
    height: "fit-content",
  }
  if (roles !== undefined) {
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {roles.map((item: RolesData, idx: number) => {
          if (idx < 3) {
            return (
              <div key={idx} style={rolesStyle}>
                <span>{item.roleName}</span>
              </div>
            )
          } else if (idx === 3) {
            return (
              <div key={idx} style={rolesStyle}>
                <span>...</span>
              </div>
            )
          } else return null
        })}
      </div>
    )
  } else return <></>
}

interface RsvpInfoProps {
  tenant: string | undefined
}

export default function ContributorsTable({ tenant }: RsvpInfoProps) {
  const [actualPage, setActualPage] = useState(0)
  const [constributosDetails, setContributorsDetails] = useState<ContributorDetailsData>()

  useEffect(() => {
    getContributorsAPI(tenant)
      .then((result) => setContributorsDetails(result))
      .catch((e) => console.error(e))
  }, [tenant])

  const columns = useMemo(
    () => [
      {
        Header: TABLE_HEADER.EMAIL,
        accessor: "email",
        Cell: (props: { row: { original: ContributorDetailsData } }) => <div>{props.row.original.email}</div>,
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.ROLES,
        accessor: "roles",
        Cell: (row: { cell: { value: RolesData[] } }) => <Roles roles={row.cell.value} />,
        disableSortBy: true,
      },
    ],
    []
  )

  const data = useMemo(() => constributosDetails, [constributosDetails])

  return (
    <>
      {data && (
        <CustomTable
          tableWidth="100%"
          columns={columns}
          data={data}
          actualPage={actualPage}
          setActualPage={setActualPage}
          isColumnFilterEnabled={false}
          hiddenColumns={[""]}
          sortBy={[]}
          filters={[]}
        />
      )}
    </>
  )
}
