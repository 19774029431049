import { useDispatch } from "react-redux"
import CustomButton from "../../CustomButton"
import { login } from "../authSlice"

export default function AuthFailed() {
  const dispatch = useDispatch()

  return (
    <div className="card col-md-4 login-card">
      <div className="card-body">
        <h5 className="card-title welcome-message" style={{ justifyContent: "center" }}>
          Accesso fallito
        </h5>
        <h6 className="card-subtitle mb-2 text-muted" style={{ textAlign: "center", padding: "12px" }}>
          Errore durante la procedura di Log In. Esegui nuovamente l&apos;accesso
        </h6>
        <div className="card-text" style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton title="Log In" width="100px" handleButtonClick={() => dispatch(login())}></CustomButton>
        </div>
      </div>
    </div>
  )
}
