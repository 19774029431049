import { useState } from "react"
import { Modal } from "react-bootstrap"
import { GuestRsvp, GuestRsvpReport } from "../../models/guestRsvpApiTypes"
import { downloadGuestRsvpExportApi } from "../../api/guestRsvp"
import { toastWarning } from "../../utils/toastMethod"
import Select from "react-select"

interface TableSharePublicLinkModalProps {
  tenant: string | undefined
  guests: GuestRsvp[]
  show: boolean
  setShow: (show: boolean) => void
}

interface SelectOptions {
  value: string
  label: string
}

const selectorderBy: SelectOptions[] = [
  {
    label: "Nome",
    value: "name",
  },
  {
    label: "Cognome",
    value: "surname",
  },
  {
    label: "Invito",
    value: "invitation",
  },
  {
    label: "Data Rsvp",
    value: "rsvpDate",
  },
]

const selectExtension: SelectOptions[] = [
  {
    label: "txt",
    value: "txt",
  },
]

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    border: "1px solid #ebf2fa",
    boxShadow: "none",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 16,
    color: state.selectProps.menuColor,
    padding: 10,
    fontSize: 14,
  }),
  option: (provided: any) => ({
    ...provided,
    borderRadius: 16,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: 40,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: 14,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: 14,
    color: "#b5b5bd",
  }),
}

export default function RsvpDwonloadListModal({ tenant, guests, show, setShow }: TableSharePublicLinkModalProps) {
  const [guestRsvpReport, setGuestRsvpReport] = useState<GuestRsvpReport>({
    extension: "",
    ids: [],
    orderBy: "",
    orderDesc: false,
  })

  const hideModal = () => {
    setShow(false)
    setGuestRsvpReport({ extension: "", orderBy: "", ids: [], orderDesc: false })
  }

  const downloadQrCodesFile = async () => {
    if (guestRsvpReport.extension.length === 0) {
      toastWarning("Inserisci un formato del file!")
    } else if (guestRsvpReport.orderBy.length === 0) {
      toastWarning("Inserisci un'ordinamento per gli invitati!")
    } else {
      guests.forEach(function (r: GuestRsvp) {
        guestRsvpReport.ids.push(r.id)
      })
      downloadGuestRsvpExportApi(tenant, guestRsvpReport).then((response) => {
        const url = window.URL.createObjectURL(response)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = "guests.txt"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      hideModal()
    }
  }

  return (
    <Modal show={show} onHide={() => hideModal()} centered={true}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Modal.Title>Scarica la lista degli invitati</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "30px" }}>
        <>
          Scarica la lista degli invitati selezionati.
          <>
            {/*             {rsvpSettings?.weddingRsvpSettings.showIntolerances ? (
              <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                <div style={{ flex: "9" }}>
                  <label className="toggle-form-label">Esporta intolleranze invitato</label>
                </div>
                <div style={{ flex: "1", justifyContent: "flex-end" }}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={options.intolerances}
                      onChange={(e) => setOptions({ ...options, intolerances: e.target.checked })}
                    ></input>
                  </div>
                </div>
              </div>
            ) : null}
            {rsvpSettings?.weddingRsvpSettings.showBusTransferNeeded ? (
              <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                <div style={{ flex: "9" }}>
                  <label className="toggle-form-label">Esporta informazioni bus</label>
                </div>
                <div style={{ flex: "1", justifyContent: "flex-end" }}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={options.bus}
                      onChange={(e) => setOptions({ ...options, bus: e.target.checked })}
                    ></input>
                  </div>
                </div>
              </div>
            ) : null}
            {rsvpSettings?.weddingRsvpSettings.showArrivalDate ? (
              <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                <div style={{ flex: "9" }}>
                  <label className="toggle-form-label">Esporta informazioni data di arrivo</label>
                </div>
                <div style={{ flex: "1", justifyContent: "flex-end" }}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={options.arrivalDate}
                      onChange={(e) => setOptions({ ...options, arrivalDate: e.target.checked })}
                    ></input>
                  </div>
                </div>
              </div>
            ) : null}
            {rsvpSettings?.weddingRsvpSettings.showEmail ? (
              <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                <div style={{ flex: "9" }}>
                  <label className="toggle-form-label">Esporta email invitato</label>
                </div>
                <div style={{ flex: "1", justifyContent: "flex-end" }}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={options.email}
                      onChange={(e) => setOptions({ ...options, email: e.target.checked })}
                    ></input>
                  </div>
                </div>
              </div>
            ) : null} */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "15px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "6" }}>
                <label className="toggle-form-label">Ordina i invitati per</label>
              </div>
              <div style={{ flex: "4", justifyContent: "flex-end" }}>
                <Select
                  styles={customStyles}
                  options={selectorderBy}
                  onChange={(e) => setGuestRsvpReport({ ...guestRsvpReport, orderBy: e !== null ? e.value : "name" })}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
              <div style={{ flex: "9" }}>
                <label className="toggle-form-label">Ordine decrescente</label>
              </div>
              <div style={{ flex: "1", justifyContent: "flex-end" }}>
                <div className="form-check form-switch" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={guestRsvpReport.orderDesc}
                    onChange={(e) => setGuestRsvpReport({ ...guestRsvpReport, orderDesc: e.target.checked })}
                  ></input>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "6" }}>
                <label className="toggle-form-label">Seleziona formato del file</label>
              </div>
              <div style={{ flex: "4", justifyContent: "flex-end" }}>
                <Select
                  styles={customStyles}
                  options={selectExtension}
                  onChange={(e) => setGuestRsvpReport({ ...guestRsvpReport, extension: e !== null ? e.value : "txt" })}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>Hai selezionato {guests?.length} invitati</div>
            <button
              type="button"
              className="btn btn-primary button-style"
              style={{ marginTop: "20px" }}
              onClick={downloadQrCodesFile}
            >
              Scarica lista
            </button>
          </>
        </>
      </Modal.Body>
    </Modal>
  )
}
