import {
  AddGuestData,
  AddInvitationData,
  PatchInvitationName,
  PatchInvitationStatus,
  PatchTable,
  QrCodeSettings,
  UpdateGuestData,
} from "../models/invitationsApiTypes"
import { toastPromise } from "../utils/toastMethod"
import axiosDashboard from "./clientApi"

export async function addGuestAPI(tenant: string | undefined, invitationId: string, payload: AddGuestData) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .post(`/dashboard/${tenant}/invitations/${invitationId}/guest`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function updateGuestAPI(tenant: string | undefined, id: string, payload: UpdateGuestData) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .put(`/dashboard/${tenant}/invitations/guest/${id}`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function deleteGuestAPI(tenant: string | undefined, id: string) {
  return new Promise<boolean>((resolve, reject) => {
    axiosDashboard
      .delete(`/dashboard/${tenant}/invitations/guest/${id}`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function updateGuestTableAPI(tenant: string | undefined, id: string, payload: PatchTable) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .patch(`/dashboard/${tenant}/invitations/guest/${id}/table`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function addInvitationAPI(tenant: string | undefined, payload: AddInvitationData) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .post(`/dashboard/${tenant}/invitations`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getInvitationsAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/invitations`).then((response) => response.data)
}

export async function getInvitationDetailsAPI(tenant: string | undefined, id: string) {
  return axiosDashboard.get(`/dashboard/${tenant}/invitations/${id}`).then((response) => response.data)
}

export async function updateInvitationNameAPI(tenant: string | undefined, id: string, payload: PatchInvitationName) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .patch(`/dashboard/${tenant}/invitations/${id}/name`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function updateInvitationStatusAPI(
  tenant: string | undefined,
  id: string,
  payload: PatchInvitationStatus
) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .patch(`/dashboard/${tenant}/invitations/${id}/status`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getReservationCodeAPI(tenant: string | undefined, invitationId: string) {
  return axiosDashboard
    .get(`/dashboard/${tenant}/invitations/${invitationId}/reservationcode`)
    .then((response) => response.data)
}

export async function deleteInvitationAPI(tenant: string | undefined, id: string) {
  return new Promise<boolean>((resolve, reject) => {
    axiosDashboard
      .delete(`/dashboard/${tenant}/invitations/${id}`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function downloadInvitationsQrCodesFile(tenant: string | undefined, payload: QrCodeSettings) {
  return new Promise<Blob>((resolve) => {
    toastPromise(
      axiosDashboard
        .post(`/dashboard/${tenant}/invitations/files/rsvp/qrcodes`, payload, {
          responseType: "blob",
          timeout: 60000,
        })
        .then((response) => resolve(response.data)),
      "Download in corso",
      "Download completato",
      "Qualcosa è andato storto"
    )
  })
}

export async function deleteRsvpAPI(tenant: string | undefined, id: string) {
  return new Promise<boolean>((resolve, reject) => {
    axiosDashboard
      .delete(`/dashboard/${tenant}/invitations/${id}/rsvp`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}
