import { addTableAPI, deleteTableAPI, updateTablesCanvaShapesAPI } from "../../../api/tableApi"
import { useAppDispatch } from "../../../hook/useReduxHooks"
import { ShapeBaseDataWithId, TableDetailsData, UpdateShapeData } from "../../../models/tableApiTypes"
import { toastSuccess, toastWarning } from "../../../utils/toastMethod"
import {
  addNewTable,
  deleteTable,
  setToolPaletView,
  TOOL_PALET_VIEW_TYPE,
  updateTableInfo,
  updateTableRotationDegree,
  updateTableShape,
} from "../tableSlice"

interface ToolPaletTableProps {
  tenant: string | undefined
  tableDetails: TableDetailsData
}

export default function ToolPaletTable({ tenant, tableDetails }: ToolPaletTableProps) {
  const dispatch = useAppDispatch()

  const hanldeChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateTableInfo({
        tableId: tableDetails.id,
        tableInfo: { name: e.currentTarget.value, description: tableDetails.description },
      })
    )
  }

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateTableInfo({
        tableId: tableDetails.id,
        tableInfo: { name: tableDetails.name, description: e.currentTarget.value },
      })
    )
  }

  const convertRotationDegree = (rotationDegree: string) => {
    const newValue = parseInt(rotationDegree.length > 0 ? rotationDegree : "0", 10)
    return newValue >= 0 && newValue <= 360 ? newValue : 0
  }

  const handleChangeRotation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRotationDegree = convertRotationDegree(e.currentTarget.value)
    const newTableShape: UpdateShapeData = {
      x: tableDetails.shape.x,
      y: tableDetails.shape.y,
      width: tableDetails.shape.width,
      height: tableDetails.shape.height,
      rotationDegrees: newRotationDegree,
      childs: [],
    }
    tableDetails.seats.forEach(function (seat) {
      const seatShape: ShapeBaseDataWithId = {
        x: seat.shape.x,
        y: seat.shape.y,
        width: seat.shape.width,
        height: seat.shape.height,
        rotationDegrees: seat.shape.rotationDegrees,
        id: seat.id,
      }
      newTableShape.childs.push(seatShape)
    })

    const toolPaletTableData: TableDetailsData = {
      id: tableDetails.id,
      layoutId: tableDetails.layoutId,
      name: tableDetails.name,
      description: tableDetails.description,
      seats: tableDetails.seats,
      shape: {
        id: tableDetails.shape.id,
        parentShapeId: tableDetails.shape.parentShapeId,
        x: tableDetails.shape.x,
        y: tableDetails.shape.y,
        width: tableDetails.shape.width,
        height: tableDetails.shape.height,
        rotationDegrees: newRotationDegree,
        title: tableDetails.shape.title,
        type: tableDetails.shape.type,
      },
    }
    dispatch(
      setToolPaletView({
        newToolPaletView: { toolPaletViewType: TOOL_PALET_VIEW_TYPE.TABLE, toolPaletTableData },
      })
    )
    dispatch(updateTableRotationDegree({ tableShapeId: tableDetails.shape.id, newRotationDegree }))
    dispatch(
      updateTableShape({
        tableShapeId: tableDetails.shape.id,
        tableShape: newTableShape,
      })
    )
  }

  const handleDeleteTable = () => {
    deleteTableAPI(tenant, tableDetails.id)
      .then(() => {
        dispatch(deleteTable({ tableId: tableDetails.id }))
        toastSuccess("Tavolo rimosso.")
      })
      .catch(() => toastWarning("Problemi con la cancellazione del tavolo. Riprova!"))
  }

  const handleDuplicateTable = () => {
    addTableAPI(tenant, {
      name: tableDetails.name,
      description: tableDetails.description,
      seatsNumber: tableDetails.seats.length,
      tableType: tableDetails.shape.type,
    })
      .then((tableDetailsData) => {
        updateTablesCanvaShapesAPI(tenant, tableDetailsData.shape.id, {
          x: tableDetails.shape.x + tableDetails.shape.width * 1.5,
          y: tableDetails.shape.y,
          width: tableDetails.shape.width,
          height: tableDetails.shape.height,
          rotationDegrees: tableDetails.shape.rotationDegrees,
          childs: tableDetails.seats.map((seat, index) => {
            return {
              x: seat.shape.x,
              y: seat.shape.y,
              width: seat.shape.width,
              height: seat.shape.height,
              id: tableDetailsData.seats[index].shape.id,
              rotationDegrees: seat.shape.rotationDegrees,
            }
          }),
        })
          .then(() => {
            tableDetailsData.shape.x = tableDetails.shape.x + tableDetails.shape.width * 1.5
            tableDetailsData.shape.y = tableDetails.shape.y
            tableDetailsData.shape.width = tableDetails.shape.width
            tableDetailsData.shape.height = tableDetails.shape.height
            tableDetailsData.seats.forEach((seat, index) => {
              seat.shape.x = tableDetails.seats[index].shape.x
              seat.shape.y = tableDetails.seats[index].shape.y
              seat.shape.rotationDegrees = tableDetails.seats[index].shape.rotationDegrees
            })
            dispatch(addNewTable({ tables: tableDetailsData }))
            toastSuccess("Tavolo duplicato.")
          })
          .catch(() => toastWarning("Qualcosa è andato storto. Riprova!"))
      })
      .catch(() => toastWarning("Qualcosa è andato storto. Riprova!"))
  }

  return (
    <div className="tool_palet_element">
      <div style={{ marginBottom: "20px" }}>
        <div>Nome tavolo</div>
        <input
          className="form-input"
          type="search"
          placeholder="Nome tavolo"
          value={tableDetails.name}
          onChange={hanldeChangeName}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <div>Descrizione tavolo</div>
        <input
          className="form-input"
          type="search"
          placeholder="Descrizione tavolo"
          value={tableDetails.description}
          onChange={handleChangeDescription}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <div>Rotazione</div>
        <input
          className="form-input"
          type="number"
          placeholder="1"
          value={tableDetails.shape.rotationDegrees}
          onChange={handleChangeRotation}
        ></input>
      </div>
      <button
        type="button"
        className="btn btn-info button-style"
        style={{ marginRight: "10px", marginBottom: "20px" }}
        onClick={handleDuplicateTable}
      >
        Duplica tavolo
      </button>
      <button
        type="button"
        className="btn btn-danger button-style"
        style={{ marginRight: "10px" }}
        onClick={handleDeleteTable}
      >
        Elimina tavolo
      </button>
    </div>
  )
}
