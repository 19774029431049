import { SETTING_ITEM } from "../../pages/Settings"
import SettingsType from "./SettingsType"
import SettingsGroup from "./SettingsGroup"
import SettingsContributor from "./SettingsContributor"
import SettingsRsvp from "./SettingsRsvp"
import SettingsGeneral from "./SettingsGeneral"

interface SettingsInfoProps {
  tenant: string | undefined
  setting: SETTING_ITEM
}

export default function SettingsInfo({ tenant, setting }: SettingsInfoProps) {
  return (
    <>
      {setting === SETTING_ITEM.GENERALS && <SettingsGeneral tenant={tenant} />}
      {setting === SETTING_ITEM.RSVP && <SettingsRsvp tenant={tenant} />}
      {setting === SETTING_ITEM.TYPES && <SettingsType tenant={tenant} />}
      {setting === SETTING_ITEM.GROUPS && <SettingsGroup tenant={tenant} />}
      {setting === SETTING_ITEM.CONSTRIBUTORS && <SettingsContributor tenant={tenant} />}
    </>
  )
}
