import { forwardRef, ForwardRefRenderFunction, useEffect, useRef } from "react"

interface TableCheckBoxProps {
  indeterminate?: boolean
}

const TableCheckBox: ForwardRefRenderFunction<HTMLInputElement, TableCheckBoxProps> = (
  { indeterminate = false, ...rest },
  ref
) => {
  const defaultRef = useRef<HTMLInputElement>()
  const resolvedRef = (ref || defaultRef) as React.MutableRefObject<HTMLInputElement>

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <input type="checkbox" ref={resolvedRef} {...rest} />
}

export default forwardRef(TableCheckBox)
