import { ReactNode } from "react"

interface ProvisioningStepBoxProps {
  children: ReactNode
  iconAlt: string
  iconSource: string
  title: string
  subTitle: string
  progressBar: ReactNode
}

export default function ProvisioningStepBox({
  children,
  iconAlt,
  iconSource,
  title,
  subTitle,
  progressBar,
}: ProvisioningStepBoxProps) {
  return (
    <div className="provisioning-box-content ">
      <div className="provisioning-icon">
        <img alt={iconAlt} src={iconSource} />
      </div>
      <div className="provisioning-title">
        {title ? <div className="provisioning-main-title">{title}</div> : null}
        {subTitle ? <div className="provisioning-sub-title">{subTitle}</div> : null}
      </div>
      {children ? <div className="provisioning-form">{children}</div> : null}
      {progressBar ? <div className="progress-bar-box">{progressBar}</div> : null}
    </div>
  )
}
