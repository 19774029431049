import "./App.css"
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom"
import store, { appInitAction } from "./store"
import { Provider } from "react-redux"
import { Slide, ToastContainer } from "react-toastify"
import { useState } from "react"
import InvitationsInfo from "./pages/Invitations"
import Dashboard from "./pages/Dashboard"
import Provisioning from "./components/provisioning/Provisioning"
import AuthRequiredPage from "./components/auth/components/AuthRequiredPage"
import WeddingsRoles from "./pages/WeddingsRoles"
import AddInvitation from "./pages/AddInvitation"
import UpdateInvitation from "./pages/UpdateInvitation"
import Settings from "./pages/Settings"
import Rsvp from "./pages/Rsvp"
import TablesViewer from "./pages/TablesViewer"
import TablesViewerPublic from "./pages/TablesViewerPublic"

export default function App() {
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = (value: boolean) => setSidebar(value)

  return (
    <Provider store={store}>
      <div className="app">
        <BrowserRouter>
          <Routes>
            <Route
              path="/home"
              element={
                <AuthRequiredPage>
                  <WeddingsRoles />
                </AuthRequiredPage>
              }
            />
            <Route
              path="/:tenant/dashboard"
              element={
                <AuthRequiredPage>
                  <Dashboard sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
                </AuthRequiredPage>
              }
            />
            <Route
              path="/:tenant/invitations"
              element={
                <AuthRequiredPage>
                  <InvitationsInfo sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
                </AuthRequiredPage>
              }
            />
            <Route
              path="/:tenant/rsvp"
              element={
                <AuthRequiredPage>
                  <Rsvp sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
                </AuthRequiredPage>
              }
            />
            <Route
              path="/:tenant/tables"
              element={
                <AuthRequiredPage>
                  <TablesViewer sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
                </AuthRequiredPage>
              }
            />
            <Route
              path="/:tenant/wedding-provisioning"
              element={
                <AuthRequiredPage>
                  <Provisioning />
                </AuthRequiredPage>
              }
            />
            <Route
              path="/:tenant/invitation/add"
              element={
                <AuthRequiredPage>
                  <AddInvitation sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
                </AuthRequiredPage>
              }
            />
            <Route
              path="/:tenant/invitation/:invitationId"
              element={
                <AuthRequiredPage>
                  <UpdateInvitation sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
                </AuthRequiredPage>
              }
            />
            <Route
              path="/:tenant/account"
              element={
                <AuthRequiredPage>
                  <Settings sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
                </AuthRequiredPage>
              }
            />
            <Route path="/tables" element={<TablesViewerPublic />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer theme="colored" position="bottom-right" autoClose={4000} transition={Slide} />
      </div>
    </Provider>
  )
}

store.dispatch(appInitAction())
