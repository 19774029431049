import { TableDetailsData } from "../../../models/tableApiTypes"

interface TableMobileCardProps {
  table: TableDetailsData
}

export default function TableMobileCard({ table }: TableMobileCardProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f7fafc",
        borderRadius: "10px",
        padding: "15px",
        overflowY: "scroll",
        maxHeight: "250px",
      }}
    >
      <div
        style={{
          fontWeight: "600",
          marginBottom: "10px",
        }}
      >
        {table.name}
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "10px", fontSize: "13.5px", color: "#718096" }}
      >
        <div style={{ flex: "4" }}>Invitato</div>
        <div style={{ flex: "5" }}>Nome invito</div>
      </div>
      <div
        style={{
          fontWeight: "400",
          fontSize: "13.5px",
        }}
      >
        {table.seats.map((seat, index) => {
          return (
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: "4" }}>
                {seat.guest?.name} {seat.guest?.surname}
              </div>
              <div style={{ flex: "5" }}>{seat.guest?.invitation}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
