import { useNavigate, useParams } from "react-router-dom"
import { CustomTooltip } from "../../CustomTooltip"
import { GuestStatus } from "../../../models/invitationsApiTypes"

interface ProgressBarChartProps {
  parentTile: string
  graphName: string
  attendingGuest: number
  invitedGuest: number
  declinedGuest: number
}

export default function ProgressBarChart({
  parentTile,
  graphName,
  attendingGuest,
  invitedGuest,
  declinedGuest,
}: ProgressBarChartProps) {
  const navigate = useNavigate()
  const { tenant } = useParams<string>()
  const redirectUrl = `/${tenant}/rsvp?${parentTile}=${graphName}`

  return (
    <div
      style={{
        height: "12px",
        display: "flex",
        flexDirection: "row",
        marginRight: "10px",
        borderRadius: "5px",
      }}
    >
      <>
        <CustomTooltip text={"Confermati: " + attendingGuest} show={true}>
          <div
            style={{
              flex: attendingGuest,
              backgroundColor: "#4c6fff",
              borderRadius: "5px",
              marginRight: "1px",
              cursor: "pointer",
            }}
            onClick={() => navigate(`${redirectUrl}&rsvp=${GuestStatus.ACCEPTED}`)}
          ></div>
        </CustomTooltip>
        <CustomTooltip text={"In attesa: " + invitedGuest} show={true}>
          <div
            style={{
              flex: invitedGuest,
              backgroundColor: "#ff92ae",
              borderRadius: "5px",
              marginRight: "1px",
              marginLeft: "1px",
              cursor: "pointer",
            }}
            onClick={() => navigate(`${redirectUrl}&rsvp=${GuestStatus.INVITED}`)}
          ></div>
        </CustomTooltip>
        <CustomTooltip text={"Disdetta: " + declinedGuest} show={true}>
          <div
            style={{
              flex: declinedGuest,
              backgroundColor: "#fdd762",
              borderRadius: "5px",
              marginLeft: "1px",
              cursor: "pointer",
            }}
            onClick={() => navigate(`${redirectUrl}&rsvp=${GuestStatus.DECLINED}`)}
          ></div>
        </CustomTooltip>
      </>
    </div>
  )
}
