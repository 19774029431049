import { Form, Formik } from "formik"
import * as Yup from "yup"
import DatePicker from "react-datepicker"
import { AddWedding } from "../../models/weddingApiTypes"

interface ProvisioningSecondStepProps {
  setNextStep: (newStep: number) => void
  setNewWeddingInfo: (newWeddingInfo: AddWedding) => void
  actualWeddingInfo: AddWedding
}

const provisioningSchema = Yup.object().shape({
  weddingDate: Yup.date().min(new Date(Date.now() + 86400000)),
})

export default function ProvisioningSecondStep({
  setNextStep,
  setNewWeddingInfo,
  actualWeddingInfo,
}: ProvisioningSecondStepProps) {
  return (
    <>
      <Formik
        initialValues={{
          nameFirstOwner: actualWeddingInfo.nameFirstOwner,
          surnameFirstOwner: actualWeddingInfo.surnameFirstOwner,
          nameSecondOwner: actualWeddingInfo.nameSecondOwner,
          surnameSecondOwner: actualWeddingInfo.surnameSecondOwner,
          weddingDate: actualWeddingInfo.weddingDate,
          location: actualWeddingInfo.location,
          rsvpSettings: {
            isRsvpEnabled: actualWeddingInfo.rsvpSettings.isRsvpEnabled,
            showArrivalDate: actualWeddingInfo.rsvpSettings.showArrivalDate,
            showBusTransferNeeded: actualWeddingInfo.rsvpSettings.showBusTransferNeeded,
            showEmail: actualWeddingInfo.rsvpSettings.showEmail,
            showIntolerances: actualWeddingInfo.rsvpSettings.showIntolerances,
          },
        }}
        validationSchema={provisioningSchema}
        onSubmit={async (values: AddWedding) => {
          setNewWeddingInfo(values)
          setNextStep(2)
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div>
              <label className="provisioning-form-label" htmlFor="weddingDate">
                Data
              </label>
              <DatePicker
                className="provisioning-form-input"
                selected={values.weddingDate}
                dateFormat="dd MMMM yyyy"
                name="weddingDate"
                onChange={(date: Date) => setFieldValue("weddingDate", date)}
              />
              {errors.weddingDate && touched.weddingDate ? (
                <div className="provisioning-form-input-error">{"Inserisci una data nel futuro"}</div>
              ) : null}
            </div>

            <div className="provisoning-button-box">
              <button
                type="button"
                className="btn btn-primary provisoning-button"
                style={{ flex: "5", margin: "5px", backgroundColor: "#a6b7d4", borderColor: "#a6b7d4" }}
                onClick={() => setNextStep(0)}
              >
                Indietro
              </button>
              <button type="submit" className="btn btn-primary provisoning-button" style={{ flex: "5", margin: "5px" }}>
                Continua
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
