import { AddGuestData } from "../../../models/invitationsApiTypes"

export enum ACTION_TYPES {
  ADD_ITEM = "ADD_ITEM",
  REMOVE_ITEM = "REMOVE_ITEM",
  UPDATE_ITEM = "UPDATE_ITEM",
  SHOW_ERROR = "SHOW_ERROR",
  CHANGE_INPUT_NAME = "CHANGE_INPUT_NAME",
  CHANGE_INPUT_GUEST_NAME = "CHANGE_INPUT_GUEST_NAME",
  CHANGE_INPUT_GUEST_SURNAME = "CHANGE_INPUT_GUEST_SURNAME",
  UPDATE_INPUT_NAME = "UPDATE_INPUT_NAME",
  UPDATE_GUEST_ID = "UPDATE_GUEST_ID",
  CHANGE_TOGGLE_INTOLERANCES = "CHANGE_TOGGLE_INTOLERANCES",
  CHANGE_TOGGLE_EMAIL = "CHANGE_TOGGLE_EMAIL",
  CHANGE_TOGGLE_BUS = "CHANGE_TOGGLE_BUS",
  CHANGE_TOGGLE_DATE = "CHANGE_TOGGLE_DATE",
  CHANGE_TOGGLE_DRAFT = "CHANGE_TOGGLE_DRAFT",
  CHANGE_DROPDOWN_TYPE = "CHANGE_DROPDOWN_TYPE",
  CHANGE_DROPDOWN_GROUP = "CHANGE_DROPDOWN_GROUP",
}

export type GuestFormReducer = AddGuestData & {
  isModified: boolean
  id: string | undefined
}

export type InvitationFormReducer = {
  name: string
  id: string | undefined
  guests: GuestFormReducer[]
}

type AddNewGuestRsvpSettings = {
  showIntolerances: boolean
  showEmail: boolean
  showBusTransferNeeded: boolean
  showArrivalDate: boolean
}

type AddInvitationAction =
  | { type: ACTION_TYPES.ADD_ITEM; payload: AddNewGuestRsvpSettings }
  | { type: ACTION_TYPES.REMOVE_ITEM; payload: AddGuestData }
  | { type: ACTION_TYPES.UPDATE_ITEM; payload: GuestFormReducer }
  | { type: ACTION_TYPES.SHOW_ERROR; payload: boolean }
  | { type: ACTION_TYPES.CHANGE_INPUT_NAME; payload: string }
  | { type: ACTION_TYPES.CHANGE_INPUT_GUEST_NAME; arrayIndex: number; payload: string }
  | { type: ACTION_TYPES.CHANGE_INPUT_GUEST_SURNAME; arrayIndex: number; payload: string }
  | { type: ACTION_TYPES.UPDATE_INPUT_NAME; payload: boolean }
  | { type: ACTION_TYPES.UPDATE_GUEST_ID; arrayIndex: number; payload: string }
  | { type: ACTION_TYPES.CHANGE_TOGGLE_INTOLERANCES; arrayIndex: number; payload: boolean }
  | { type: ACTION_TYPES.CHANGE_TOGGLE_EMAIL; arrayIndex: number; payload: boolean }
  | { type: ACTION_TYPES.CHANGE_TOGGLE_BUS; arrayIndex: number; payload: boolean }
  | { type: ACTION_TYPES.CHANGE_TOGGLE_DATE; arrayIndex: number; payload: boolean }
  | { type: ACTION_TYPES.CHANGE_TOGGLE_DRAFT; arrayIndex: number; payload: boolean }
  | { type: ACTION_TYPES.CHANGE_DROPDOWN_TYPE; arrayIndex: number; payload: string }
  | { type: ACTION_TYPES.CHANGE_DROPDOWN_GROUP; arrayIndex: number; payload: string }

const updateInvitationName = (guests: AddGuestData[]): string => {
  if (guests.length === 1) {
    return guests.map((guest) => guest.name).toString()
  } else if (guests.length === 2) {
    return guests.map((guest) => guest.name).join(" & ")
  } else {
    let invitationName = ""
    guests.forEach(function (guest, i) {
      if (guest.name.length > 0) {
        if (i === 0) {
          invitationName += guest.name
        } else if (i === guests.length - 1) {
          invitationName += " & " + guest.name
        } else {
          invitationName += ", " + guest.name
        }
      }
    })
    return invitationName
  }
}

export const checkRequiredField = (guests: GuestFormReducer[]): boolean => {
  let isEmpty = false
  guests.forEach(function (guest) {
    if (guest.name.length === 0 || guest.typeId.length === 0) {
      isEmpty = true
    }
  })
  if (isEmpty) {
    return true
  }
  return false
}

interface AddInvitation {
  isInvitationNameChangedManually: boolean
  isEmpty: boolean
  isInvitationNameUpdating: boolean
  invitation: InvitationFormReducer
}

export const invitationFormReducer = (state: AddInvitation, action: AddInvitationAction): AddInvitation => {
  const { type } = action

  switch (type) {
    case ACTION_TYPES.ADD_ITEM: {
      if (checkRequiredField(state.invitation.guests)) {
        return { ...state, isEmpty: true }
      }
      const emptyGuest: GuestFormReducer = {
        name: "",
        surname: "",
        id: undefined,
        isModified: false,
        typeId: "",
        groupId: undefined,
        rsvpSettings: {
          showBusTransferNeeded: action.payload.showBusTransferNeeded,
          showArrivalDate: action.payload.showArrivalDate,
          showEmail: action.payload.showEmail,
          showIntolerances: action.payload.showIntolerances,
        },
        isDraft: false,
      }
      return {
        ...state,
        invitation: { ...state.invitation, guests: [...state.invitation.guests, emptyGuest] },
        isEmpty: false,
      }
    }
    case ACTION_TYPES.REMOVE_ITEM:
      if (!state.isInvitationNameChangedManually) {
        return {
          ...state,
          invitation: {
            guests: state.invitation.guests.filter((item) => action.payload !== item),
            name: updateInvitationName(state.invitation.guests.filter((item) => action.payload !== item)),
            id: state.invitation.id,
          },
        }
      } else {
        return {
          ...state,
          invitation: {
            ...state.invitation,
            guests: state.invitation.guests.filter((item) => action.payload !== item),
          },
        }
      }

    case ACTION_TYPES.UPDATE_ITEM:
      if (checkRequiredField(state.invitation.guests)) {
        return { ...state, isEmpty: true }
      }
      return state

    case ACTION_TYPES.SHOW_ERROR:
      return { ...state, isEmpty: action.payload }

    case ACTION_TYPES.CHANGE_INPUT_NAME: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.name = action.payload
      tempInvitation.isInvitationNameChangedManually = true
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_INPUT_GUEST_NAME: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].name = action.payload
      if (!tempInvitation.isInvitationNameChangedManually) {
        tempInvitation.invitation.name = updateInvitationName(tempInvitation.invitation.guests)
      }
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_INPUT_GUEST_SURNAME: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].surname = action.payload
      return tempInvitation
    }
    case ACTION_TYPES.UPDATE_INPUT_NAME: {
      return { ...state, isInvitationNameUpdating: action.payload }
    }
    case ACTION_TYPES.UPDATE_GUEST_ID: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].id = action.payload
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_TOGGLE_INTOLERANCES: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].rsvpSettings.showIntolerances = action.payload
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_TOGGLE_EMAIL: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].rsvpSettings.showEmail = action.payload
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_TOGGLE_BUS: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].rsvpSettings.showBusTransferNeeded = action.payload
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_TOGGLE_DATE: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].rsvpSettings.showArrivalDate = action.payload
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_TOGGLE_DRAFT: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].isDraft = action.payload
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_DROPDOWN_TYPE: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].typeId = action.payload
      return tempInvitation
    }
    case ACTION_TYPES.CHANGE_DROPDOWN_GROUP: {
      const tempInvitation = { ...state }
      tempInvitation.invitation.guests[action.arrayIndex].groupId = action.payload
      return tempInvitation
    }
    default:
      return state
  }
}
