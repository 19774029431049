import { ReactNode } from "react"

interface CustomCardProps {
  title: ReactNode
  children: ReactNode
  footer?: ReactNode
}

export const CustomCard = ({ title, children, footer }: CustomCardProps) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "16px",
        padding: "15px",
        minHeight: "50px",
      }}
    >
      {title ? <div style={{ color: "#27272E", fontWeight: "bold", fontSize: "16px" }}>{title}</div> : null}
      {children ? <div style={{ color: "#425466", fontWeight: "normal", fontSize: "14px" }}>{children}</div> : null}
      {footer ? <div>{footer}</div> : null}
    </div>
  )
}
