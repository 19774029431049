import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import dashboardIcon from "../icon/dashboard_icon.svg"
import invitaionIcon from "../icon/invitation_icon.svg"
import rsvpIcon from "../icon/rsvp_sidebar_icon.svg"
import tableIcon from "../icon/table_icon.svg"
import settingsIcon from "../icon/settings_icon.svg"
import logoutIcon from "../icon/logout_icon.svg"
import wamooIcon from "../icon/wamoo_icon.svg"
import { logout } from "../components/auth/authSlice"
import useCheckMobileResolution from "../hook/useCheckMobileResolution"

interface SidebarProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function Sidebar({ sidebar, showSidebar }: SidebarProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useCheckMobileResolution()
  const { tenant } = useParams<string>()

  return (
    <>
      <nav id="sidebar" className={isMobile && sidebar ? "active" : ""}>
        <div className="sidebar-header">
          <img
            alt="WamooIcon"
            src={wamooIcon}
            onClick={() => navigate(`/${tenant}/dashboard`)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="sidebar-body">
          <ul className="components" onClick={() => showSidebar(false)}>
            <li>
              <img alt="DashboardIcon" src={dashboardIcon} style={{ marginRight: "15px" }} />
              <Link to={`/${tenant}/dashboard`}>Dashboard</Link>
            </li>
            <li>
              <img alt="InvitaionIcon" src={invitaionIcon} style={{ marginRight: "15px" }} />
              <Link to={`/${tenant}/invitations`}>Invitati</Link>
            </li>
            <li>
              <img alt="RsvpIcon" src={rsvpIcon} style={{ marginRight: "15px" }} />
              <Link to={`/${tenant}/rsvp`}>RSVP</Link>
            </li>
            <li>
              <img alt="RsvpIcon" src={tableIcon} style={{ marginRight: "15px" }} />
              <Link to={`/${tenant}/tables`}>Tavoli (Beta)</Link>
            </li>
          </ul>
        </div>

        <hr
          style={{
            border: "1px solid #eff4f8",
            color: "#eff4f8",
            backgroundColor: "#eff4f8",
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "80%",
          }}
        />
        <div className="sidebar-footer">
          <ul className="components" onClick={() => showSidebar(false)}>
            <li>
              <img alt="SettingsIcon" src={settingsIcon} style={{ marginRight: "15px" }} />
              <Link to={`/${tenant}/account`}>Impostazioni</Link>
            </li>
            <li>
              <img alt="LogoutIcon" src={logoutIcon} style={{ marginRight: "15px" }} />
              <Link to={`/home`} onClick={() => dispatch(logout())}>
                Logout
              </Link>
            </li>
            {/* <li>
              <small className="col-sm">&copy;{new Date().getFullYear()} Wamoo</small>
            </li> */}
          </ul>
        </div>
      </nav>

      {/*       <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <div className="sidebar-body">
          <ul className="components">
            <li>
              <img alt="DashboardIcon" src={dashboardIcon} style={{ marginRight: "15px" }} />
              <Link to={`/${tenant}/dashboard`}>Dashboard</Link>
            </li>
            <li>
              <img alt="InvitaionIcon" src={invitaionIcon} style={{ marginRight: "15px" }} />
              <Link to={`/${tenant}/invitations`}>Invitati</Link>
            </li>
            <li>
              <img alt="RsvpIcon" src={rsvpIcon} style={{ marginRight: "15px" }} />
              <Link to={`/${tenant}/rsvp`}>RSVP</Link>
            </li>
          </ul>
        </div>
      </nav> */}
    </>
  )
}
