import { updateInvitationStatusAPI } from "../../api/invitationsApi"
import { InvitationInfoData } from "../../models/invitationsApiTypes"
import { toastWarning } from "../../utils/toastMethod"
import Select from "react-select"

interface SelectOptions {
  value: string
  label: string
  colorSelected: string
  colorFocused: string
  colorText: string
}

export enum INVITATION_STATE_VALUE {
  TOBEDELIVER = "ToBeDelivered",
  DELIVERED = "Delivered",
  PENDING = "Draft",
}

export enum INVITATION_STATE_LABEL {
  TOBEDELIVER = "Da consegnare",
  DELIVERED = "Consegnato",
  PENDING = "Bozza",
}

const invitationStateOption: SelectOptions[] = [
  {
    value: INVITATION_STATE_VALUE.TOBEDELIVER,
    label: INVITATION_STATE_LABEL.TOBEDELIVER,
    colorSelected: "#f8d7da",
    colorFocused: "#f8d7da",
    colorText: "#881c24",
  },
  {
    value: INVITATION_STATE_VALUE.DELIVERED,
    label: INVITATION_STATE_LABEL.DELIVERED,
    colorSelected: "#d4edda",
    colorFocused: "#d4edda",
    colorText: "#155724",
  },
]

interface InvitationsStateProps {
  tenant: string | undefined
  invitation: InvitationInfoData
  invitations: InvitationInfoData[] | undefined
  setInvitations: (newValue: InvitationInfoData[] | undefined) => void
}

export default function InvitationsState({ tenant, invitation, invitations, setInvitations }: InvitationsStateProps) {
  const colourStyles = {
    container: (styles: any) => ({
      ...styles,
      minWidth: "150px",
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "#ffffff",
      borderColor: "#ffffff",
      boxShadow: "none",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#ffffff",
      },
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 0,
    }),
    option: (styles: any, { data, isFocused, isSelected }: any) => {
      return {
        ...styles,
        color: isSelected ? data.colorText : isFocused ? data.colorText : undefined,
        backgroundColor: isSelected ? data.colorSelected : isFocused ? data.colorFocused : undefined,
        ":active": {
          ...styles[":active"],
          backgroundColor: data.colorSelected,
        },
      }
    },
    valueContainer: (styles: any) => ({
      ...styles,
      borderRadius: 20,
    }),
    singleValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        padding: 4,
        borderRadius: 4,
        textWeight: "500",
        textAlign: "center",
        color: data.colorText,
        backgroundColor: data.colorSelected,
      }
    },
  }

  const setNewInvitationStatus = (newStatus: string) => {
    if (invitation.status === newStatus) return

    const newInvitations = invitations?.map((i) => {
      if (i.id === invitation.id) {
        return { ...i, status: newStatus }
      }
      return i
    })
    setInvitations(newInvitations)

    const newInvitationStatus = invitationStateOption.find((x) => x.label === newStatus)?.value
    updateInvitationStatusAPI(tenant, invitation.id, {
      status: newInvitationStatus !== undefined ? newInvitationStatus : INVITATION_STATE_VALUE.TOBEDELIVER,
    }).catch(() => {
      // In case of errors reset to the original invitations value
      setInvitations(invitations)
      toastWarning("Qualcosa è andato storto. Riprova!")
    })
  }

  return (
    <Select
      value={invitationStateOption.find((x) => x.label === invitation.status)}
      isSearchable={false}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      styles={colourStyles}
      options={invitationStateOption}
      onChange={(e) => setNewInvitationStatus(e !== null ? e.label : "")}
    />
  )
}
