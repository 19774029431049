import { useEffect, useState } from "react"

interface CountDownProps {
  targetDate: number
}

type CountDownDate = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const convertMS = (ms: number): CountDownDate => {
  let m = 0
  let s = 0
  let h = 0
  let d = 0
  s = Math.floor(ms / 1000)
  m = Math.floor(s / 60)
  s = s % 60
  h = Math.floor(m / 60)
  m = m % 60
  d = Math.floor(h / 24)
  h = h % 24

  const countDownDate: CountDownDate = {
    days: d,
    hours: h,
    minutes: m,
    seconds: s,
  }

  return countDownDate
}

export default function CountDown({ targetDate }: CountDownProps) {
  const countDownTargetDate = new Date(targetDate).setUTCHours(0, 0, 0, 0)
  const [countDown, setCountDown] = useState(
    countDownTargetDate > new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
      ? countDownTargetDate - (new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      : 0
  )
  const [countDownDate, setCountDownDate] = useState<CountDownDate>({ days: 0, hours: 0, minutes: 0, seconds: 0 })

  useEffect(() => {
    const interval = setInterval(() => {
      const actualDate = new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
      if (countDownTargetDate > actualDate) {
        setCountDown(countDownTargetDate - actualDate)
      } else {
        setCountDown(0)
      }
    }, 1000)

    setCountDownDate(convertMS(countDown))

    return () => clearInterval(interval)
  }, [countDown, countDownTargetDate])

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <div style={{ display: "flex", flexDirection: "column", margin: "10px" }}>
        <div style={{ marginBottom: "2px" }}>{countDownDate.days}</div>
        <p style={{ fontSize: "10px" }}>Giorni</p>
      </div>
      <div style={{ display: "flex", flexDirection: "column", margin: "10px" }}>
        <div style={{ marginBottom: "2px" }}>{countDownDate.hours}</div>
        <p style={{ fontSize: "10px" }}>Ore</p>
      </div>
      <div style={{ display: "flex", flexDirection: "column", margin: "10px" }}>
        <div style={{ marginBottom: "2px" }}>{countDownDate.minutes}</div>
        <p style={{ fontSize: "10px" }}>Minuti</p>
      </div>
      <div style={{ display: "flex", flexDirection: "column", margin: "10px" }}>
        <div style={{ marginBottom: "2px" }}>{countDownDate.seconds}</div>
        <p style={{ fontSize: "10px" }}>Secondi</p>
      </div>
    </div>
  )
}
