import { WeddingInfo } from "../../../models/weddingApiTypes"
import WeddingCounterInfoInvitations from "./WeddingCounterInfoInvitations"
import WeddingCounterInfoScanQrCodeTile from "./WeddingCounterInfoScanQrCodeTile"
import WeddingCounterInfoTableTile from "./WeddingCounterInfoTableTile"

interface WeddingCountersInfoTileProps {
  wedding: WeddingInfo | undefined
}

export default function WeddingCountersInfoTile({ wedding }: WeddingCountersInfoTileProps) {
  return (
    <>
      <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            flex: "4",
            marginBottom: "12px",
          }}
        >
          <WeddingCounterInfoInvitations wedding={wedding} />
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            flex: "4",
            marginBottom: "12px",
            marginTop: "12px",
          }}
        >
          <WeddingCounterInfoScanQrCodeTile wedding={wedding} />
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            flex: "4",
            marginTop: "12px",
          }}
        >
          <WeddingCounterInfoTableTile wedding={wedding} />
        </div>
      </div>
    </>
  )
}
