import { ReactNode } from "react"

interface HeaderBarProps {
  title: ReactNode
  colorTitle?: string
  subtitleLogo?: ReactNode
  subtitle?: ReactNode
  colorSubtitle?: string
  children?: ReactNode
}

export default function HeaderBar({ title, colorTitle = "#000000", subtitleLogo, subtitle, children }: HeaderBarProps) {
  return (
    <div className="header-bar">
      <div style={{ flex: "6", marginTop: "auto", marginBottom: "auto", paddingLeft: "30px" }}>
        <div style={{ color: colorTitle, display: "flex", flexDirection: "column" }}>
          {title ? (
            <div className="welcome-message" style={{ textAlign: "left" }}>
              {title}
            </div>
          ) : null}

          <div style={{ display: "flex", textAlign: "left", alignItems: "center" }}>
            {subtitleLogo ? <>{subtitleLogo}</> : null}
            {subtitle ? <>{subtitle}</> : null}
          </div>
        </div>
      </div>
      {children ? (
        <div
          style={{
            flex: "4",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "auto",
            marginBottom: "auto",
            paddingRight: "30px",
          }}
        >
          {children}
        </div>
      ) : null}
    </div>
  )
}
