import { ReactNode } from "react"
import { useAppSelector } from "../../../hook/useReduxHooks"
import { RootState } from "../../../store"
import AuthRequired from "./AuthRequired"
import AuthInProgress from "./AuthInProgress"
import AuthFailed from "./AuthFailed"
import wamooIcon from "../../../icon/wamoo_icon.svg"

interface AuthRequiredPageProps {
  children?: ReactNode
}

export default function AuthRequiredPage({ children }: AuthRequiredPageProps) {
  const auth = useAppSelector((state: RootState) => state.auth)

  if (auth.account) return <>{children}</>
  else {
    if (auth.isLoading)
      return (
        <div style={{ display: "flex", flexDirection: "column", margin: "auto", alignItems: "center" }}>
          <img alt="wamooIcon" src={wamooIcon} style={{ marginBottom: "50px" }} />
          <AuthInProgress />
        </div>
      )
    else if (auth.error)
      return (
        <div style={{ display: "flex", flexDirection: "column", margin: "auto", alignItems: "center" }}>
          <img alt="wamooIcon" src={wamooIcon} style={{ marginBottom: "50px" }} />
          <AuthFailed />
        </div>
      )
    else
      return (
        <div style={{ display: "flex", flexDirection: "column", margin: "auto", alignItems: "center" }}>
          <img alt="wamooIcon" src={wamooIcon} style={{ marginBottom: "50px" }} />
          <AuthRequired />
        </div>
      )
  }
}
