import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../hook/useReduxHooks"
import { SeatDetailsData } from "../../../models/tableApiTypes"
import { RootState } from "../../../store"
import { setToolPaletView, TOOL_PALET_VIEW_TYPE } from "../tableSlice"

const CHAIR_RADIUS = 20
const CHAIR_NAME_TEXT_OFFSET = -35

interface TableSeatProps {
  seatDetails: SeatDetailsData
  rotateDegreeNumberSeat: number
}

export default function TableSeat({ seatDetails, rotateDegreeNumberSeat }: TableSeatProps) {
  const [hover, setHover] = useState(false)
  const dispatch = useAppDispatch()
  const showGuestSurname = useAppSelector((state: RootState) => state.table.showGuestSurname)
  const elementSelected = useAppSelector((state: RootState) => state.table.toolPalet)
  const isSeatSelected = elementSelected.toolPaletSeatData?.id === seatDetails.id

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const handleClick = () => {
    dispatch(
      setToolPaletView({
        newToolPaletView: { toolPaletViewType: TOOL_PALET_VIEW_TYPE.SEAT, toolPaletSeatData: seatDetails },
      })
    )
  }

  return (
    <g transform={`translate(${seatDetails.shape.x}, ${seatDetails.shape.y}) rotate(0)`}>
      <circle
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerDown={handleClick}
        style={
          isSeatSelected
            ? { fill: "#e7e7e7", opacity: "100%", stroke: hover ? "#818181" : undefined }
            : seatDetails.guest !== null
            ? {
                fill: "#d6d3d3",
                stroke: "#425466",
                strokeWidth:
                  seatDetails.guest.rsvp !== undefined
                    ? seatDetails.guest.rsvp.intolerances.find((i) => i.length > 0)
                      ? "3"
                      : "0.5"
                    : "0.5",
              }
            : {
                fill: hover ? "#e7e7e7" : "#fafafa",
                opacity: hover ? "100%" : "40%",
                stroke: "#818181",
                strokeWidth: "1",
                zIndex: "0",
              }
        }
        cx={0}
        cy={0}
        r={CHAIR_RADIUS}
      />
      <text
        className="table_seat_text"
        style={{ fill: "#a5a5a5", fontSize: "1.2em" }}
        x={0}
        y={1}
        textAnchor={"middle"}
        dominantBaseline={"middle"}
        transform={`rotate(${rotateDegreeNumberSeat})`}
      >
        {seatDetails.order}
      </text>
      {seatDetails.guest !== null && seatDetails.guest.name !== null && seatDetails.guest.name.length > 0 ? (
        <g transform={`rotate(${seatDetails.shape.rotationDegrees})`}>
          <text
            className="table_seat_guest_name_text"
            style={{ fill: "#000000", fontSize: "1.4em", fontWeight: "bold" }}
            x={0}
            y={-20}
            dy={CHAIR_NAME_TEXT_OFFSET}
            textAnchor={"middle"}
          >
            {seatDetails.guest.name}
          </text>
          {showGuestSurname ? (
            <text
              className="table_seat_guest_name_text"
              style={{ fill: "#000000", fontSize: "1.4em", fontWeight: "bold" }}
              x={0}
              y={0}
              dy={CHAIR_NAME_TEXT_OFFSET}
              textAnchor={"middle"}
            >
              {seatDetails.guest.surname.split(" ").length === 0
                ? ""
                : seatDetails.guest.surname.split(" ").length === 1
                ? seatDetails.guest.surname
                : seatDetails.guest.surname.split(" ")[0] + ".."}
            </text>
          ) : null}
        </g>
      ) : null}
    </g>
  )
}
