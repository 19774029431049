import { GuestRsvpReport, SendEmail } from "../models/guestRsvpApiTypes"
import { toastPromise } from "../utils/toastMethod"
import axiosDashboard from "./clientApi"

export async function getGuestRsvpAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/guestsrsvp`).then((response) => response.data)
}

export async function downloadGuestsFileAPI(tenant: string | undefined, showAll: boolean) {
  return axiosDashboard
    .get(`/dashboard/${tenant}/guestsrsvp/pdf?showAll=${showAll}`, { responseType: "blob" })
    .then((response) => response.data)
}

export async function downloadGuestRsvpExportApi(tenant: string | undefined, payload: GuestRsvpReport) {
  return new Promise<Blob>((resolve) => {
    toastPromise(
      axiosDashboard
        .post(`/dashboard/${tenant}/guestsrsvp/export`, payload, {
          responseType: "blob",
          timeout: 60000,
        })
        .then((response) => resolve(response.data)),
      "Download in corso",
      "Download completato",
      "Qualcosa è andato storto"
    )
  })
}

export async function sendEmailApi(tenant: string | undefined, payload: SendEmail) {
  return new Promise<boolean>((resolve) => {
    toastPromise(
      axiosDashboard
        .post(`/dashboard/${tenant}/guestsrsvp/send-message`, payload, {
          timeout: 20000,
        })
        .then((response) => resolve(response.data)),
      "Invio in corso",
      "Invio completato",
      "Qualcosa è andato storto"
    )
  })
}
