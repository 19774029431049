import "../../style/provisioning_style.css"
import "react-datepicker/dist/react-datepicker.css"
import { useEffect, useState } from "react"
import ProvisioningStepBox from "./ProvisioningStepBox"
import ProvisioningSecondStep from "./ProvisioningSecondStep"
import ProvisioningThirdStep from "./ProvisioningThirdStep"
import ProvisioningFirstStep from "./ProvisioningFirstStep"
import { AddWedding, TenantRsvpSettings } from "../../models/weddingApiTypes"
import ProgressBar from "./ProgressBar"
import { useParams } from "react-router-dom"
import ProvisioningFourthStep from "./ProvisioningFourthStep"
import firstStepProvisioning from "../../icon/provisionin_1st_icon.svg"
import secondStepProvisioning from "../../icon/provisionin_2nd_icon.svg"
import thirdStepProvisioning from "../../icon/provisionin_3rd_icon.svg"
import fourthStepProvisioning from "../../icon/provisionin_4th_icon.svg"
import wamooIcon from "../../icon/wamoo_icon.svg"
import { getWeddingsRsvpTenantSettingAPI } from "../../api/weddingApi"
import { toastWarning } from "../../utils/toastMethod"

export default function Provisioning() {
  const { tenant } = useParams<string>()
  const [tenantRsvpSettings, setTenantRsvpSettings] = useState<TenantRsvpSettings>()
  const [step, setStep] = useState(0)
  const [weddingInfo, setWeddingInfo] = useState<AddWedding>({
    nameFirstOwner: "",
    surnameFirstOwner: "",
    nameSecondOwner: "",
    surnameSecondOwner: "",
    location: "",
    weddingDate: new Date(),
    rsvpSettings: {
      isRsvpEnabled: true,
      showArrivalDate: false,
      showBusTransferNeeded: false,
      showEmail: false,
      showIntolerances: false,
    },
  })

  useEffect(() => {
    tenant &&
      getWeddingsRsvpTenantSettingAPI(tenant)
        .then((result) => {
          setTenantRsvpSettings(result)
        })
        .catch(() => toastWarning("Qualcosa è andato storto. Riprova!"))
  }, [tenant])

  return (
    <>
      {tenantRsvpSettings && (
        <div className="provisioning">
          <div className="provisioning-icon">
            <img alt="wamooIcon" src={wamooIcon} style={{ marginBottom: "50px" }} />
          </div>
          <div className="provisioning-box-ext">
            <div className="provisioning-box">
              {step === 0 && (
                <>
                  <ProvisioningStepBox
                    iconAlt="FirstStepProvisioning"
                    iconSource={firstStepProvisioning}
                    title="Prima di tutto..."
                    subTitle="Come vi chiamate?"
                    progressBar={
                      <ProgressBar
                        step={step}
                        settingsStep={
                          tenantRsvpSettings.intolerancesEnabled ||
                          tenantRsvpSettings.busEnabled ||
                          tenantRsvpSettings.arrivalDateEnabled ||
                          tenantRsvpSettings.emailEnabled
                        }
                      />
                    }
                  >
                    <ProvisioningFirstStep
                      setNextStep={(nextStep) => setStep(nextStep)}
                      setNewWeddingInfo={(updatedInfo) => setWeddingInfo(updatedInfo)}
                      actualWeddingInfo={weddingInfo}
                    />
                  </ProvisioningStepBox>
                </>
              )}

              {step === 1 && (
                <>
                  <ProvisioningStepBox
                    iconAlt="SecondStepProvisioning"
                    iconSource={secondStepProvisioning}
                    title="A quando il fatidico Si?"
                    subTitle="Giorno, mese, anno..."
                    progressBar={
                      <ProgressBar
                        step={step}
                        settingsStep={
                          tenantRsvpSettings.intolerancesEnabled ||
                          tenantRsvpSettings.busEnabled ||
                          tenantRsvpSettings.arrivalDateEnabled ||
                          tenantRsvpSettings.emailEnabled
                        }
                      />
                    }
                  >
                    <ProvisioningSecondStep
                      setNextStep={(nextStep) => setStep(nextStep)}
                      setNewWeddingInfo={(updatedInfo) => setWeddingInfo(updatedInfo)}
                      actualWeddingInfo={weddingInfo}
                    />
                  </ProvisioningStepBox>
                </>
              )}
              {step === 2 && (
                <>
                  <ProvisioningStepBox
                    iconAlt="ThirdStepProvisioning"
                    iconSource={thirdStepProvisioning}
                    title="Avete già una location?"
                    subTitle="Ci basta il nome!"
                    progressBar={
                      <ProgressBar
                        step={step}
                        settingsStep={
                          tenantRsvpSettings.intolerancesEnabled ||
                          tenantRsvpSettings.busEnabled ||
                          tenantRsvpSettings.arrivalDateEnabled ||
                          tenantRsvpSettings.emailEnabled
                        }
                      />
                    }
                  >
                    <ProvisioningThirdStep
                      tenant={tenant}
                      setNextStep={(nextStep) => setStep(nextStep)}
                      setNewWeddingInfo={(updatedInfo) => setWeddingInfo(updatedInfo)}
                      actualWeddingInfo={weddingInfo}
                      rsvpTenantSettings={tenantRsvpSettings}
                    />
                  </ProvisioningStepBox>
                </>
              )}

              {step === 3 && (
                <>
                  <ProvisioningStepBox
                    iconAlt="FourthStepProvisioning"
                    iconSource={fourthStepProvisioning}
                    title="Cosa vi serve sapere?"
                    subTitle="Seleziona, schicciando sul toggle, le informazioni che vorresti ricevere dai tuoi ospiti."
                    progressBar={
                      <ProgressBar
                        step={step}
                        settingsStep={
                          tenantRsvpSettings.intolerancesEnabled ||
                          tenantRsvpSettings.busEnabled ||
                          tenantRsvpSettings.arrivalDateEnabled ||
                          tenantRsvpSettings.emailEnabled
                        }
                      />
                    }
                  >
                    <ProvisioningFourthStep
                      tenant={tenant}
                      setNextStep={(nextStep) => setStep(nextStep)}
                      setNewWeddingInfo={(updatedInfo) => setWeddingInfo(updatedInfo)}
                      actualWeddingInfo={weddingInfo}
                      rsvpTenantSettings={tenantRsvpSettings}
                    />
                  </ProvisioningStepBox>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
