import { useAppDispatch } from "../../../hook/useReduxHooks"
import { TableDetailsData } from "../../../models/tableApiTypes"
import { TOOL_PALET_VIEW_TYPE, setToolPaletView } from "../tableSlice"
import Table from "./Table"

interface TablesLayoutProps {
  tables: TableDetailsData[]
  setChildActive: (isActive: boolean) => void
  isPublicViewer: boolean
}

export default function TablesLayout({ tables, setChildActive, isPublicViewer }: TablesLayoutProps) {
  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(
      setToolPaletView({
        newToolPaletView: { toolPaletViewType: TOOL_PALET_VIEW_TYPE.NONE },
      })
    )
  }

  return (
    <>
      <rect width="10000" height="6000" fill="url(#grid)" onPointerDown={handleClick} />
      <g id="tablesLayout">
        {tables.map((table, index) => {
          return (
            <Table
              key={index}
              tableDetails={table}
              setChildActive={(isActive) => setChildActive(isActive)}
              isPublicViewer={isPublicViewer}
            />
          )
        })}
      </g>
    </>
  )
}
