import React from "react"
import ReactDOM from "react-dom/client"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "./style/sidebar-style.css"
import "react-toastify/dist/ReactToastify.css"
import "./App.css"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import Bowser from "bowser"

const browser = Bowser.getParser(window.navigator.userAgent)
const isOldBrowser = browser.satisfies({
  ie: "<=11",
  firefox: "<=27",
  opera: "<=12",
  safari: "<=6",
  chrome: "<=20",
})

if (isOldBrowser) {
  document.getElementById("old-browser-alert")?.classList.remove("hidden")
}

/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
) */

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
