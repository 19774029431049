interface ProgressBarProps {
  step: number
  settingsStep: boolean
}

export default function ProgressBar({ step, settingsStep }: ProgressBarProps) {
  return (
    <div className="progress-bar">
      {step === 0 && (
        <>
          <div className="progress-bar-line"></div>
          <div className="progress-bar-line" style={{ backgroundColor: "#CBD5E0" }}></div>
          <div className="progress-bar-line" style={{ backgroundColor: "#CBD5E0" }}></div>
          {settingsStep ? <div className="progress-bar-line" style={{ backgroundColor: "#CBD5E0" }}></div> : null}
        </>
      )}

      {step === 1 && (
        <>
          <div className="progress-bar-line"></div>
          <div className="progress-bar-line"></div>
          <div className="progress-bar-line" style={{ backgroundColor: "#CBD5E0" }}></div>
          {settingsStep ? <div className="progress-bar-line" style={{ backgroundColor: "#CBD5E0" }}></div> : null}
        </>
      )}

      {step === 2 && (
        <>
          <div className="progress-bar-line"></div>
          <div className="progress-bar-line"></div>
          <div className="progress-bar-line"></div>
          {settingsStep ? <div className="progress-bar-line" style={{ backgroundColor: "#CBD5E0" }}></div> : null}
        </>
      )}

      {step === 3 && (
        <>
          <div className="progress-bar-line"></div>
          <div className="progress-bar-line"></div>
          <div className="progress-bar-line"></div>
          <div className="progress-bar-line"></div>
        </>
      )}
    </div>
  )
}
