import { useEffect, useState } from "react"
import { VictoryPie } from "victory"
import { WeddingInfo } from "../../../models/weddingApiTypes"

interface WeddingInvitationsRecapProps {
  wedding: WeddingInfo
}

const defaultGraphData = [
  { x: " ", y: 100 },
  { x: " ", y: 0 },
  { x: " ", y: 0 },
  { x: " ", y: 0 },
]

export default function WeddingInvitationsRecap({ wedding }: WeddingInvitationsRecapProps) {
  const [graphData, setGraphicData] = useState(defaultGraphData)

  useEffect(() => {
    wedding &&
      setGraphicData([
        { x: " ", y: wedding.counters.guests.counters.attending },
        {
          x: " ",
          y:
            wedding.counters.guests.counters.totals -
            wedding.counters.guests.counters.attending -
            wedding.counters.guests.counters.declined -
            wedding.counters.guests.counters.draft,
        },
        { x: " ", y: wedding.counters.guests.counters.declined },
        { x: " ", y: wedding.counters.guests.counters.draft },
      ])
  }, [wedding])

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <VictoryPie
        animate={{ easing: "exp" }}
        startAngle={90}
        endAngle={450}
        cornerRadius={10}
        height={300}
        padAngle={2}
        innerRadius={130}
        colorScale={["#4c6fff", "#ff92ae", "#fdd762", "#999999"]}
        data={graphData}
      ></VictoryPie>
      <div style={{ display: "flex", flexDirection: "row", fontSize: "14px", fontWeight: "bold" }}>
        <div style={{ flex: "3", textAlign: "center" }}>
          <svg width="15" height="15">
            <circle cx="7" cy="6" r="4" fill="#4c6fff" />
          </svg>
          {wedding.counters.guests.counters.attending}
          <p style={{ fontSize: "12px", fontWeight: "normal", color: "#425466" }}>Confermati</p>
        </div>
        <div style={{ flex: "3", textAlign: "center" }}>
          <svg width="15" height="15">
            <circle cx="7" cy="6" r="4" fill="#ff92ae" />
          </svg>
          {wedding.counters.guests.counters.totals -
            wedding.counters.guests.counters.attending -
            wedding.counters.guests.counters.declined -
            wedding.counters.guests.counters.draft}
          <p style={{ fontSize: "12px", fontWeight: "normal", color: "#425466" }}>In attesa</p>
        </div>
        <div style={{ flex: "3", textAlign: "center" }}>
          <svg width="15" height="15">
            <circle cx="7" cy="6" r="4" fill="#fdd762" />
          </svg>
          {wedding.counters.guests.counters.declined}
          <p style={{ fontSize: "12px", fontWeight: "normal", color: "#425466" }}>Disdetta</p>
        </div>
        {wedding.counters.guests.counters.draft > 0 && (
          <div style={{ flex: "3", textAlign: "center" }}>
            <svg width="15" height="15">
              <circle cx="7" cy="6" r="4" fill="#999999" />
            </svg>
            {wedding.counters.guests.counters.draft}
            <p style={{ fontSize: "12px", fontWeight: "normal", color: "#425466" }}>Bozza</p>
          </div>
        )}
      </div>
      <hr
        style={{
          border: "1px solid #e4eaff",
          color: "#e4eaff",
          backgroundColor: "#e4eaff",
          marginTop: "0px",
          marginBottom: "0px",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50%",
        }}
      />
      <div style={{ fontSize: "14px", fontWeight: "normal", color: "#718096", textAlign: "center" }}>
        di {wedding.counters.guests.counters.totals} invitati
      </div>
    </div>
  )
}
