import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { getTablesLayoutShareLink, updateTablesLayoutShareLinkEnable } from "../../../api/tableApi"
import { useAppDispatch, useAppSelector } from "../../../hook/useReduxHooks"
import { TablesLayoutShareEnable } from "../../../models/tableApiTypes"
import { RootState } from "../../../store"
import { toastWarning } from "../../../utils/toastMethod"
import { setTableLayoutState } from "../tableSlice"

interface TableSharePublicLinkModalProps {
  tenant: string | undefined
  show: boolean
  setShow: (show: boolean) => void
}

export default function TableSharePublicLinkModal({ tenant, show, setShow }: TableSharePublicLinkModalProps) {
  const [isCopied, setIsCopied] = useState(false)
  const [linkToShare, setLinkToShare] = useState("")
  const tablesLayout = useAppSelector((state: RootState) => state.table.layout)
  const isSahringEnabled = useAppSelector((state: RootState) => state.table.layout.sharingEnabled)
  const dispatch = useAppDispatch()

  useEffect(() => {
    show &&
      isSahringEnabled &&
      getTablesLayoutShareLink(tenant, tablesLayout.id)
        .then((result) => {
          setLinkToShare(result)
        })
        .catch(() => toastWarning("Errore nel caricamento dei tavoli. Riprova!"))
  }, [isSahringEnabled, show, tablesLayout.id, tenant])

  const copyText = async () => {
    await navigator.clipboard.writeText(linkToShare)
  }

  const handleCopyClick = () => {
    copyText().then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    })
  }

  const handleCheckBoxChange = (newValue: boolean) => {
    const enableShare: TablesLayoutShareEnable = {
      enabled: newValue,
    }
    updateTablesLayoutShareLinkEnable(tenant, tablesLayout.id, enableShare)
      .then((result) => {
        setLinkToShare(result)
        dispatch(
          setTableLayoutState({
            tablesLayout: {
              id: tablesLayout.id,
              name: tablesLayout.name,
              description: tablesLayout.description,
              approved: tablesLayout.approved,
              sharingEnabled: newValue,
            },
          })
        )
      })
      .catch(() => {
        toastWarning("Qualcosa è andato storto. Riprova!")
      })
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Modal.Title>Condividi la mappa dei tavoli</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "30px" }}>
        {!tablesLayout.sharingEnabled ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: "9" }}>
              <label className="toggle-form-label">Abilita condivisione Link</label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={tablesLayout.sharingEnabled}
                onChange={(e) => handleCheckBoxChange(e.target.checked)}
              ></input>
            </div>
          </div>
        ) : (
          <>
            Copia il link sotto e condivilo con tuoi cari per mostrargli la mappa dei tavoli del vostro grande giorno 🥳
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input className="form-input" type="text" value={linkToShare} readOnly />
              <div style={{ marginLeft: "10px" }}>
                {!isCopied ? (
                  <div onClick={handleCopyClick} style={{ cursor: "pointer" }}>
                    Copia
                  </div>
                ) : (
                  <div style={{ color: "green" }}>Copiato</div>
                )}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "40px" }}>
              <div style={{ flex: "9" }}>
                <label className="toggle-form-label">Disabilita condivisione Link</label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={tablesLayout.sharingEnabled}
                  onChange={(e) => handleCheckBoxChange(e.target.checked)}
                ></input>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
