import { useEffect, useState } from "react"
import { PatchWeddingSettings, RsvpSettings } from "../../models/weddingApiTypes"
import { CustomTooltip } from "./../CustomTooltip"
import {
  getWeddingsRsvpSettingAPI,
  updateShowArrivalDateAPI,
  updateShowBusTransferAPI,
  updateShowEmailAPI,
  updateShowIntolerancesAPI,
} from "../../api/weddingApi"

enum FLAG_TYPE {
  INTOLERANCES = "showIntolerances",
  BUS_TRANSFER = "showBusTransferNeeded",
  ARRIVAL_DATE = "showArrivalDate",
  EMAIL = "showEmail",
}

interface SettingsRsvpProps {
  tenant: string | undefined
}

export default function SettingsRsvp({ tenant }: SettingsRsvpProps) {
  const [rsvpSettings, setRsvpSettings] = useState<RsvpSettings>()
  const [rsvpSettingsEnable, setRsvpSettingsEnable] = useState<boolean>(false)

  useEffect(() => {
    tenant &&
      getWeddingsRsvpSettingAPI(tenant)
        .then((result) => {
          setRsvpSettings(result)
        })
        .catch(() => console.error("ERROR"))
  }, [tenant])

  const updateSettings = async (
    show: boolean,
    varToUpdate: string,
    updateSettingsAPI: (tenant: string | undefined, payload: PatchWeddingSettings) => Promise<string>
  ) => {
    if (rsvpSettings !== undefined) {
      const newSettings: PatchWeddingSettings = {
        show,
        updateAll: show,
      }
      let oldValue: boolean
      switch (varToUpdate) {
        case FLAG_TYPE.INTOLERANCES:
          oldValue = rsvpSettings.weddingRsvpSettings.showIntolerances
          setRsvpSettings({
            ...rsvpSettings,
            weddingRsvpSettings: { ...rsvpSettings.weddingRsvpSettings, showIntolerances: show },
            tenantRsvpSettings: rsvpSettings.tenantRsvpSettings,
          })
          break
        case FLAG_TYPE.BUS_TRANSFER:
          oldValue = rsvpSettings.weddingRsvpSettings.showBusTransferNeeded
          setRsvpSettings({
            ...rsvpSettings,
            weddingRsvpSettings: { ...rsvpSettings.weddingRsvpSettings, showBusTransferNeeded: show },
            tenantRsvpSettings: rsvpSettings.tenantRsvpSettings,
          })
          break
        case FLAG_TYPE.ARRIVAL_DATE:
          oldValue = rsvpSettings.weddingRsvpSettings.showArrivalDate
          setRsvpSettings({
            ...rsvpSettings,
            weddingRsvpSettings: { ...rsvpSettings.weddingRsvpSettings, showArrivalDate: show },
            tenantRsvpSettings: rsvpSettings.tenantRsvpSettings,
          })
          break
        case FLAG_TYPE.EMAIL:
          oldValue = rsvpSettings.weddingRsvpSettings.showEmail
          setRsvpSettings({
            ...rsvpSettings,
            weddingRsvpSettings: { ...rsvpSettings.weddingRsvpSettings, showEmail: show },
            tenantRsvpSettings: rsvpSettings.tenantRsvpSettings,
          })
          break
        default:
          break
      }
      setRsvpSettingsEnable(true)
      updateSettingsAPI(tenant, newSettings)
        .then(() => setRsvpSettingsEnable(false))
        .catch(() => {
          switch (varToUpdate) {
            case FLAG_TYPE.INTOLERANCES:
              setRsvpSettings({
                ...rsvpSettings,
                weddingRsvpSettings: { ...rsvpSettings.weddingRsvpSettings, showIntolerances: oldValue },
                tenantRsvpSettings: rsvpSettings.tenantRsvpSettings,
              })
              break
            case FLAG_TYPE.BUS_TRANSFER:
              setRsvpSettings({
                ...rsvpSettings,
                weddingRsvpSettings: { ...rsvpSettings.weddingRsvpSettings, showBusTransferNeeded: oldValue },
                tenantRsvpSettings: rsvpSettings.tenantRsvpSettings,
              })
              break
            case FLAG_TYPE.ARRIVAL_DATE:
              setRsvpSettings({
                ...rsvpSettings,
                weddingRsvpSettings: { ...rsvpSettings.weddingRsvpSettings, showArrivalDate: oldValue },
                tenantRsvpSettings: rsvpSettings.tenantRsvpSettings,
              })
              break
            case FLAG_TYPE.EMAIL:
              setRsvpSettings({
                ...rsvpSettings,
                weddingRsvpSettings: { ...rsvpSettings.weddingRsvpSettings, showEmail: oldValue },
                tenantRsvpSettings: rsvpSettings.tenantRsvpSettings,
              })
              break
            default:
              break
          }
          setRsvpSettingsEnable(false)
        })
    }
  }
  return (
    <>
      {rsvpSettings && (
        <div className="form-container">
          <label className="form-label">RSVP settings</label>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: "9" }}>
                <label className="toggle-form-label">Abilita intolleranze</label>
              </div>
              <div style={{ flex: "1", justifyContent: "flex-end" }}>
                <CustomTooltip
                  text="Funzione disabilitata"
                  show={
                    !rsvpSettings.tenantRsvpSettings.intolerancesEnabled !== undefined
                      ? !rsvpSettings.tenantRsvpSettings.intolerancesEnabled
                      : true
                  }
                >
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      disabled={rsvpSettings.tenantRsvpSettings.intolerancesEnabled ? rsvpSettingsEnable : true}
                      checked={rsvpSettings.weddingRsvpSettings.showIntolerances}
                      onChange={(e) =>
                        updateSettings(e.target.checked, FLAG_TYPE.INTOLERANCES, updateShowIntolerancesAPI)
                      }
                    ></input>
                  </div>
                </CustomTooltip>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: "9" }}>
                <label className="toggle-form-label">Abilita bus</label>
              </div>
              <div style={{ flex: "1", justifyContent: "flex-end" }}>
                <CustomTooltip
                  text="Funzione disabilitata"
                  show={
                    !rsvpSettings.tenantRsvpSettings.busEnabled !== undefined
                      ? !rsvpSettings.tenantRsvpSettings.busEnabled
                      : true
                  }
                >
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      disabled={rsvpSettings.tenantRsvpSettings.busEnabled ? rsvpSettingsEnable : true}
                      checked={rsvpSettings.weddingRsvpSettings.showBusTransferNeeded}
                      onChange={(e) =>
                        updateSettings(e.target.checked, FLAG_TYPE.BUS_TRANSFER, updateShowBusTransferAPI)
                      }
                    ></input>
                  </div>
                </CustomTooltip>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: "9" }}>
                <label className="toggle-form-label">Abilita data di arrivo</label>
              </div>
              <div style={{ flex: "1", justifyContent: "flex-end" }}>
                <CustomTooltip
                  text="Funzione disabilitata"
                  show={
                    !rsvpSettings.tenantRsvpSettings.arrivalDateEnabled !== undefined
                      ? !rsvpSettings.tenantRsvpSettings.arrivalDateEnabled
                      : true
                  }
                >
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      disabled={rsvpSettings.tenantRsvpSettings.arrivalDateEnabled ? rsvpSettingsEnable : true}
                      checked={rsvpSettings.weddingRsvpSettings.showArrivalDate}
                      onChange={(e) =>
                        updateSettings(e.target.checked, FLAG_TYPE.ARRIVAL_DATE, updateShowArrivalDateAPI)
                      }
                    ></input>
                  </div>
                </CustomTooltip>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: "9" }}>
                <label className="toggle-form-label">Abilita email</label>
              </div>
              <div style={{ flex: "1", justifyContent: "flex-end" }}>
                <CustomTooltip
                  text="Funzione disabilitata"
                  show={
                    !rsvpSettings.tenantRsvpSettings.emailEnabled !== undefined
                      ? !rsvpSettings.tenantRsvpSettings.emailEnabled
                      : true
                  }
                >
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      disabled={rsvpSettings.tenantRsvpSettings.emailEnabled ? rsvpSettingsEnable : true}
                      checked={rsvpSettings.weddingRsvpSettings.showEmail}
                      onChange={(e) => updateSettings(e.target.checked, FLAG_TYPE.EMAIL, updateShowEmailAPI)}
                    ></input>
                  </div>
                </CustomTooltip>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
