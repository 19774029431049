import { Form, Formik } from "formik"
import { addWeddingAPI } from "../../api/weddingApi"
import { useNavigate } from "react-router-dom"
import { AddWedding, TenantRsvpSettings } from "../../models/weddingApiTypes"
import { toastSuccess, toastWarning } from "../../utils/toastMethod"

interface ProvisioningFourthStepProps {
  tenant: string | undefined
  setNextStep: (newStep: number) => void
  setNewWeddingInfo: (newWeddingInfo: AddWedding) => void
  actualWeddingInfo: AddWedding
  rsvpTenantSettings: TenantRsvpSettings
}

export default function ProvisioningFourthStep({
  tenant,
  setNextStep,
  setNewWeddingInfo,
  actualWeddingInfo,
  rsvpTenantSettings,
}: ProvisioningFourthStepProps) {
  const navigate = useNavigate()

  return (
    <>
      <Formik
        initialValues={{
          nameFirstOwner: actualWeddingInfo.nameFirstOwner,
          surnameFirstOwner: actualWeddingInfo.surnameFirstOwner,
          nameSecondOwner: actualWeddingInfo.nameSecondOwner,
          surnameSecondOwner: actualWeddingInfo.surnameSecondOwner,
          weddingDate: actualWeddingInfo.weddingDate,
          location: actualWeddingInfo.location,
          rsvpSettings: {
            isRsvpEnabled: actualWeddingInfo.rsvpSettings.isRsvpEnabled,
            showArrivalDate: actualWeddingInfo.rsvpSettings.showArrivalDate,
            showBusTransferNeeded: actualWeddingInfo.rsvpSettings.showBusTransferNeeded,
            showEmail: actualWeddingInfo.rsvpSettings.showEmail,
            showIntolerances: actualWeddingInfo.rsvpSettings.showIntolerances,
          },
        }}
        onSubmit={async (values: AddWedding) => {
          setNewWeddingInfo(values)
          addWeddingAPI(tenant, values)
            .then(() => {
              toastSuccess("Benvenuto nella tua dashboard.")
              navigate(`/${tenant}/dashboard`)
            })
            .catch(() => {
              toastWarning("Qualcosa è andato storto. Riprova!")
            })
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              {rsvpTenantSettings.intolerancesEnabled && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: "9" }}>
                    <label className="provisioning-form-label-switch">Intolleranze alimentari</label>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div className="form-check form-switch" style={{ marginTop: "10px" }}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        name="rsvpSettings.showIntolerances"
                        checked={values.rsvpSettings.showIntolerances}
                        onChange={(e) => setFieldValue("rsvpSettings.showIntolerances", e.target.checked)}
                      ></input>
                    </div>
                  </div>
                </div>
              )}
              {rsvpTenantSettings.busEnabled && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: "9" }}>
                    <label className="provisioning-form-label-switch">Servizio bus navetta</label>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div className="form-check form-switch" style={{ marginTop: "10px" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="rsvpSettings.showBusTransferNeeded"
                        checked={values.rsvpSettings.showBusTransferNeeded}
                        onChange={(e) => setFieldValue("rsvpSettings.showBusTransferNeeded", e.target.checked)}
                      ></input>
                    </div>
                  </div>
                </div>
              )}
              {rsvpTenantSettings.emailEnabled && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: "9" }}>
                    <label className="provisioning-form-label-switch">Indirizzo email</label>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div className="form-check form-switch" style={{ marginTop: "10px" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="rsvpSettings.showEmail"
                        checked={values.rsvpSettings.showEmail}
                        onChange={(e) => setFieldValue("rsvpSettings.showEmail", e.target.checked)}
                      ></input>
                    </div>
                  </div>
                </div>
              )}
              {rsvpTenantSettings.arrivalDateEnabled && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: "9" }}>
                    <label className="provisioning-form-label-switch">Data di arrivo</label>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div className="form-check form-switch" style={{ marginTop: "10px" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="rsvpSettings.showArrivalDate"
                        checked={values.rsvpSettings.showArrivalDate}
                        onChange={(e) => setFieldValue("rsvpSettings.showArrivalDate", e.target.checked)}
                      ></input>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="provisoning-button-box">
              <button
                type="button"
                className="btn btn-primary provisoning-button"
                style={{ flex: "5", margin: "5px", backgroundColor: "#a6b7d4", borderColor: "#a6b7d4" }}
                onClick={() => setNextStep(2)}
              >
                Indietro
              </button>
              <button type="submit" className="btn btn-primary provisoning-button" style={{ flex: "5", margin: "5px" }}>
                Fine
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
