interface IntolerancesProps {
  intolerances: string[] | undefined
}

const HasItemNotEmpty = (intolerances: string[]) => {
  let isNotEmpty = false
  intolerances.forEach(function (intolerance) {
    if (intolerance.length > 0) isNotEmpty = true
  })
  return isNotEmpty
}

export default function Intolerances({ intolerances }: IntolerancesProps) {
  const intolerancesStyle = {
    backgroundColor: "#f5f5f5",
    color: "#4c8e9d",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "4px",
    padding: "5px",
    marginRight: "10px",
    height: "fit-content",
  }
  if (intolerances !== undefined && HasItemNotEmpty(intolerances)) {
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {intolerances.map((item: string, idx: number) => {
          if (idx < 3) {
            return (
              <div key={idx} style={intolerancesStyle}>
                <span>{item}</span>
              </div>
            )
          } else if (idx === 3) {
            return (
              <div key={idx} style={intolerancesStyle}>
                <span>...</span>
              </div>
            )
          } else return null
        })}
      </div>
    )
  } else return <></>
}
