import { useState } from "react"
import { Modal } from "react-bootstrap"
import { addTableAPI, updateTablesCanvaShapesAPI } from "../../../api/tableApi"
import { useAppDispatch } from "../../../hook/useReduxHooks"
import { AddNewTableData, ShapeBaseDataWithId, TableType, UpdateShapeData } from "../../../models/tableApiTypes"
import { toastSuccess, toastWarning } from "../../../utils/toastMethod"
import { addNewTable } from "../tableSlice"

const CHAIR_DIAMETER = 30
const CHAIR_SPACE = 80
const CHAIR_OFFSET = 25
const TABLE_RADIUS_FOR_SEAT_CIRCLE = 12
const CHAIR_OFFSET_DEGREE = 90
const TABLE_DEFAULT_X = 2500
const TABLE_DEFAULT_Y = 2500

type AddNewTable = {
  table: AddNewTableData
  seatNumberX: number
  seatNumberY: number
}

interface TableModalProps {
  tenant: string | undefined
  show: boolean
  showModal: (value: boolean) => void
  modalToShow: TableType
  title: string
}

export default function TableModal({ tenant, show, showModal, title, modalToShow }: TableModalProps) {
  const dispatch = useAppDispatch()
  const [newTable, setNewTable] = useState<AddNewTable>({
    table: {
      name: "",
      description: "",
      tableType: "",
      seatsNumber: 0,
    },
    seatNumberX: 0,
    seatNumberY: 0,
  })

  const handleClick = () => {
    newTable.table.seatsNumber =
      modalToShow === TableType.TABLE_RECT ? 2 * newTable.seatNumberX + 2 * newTable.seatNumberY : newTable.seatNumberX
    newTable.table.tableType = TableType[modalToShow]

    addTableAPI(tenant, newTable.table)
      .then((tableDetailsData) => {
        const seats: ShapeBaseDataWithId[] = []
        let updateShape: UpdateShapeData
        if (modalToShow === TableType.TABLE_RECT) {
          for (let i = 0; i < newTable.seatNumberX; i++) {
            const xUp = (CHAIR_SPACE + CHAIR_DIAMETER) * i + CHAIR_DIAMETER / 2 + CHAIR_SPACE
            const yUp = -CHAIR_OFFSET
            seats.push({ x: xUp, y: yUp, rotationDegrees: 0, width: 0, height: 0, id: "" })
          }
          for (let i = 0; i < newTable.seatNumberY; i++) {
            const xRight = (CHAIR_DIAMETER + CHAIR_SPACE) * newTable.seatNumberX + CHAIR_SPACE + CHAIR_OFFSET
            const yRight = (CHAIR_SPACE + CHAIR_DIAMETER) * i + CHAIR_DIAMETER / 2 + CHAIR_SPACE
            seats.push({
              x: xRight,
              y: yRight,
              rotationDegrees: 90,
              width: 0,
              height: 0,
              id: "",
            })
          }
          for (let i = newTable.seatNumberX - 1; i >= 0; i--) {
            const xDown = (CHAIR_SPACE + CHAIR_DIAMETER) * i + CHAIR_DIAMETER / 2 + CHAIR_SPACE
            const yDown = (CHAIR_DIAMETER + CHAIR_SPACE) * newTable.seatNumberY + CHAIR_SPACE + CHAIR_OFFSET
            seats.push({
              x: xDown,
              y: yDown,
              rotationDegrees: 180,
              width: 0,
              height: 0,
              id: "",
            })
          }
          for (let i = newTable.seatNumberY - 1; i >= 0; i--) {
            const xLeft = -CHAIR_OFFSET
            const yLeft = (CHAIR_SPACE + CHAIR_DIAMETER) * i + CHAIR_DIAMETER / 2 + CHAIR_SPACE
            seats.push({
              x: xLeft,
              y: yLeft,
              rotationDegrees: 270,
              width: 0,
              height: 0,
              id: "",
            })
          }
          updateShape = {
            x: TABLE_DEFAULT_X,
            y: TABLE_DEFAULT_Y,
            width: (CHAIR_DIAMETER + CHAIR_SPACE) * newTable.seatNumberX + CHAIR_SPACE,
            height: (CHAIR_DIAMETER + CHAIR_SPACE) * newTable.seatNumberY + CHAIR_SPACE,
            rotationDegrees: 0,
            childs: seats,
          }
        } else {
          for (let i = 0; i < newTable.seatNumberX; i++) {
            const seatDegree = (360 / newTable.seatNumberX) * i
            const xTranslate =
              (newTable.seatNumberX * TABLE_RADIUS_FOR_SEAT_CIRCLE + CHAIR_OFFSET) *
              Math.cos((Math.PI / 180) * seatDegree)
            const yTranslate =
              (newTable.seatNumberX * TABLE_RADIUS_FOR_SEAT_CIRCLE + CHAIR_OFFSET) *
              Math.sin((Math.PI / 180) * seatDegree)
            seats.push({
              x: xTranslate,
              y: yTranslate,
              rotationDegrees: seatDegree + CHAIR_OFFSET_DEGREE,
              width: 0,
              height: 0,
              id: "",
            })
          }
          updateShape = {
            x: TABLE_DEFAULT_X,
            y: TABLE_DEFAULT_Y,
            width: TABLE_RADIUS_FOR_SEAT_CIRCLE * newTable.seatNumberX,
            height: TABLE_RADIUS_FOR_SEAT_CIRCLE * newTable.seatNumberY,
            rotationDegrees: 0,
            childs: seats,
          }
        }

        for (let i = 0; i < tableDetailsData.seats.length; i++) {
          seats[i].id = tableDetailsData.seats[i].shape.id
        }

        updateTablesCanvaShapesAPI(tenant, tableDetailsData.shape.id, updateShape)
          .then(() => {
            tableDetailsData.shape.x = updateShape.x
            tableDetailsData.shape.y = updateShape.y
            tableDetailsData.shape.width = updateShape.width
            tableDetailsData.shape.height = updateShape.height
            tableDetailsData.seats.forEach((seat, index) => {
              seat.shape.x = seats[index].x
              seat.shape.y = seats[index].y
              seat.shape.rotationDegrees = seats[index].rotationDegrees
            })
            dispatch(addNewTable({ tables: tableDetailsData }))
            toastSuccess("Nuovo tavolo inserito.")
          })
          .catch(() => toastWarning("Qualcosa è andato storto. Riprova!"))

        showModal(false)
      })
      .catch(() => toastWarning("Qualcosa è andato storto. Riprova!"))
  }

  return (
    <Modal show={show} onHide={() => showModal(false)} centered>
      <Modal.Header>
        <Modal.Title style={{ color: "425466" }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label className="form-label">Nome tavolo</label>
          <input
            className="form-input"
            type="text"
            placeholder="Tavolo sposi"
            onChange={(e) => setNewTable({ ...newTable, table: { ...newTable.table, name: e.currentTarget.value } })}
          ></input>
        </div>
        <div>
          <label className="form-label">Descrizione tavolo</label>
          <input
            className="form-input"
            type="text"
            placeholder="Tavolo degli sposi"
            onChange={(e) =>
              setNewTable({ ...newTable, table: { ...newTable.table, description: e.currentTarget.value } })
            }
          ></input>
        </div>
        {modalToShow === TableType.TABLE_RECT ? (
          <>
            <div>
              <label className="form-label">Numero di posti lato lungo</label>
              <input
                className="form-input"
                type="number"
                placeholder="1"
                onChange={(e) =>
                  setNewTable({
                    ...newTable,
                    seatNumberX: parseInt(e.currentTarget.value.length > 0 ? e.currentTarget.value : "1", 10),
                  })
                }
              ></input>
            </div>
            <div>
              <label className="form-label">Numero di posti lato corto</label>
              <input
                className="form-input"
                type="number"
                placeholder="1"
                onChange={(e) =>
                  setNewTable({
                    ...newTable,
                    seatNumberY: parseInt(e.currentTarget.value.length > 0 ? e.currentTarget.value : "1", 10),
                  })
                }
              ></input>
            </div>
          </>
        ) : (
          <div>
            <label className="form-label">Numero di posti</label>
            <input
              className="form-input"
              type="number"
              placeholder="1"
              onChange={(e) =>
                setNewTable({
                  ...newTable,
                  seatNumberX: parseInt(e.currentTarget.value.length > 0 ? e.currentTarget.value : "1", 10),
                  seatNumberY: parseInt(e.currentTarget.value.length > 0 ? e.currentTarget.value : "1", 10),
                })
              }
            ></input>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary button-style" onClick={handleClick}>
          Aggiungi
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          style={{
            border: "2px solid #e9e7e7",
          }}
          onClick={() => showModal(false)}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}
