import { useEffect, useState } from "react"
import { addContributorAPI, getContributorsRolesAPI } from "../../api/contributorsApi"
import Select from "react-select"
import { toastSuccess, toastWarning } from "../../utils/toastMethod"
import ContributorsTable from "./ContributorsTable"
import { UpdateContributors } from "../../models/contributorsApi"

interface ContributorsInfoProps {
  tenant: string | undefined
}

interface SelectOptions {
  value: string
  label: string
}

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    border: "1px solid #ebf2fa",
    boxShadow: "none",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 16,
    color: state.selectProps.menuColor,
    padding: 10,
    fontSize: 14,
  }),
  option: (provided: any) => ({
    ...provided,
    borderRadius: 16,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: 40,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: 14,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: 14,
    color: "#b5b5bd",
  }),
}

export default function ContributorsInfo({ tenant }: ContributorsInfoProps) {
  const [contributors, setCotributors] = useState<SelectOptions[]>([])
  const [newContributor, setNewContributor] = useState<UpdateContributors>({
    roleName: "",
    userEmail: "",
  })

  useEffect(() => {
    tenant &&
      getContributorsRolesAPI(tenant)
        .then((constributors) => {
          const options: SelectOptions[] = []
          constributors.forEach((constributor: string) => {
            options.push({
              value: constributor,
              label: constributor,
            })
          })
          setCotributors([...options])
        })
        .catch(() => console.error("ERROR"))
  }, [tenant])

  const handleClick = () => {
    addContributorAPI(tenant, newContributor)
      .then(() => {
        setNewContributor({ ...newContributor, userEmail: "" })
        toastSuccess("Nuovo collaboratore inserito.")
      })
      .catch(() => {
        toastWarning("Qualcosa è andato storto. Riprova!")
      })
  }

  return (
    <>
      {contributors && (
        <>
          <div className="form-container" style={{ marginBottom: "40px" }}>
            <label className="form-label">Inserisci e-mail del collaboratore</label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div style={{ flex: "7" }}>
                <input
                  className="form-input"
                  type="text"
                  placeholder="email@gmail.com"
                  value={newContributor.userEmail}
                  onChange={(e) => setNewContributor({ ...newContributor, userEmail: e.currentTarget.value })}
                ></input>
              </div>
              <div style={{ display: "flex", flex: "3", alignItems: "flex-end", marginLeft: "10px" }}>
                <Select
                  styles={customStyles}
                  options={contributors}
                  defaultValue={contributors[0]}
                  isClearable
                  onChange={(e) =>
                    setNewContributor({ ...newContributor, roleName: e !== null ? e.value : contributors[0].label })
                  }
                />
              </div>
            </div>
            {newContributor.userEmail.length > 0 && !/\S+@\S+\.\S+/.test(newContributor.userEmail) ? (
              <div className="provisioning-form-input-error">* Email non valida</div>
            ) : null}
            <div style={{ display: "flex", flex: "2", alignItems: "flex-end", marginTop: "15px" }}>
              <button
                type="button"
                className="btn btn-primary button-style"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                onClick={handleClick}
              >
                Aggiungi collaboratore
              </button>
            </div>
          </div>
          <ContributorsTable tenant={tenant} />
        </>
      )}
    </>
  )
}
