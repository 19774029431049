import { useState } from "react"
import TableSharePublicLinkModal from "./TableSharePublicLinkModal"
import shareIcon from "../../../icon/export_share_icon.svg"

interface TableSharePublicLinkCtaProps {
  tenant: string | undefined
}

export default function TableSharePublicLinkCta({ tenant }: TableSharePublicLinkCtaProps) {
  const [show, setShow] = useState(false)

  return (
    <>
      <button
        type="button"
        className="btn btn-primary button-style"
        style={{ marginRight: "10px" }}
        onClick={() => setShow(true)}
      >
        <img alt="downloadIcon" src={shareIcon} style={{ marginRight: "10px" }} />
        Condividi tavoli
      </button>
      <TableSharePublicLinkModal tenant={tenant} show={show} setShow={setShow} />
    </>
  )
}
