import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { getTablesLayoutsDetailsPublicAPI } from "../api/tableApi"
import HeaderBar from "../components/HeaderBar"
import TablesGrid from "../components/tables/components/TablesGrid"
import { setTablesState } from "../components/tables/tableSlice"
import useCheckMobileResolution from "../hook/useCheckMobileResolution"
import { useAppDispatch, useAppSelector } from "../hook/useReduxHooks"
import { TablesLayoutShareDetails } from "../models/tableApiTypes"
import { RootState } from "../store"
import { toastWarning } from "../utils/toastMethod"

export default function TablesViewerPublic() {
  const { tenant } = useParams<string>()
  const [tablesLayoutsDetailsPublic, setTablesLayoutsDetailsPublic] = useState<TablesLayoutShareDetails>()
  const isMobile = useCheckMobileResolution()
  const tablesDetails = useAppSelector((state: RootState) => state.table)
  const dispatch = useAppDispatch()
  const search = useLocation().search
  const identifier = new URLSearchParams(search).get("identifier") ?? ""
  const layoutId = new URLSearchParams(search).get("layoutId") ?? ""
  const code = new URLSearchParams(search).get("code") ?? ""

  useEffect(() => {
    if (identifier.length > 0 && layoutId.length > 0 && code.length > 0) {
      getTablesLayoutsDetailsPublicAPI(identifier, layoutId, code)
        .then((result: TablesLayoutShareDetails) => {
          setTablesLayoutsDetailsPublic(result)
          dispatch(setTablesState({ tables: result.tables }))
        })
        .catch(() => toastWarning("Errore nel caricamento dei tavoli. Riprova!"))
    } else toastWarning("Verifica che il link sia corretto")
  }, [tenant, identifier, layoutId, code, dispatch])

  return (
    <>
      <div className="wrapper" style={{ overflow: "hidden", height: "100%" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {tablesLayoutsDetailsPublic && (
            <>
              <HeaderBar title={<>{tablesLayoutsDetailsPublic.title}</>} />
              <div className="table_viewer">
                <TablesGrid isMobile={isMobile} tables={tablesDetails.tables} isPublicViewer={true} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
