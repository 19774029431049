import { useParams } from "react-router-dom"
import { useState } from "react"
import { GuestRsvp } from "../models/guestRsvpApiTypes"
import Sidebar from "../components/Sidebar"
import MenuIcon from "../components/MenuIcon"
import HeaderBar from "../components/HeaderBar"
import RsvpInfo from "../components/rsvp/RsvpInfo"
import RsvpDwonloadListCta from "../components/rsvp/RsvpDwonloadListCta"
import RsvpSendEmailCta from "../components/rsvp/RsvpSendEmailCta"

interface RsvpProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function Rsvp({ sidebar = true, showSidebar }: RsvpProps) {
  const [selectedRows, setSelectedRows] = useState<GuestRsvp[]>([])
  const { tenant } = useParams<string>()

  return (
    <>
      <div className="wrapper">
        <Sidebar sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
        <div id="content">
          <HeaderBar
            title={
              <>
                <MenuIcon sidebar={sidebar} showSidebar={showSidebar} />
                RSVP
              </>
            }
          >
            <RsvpDwonloadListCta tenant={tenant} guests={selectedRows} />
            <RsvpSendEmailCta tenant={tenant} guests={selectedRows} />
          </HeaderBar>
          <div className="container">
            <RsvpInfo tenant={tenant} setSelectedRows={setSelectedRows} />
          </div>
        </div>
      </div>
    </>
  )
}
