import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getTablesLayoutsAPI, getTablesLayoutsDetailsAPI } from "../api/tableApi"
import { useAppDispatch, useAppSelector } from "../hook/useReduxHooks"
import { TableDetailsData, TablesLayoutDetails, TablesLayoutInfo } from "../models/tableApiTypes"
import { RootState } from "../store"
import { toastWarning } from "../utils/toastMethod"
import {
  setTableLayoutState,
  setTablesState,
  setToolPaletView,
  TOOL_PALET_VIEW_TYPE,
} from "../components/tables/tableSlice"
import useCheckMobileResolution from "../hook/useCheckMobileResolution"
import HeaderBar from "../components/HeaderBar"
import MenuIcon from "../components/MenuIcon"
import Sidebar from "../components/Sidebar"
import TableCta from "../components/tables/components/TableCta"
import TableDownloadListCta from "../components/tables/components/TableDownloadListCta"
import TablesGrid from "../components/tables/components/TablesGrid"
import TableSharePublicLinkCta from "../components/tables/components/TableSharePublicLinkCta"
import ToolPalet from "../components/tables/components/ToolPalet"
import TableSvgReportCta from "../components/tables/components/TableSvgReportCta"
import TableMobileView from "../components/tables/components/TableMobileView"

interface TablesViewerProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function TablesViewer({ sidebar = true, showSidebar }: TablesViewerProps) {
  const { tenant } = useParams<string>()
  const [tablesDetailsData, setTableDetailsData] = useState<TableDetailsData[]>()
  const tablesDetails = useAppSelector((state: RootState) => state.table)
  const dispatch = useAppDispatch()
  const isMobile = useCheckMobileResolution()

  useEffect(() => {
    tenant &&
      dispatch(
        setToolPaletView({
          newToolPaletView: { toolPaletViewType: TOOL_PALET_VIEW_TYPE.NONE },
        })
      ) &&
      getTablesLayoutsAPI(tenant)
        .then((tablesLayout: TablesLayoutInfo[]) => {
          const approvedLayout = tablesLayout.find((table) => table.approved === true)
          if (approvedLayout !== undefined) {
            dispatch(
              setTableLayoutState({
                tablesLayout: {
                  id: approvedLayout.id,
                  name: approvedLayout.name,
                  description: approvedLayout.description,
                  approved: approvedLayout.approved,
                  sharingEnabled: approvedLayout.sharingEnabled,
                },
              })
            )
            getTablesLayoutsDetailsAPI(tenant, approvedLayout?.id)
              .then((result: TablesLayoutDetails) => {
                setTableDetailsData(result.tables)
                dispatch(setTablesState({ tables: result.tables }))
              })
              .catch(() => toastWarning("Errore nel caricamento dei tavoli. Riprova!"))
          }
        })
        .catch(() => toastWarning("Errore nel caricamento dei tavoli. Riprova!"))
  }, [tenant, dispatch])

  return (
    <>
      <div className="wrapper" style={{ overflow: "hidden", height: "100%" }}>
        <Sidebar sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
        <div id="content" style={{ display: "flex", flexDirection: "column" }}>
          <HeaderBar
            title={
              <>
                <MenuIcon sidebar={sidebar} showSidebar={showSidebar} />
                Tavoli
              </>
            }
          >
            {!isMobile ? (
              <>
                <TableSharePublicLinkCta tenant={tenant} />
                <TableSvgReportCta tenant={tenant} />
                <TableDownloadListCta tenant={tenant} />
                <TableCta tenant={tenant} />
              </>
            ) : (
              <TableDownloadListCta tenant={tenant} />
            )}
          </HeaderBar>
          <>
            {!isMobile ? (
              <div className="table_viewer">
                <ToolPalet />
                {tablesDetailsData && (
                  <TablesGrid isMobile={isMobile} tables={tablesDetails.tables} isPublicViewer={false} />
                )}
              </div>
            ) : (
              <div className="table_viewer">
                <TableMobileView tables={tablesDetails.tables} />
              </div>
            )}
          </>
        </div>
      </div>
    </>
  )
}
