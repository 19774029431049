import { ReactNode } from "react"
import "../../style/custom_style.css"

interface TileHeaderProps {
  avatar?: ReactNode
  title?: string
  color?: string
  fontWeight?: string
}

export default function TileHeader({ avatar, title, color, fontWeight }: TileHeaderProps) {
  return (
    <div className="row">
      {avatar ? <div className="tile-header-image">{avatar}</div> : null}
      {title ? (
        <div className="tile-header-title" style={{ color, fontWeight }}>
          {title}
        </div>
      ) : null}
    </div>
  )
}
