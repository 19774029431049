import { UpdateContributors } from "../models/contributorsApi"
import axiosDashboard from "./clientApi"

export async function getContributorsAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/contributors`).then((response) => response.data)
}

export async function getContributorsRolesAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/contributors/roles`).then((response) => response.data)
}

export async function addContributorAPI(tenant: string | undefined, payload: UpdateContributors) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .post(`/dashboard/${tenant}/contributors`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}
