import { useEffect, useState } from "react"
import {
  addGuestGroupAPI,
  deleteGroupTypeAPI,
  getGuestGroupsDetailsAPI,
  updateGroupTypeAPI,
} from "../../api/guestGroupApi"
import { AddGuestGroupData, GuestGroupDetailsData, UpdateGuestGroupData } from "../../models/guestGroupApiTypes"
import { CustomCard } from "./../CustomCard"
import modifyIcon from "../../icon/modify_icon.svg"
import deleteIcon from "../../icon/delete-icon.svg"
import addInvitationIcon from "../../icon/add_icon.svg"
import saveIcon from "../../icon/save_icon.svg"
import { toastSuccess, toastWarning } from "../../utils/toastMethod"

enum EDIT_GROUP_FIELD {
  NAME = "name",
  DESCRIPTION = "description",
}

interface GuestGroupInfoProps {
  tenant: string | undefined
}

interface GuestGroupDetails {
  data: GuestGroupDetailsData
  isEditable: boolean
}

export default function GuestGroupInfo({ tenant }: GuestGroupInfoProps) {
  const [guestGroupsDetails, setGuestGroupsDetails] = useState<GuestGroupDetails[]>([])

  useEffect(() => {
    getGuestGroupsDetailsAPI(tenant)
      .then((result) => {
        setGuestGroupsDetails([])
        result.forEach(function (r: GuestGroupDetailsData) {
          const newGroupDetails: GuestGroupDetails = {
            data: {
              name: r.name,
              description: r.description,
              id: r.id,
            },
            isEditable: false,
          }
          setGuestGroupsDetails((groupsDetails) => [...groupsDetails, newGroupDetails])
        })
      })
      .catch(() => console.error("ERROR"))
  }, [tenant])

  const addGuestGroup = () => {
    const addGroup: AddGuestGroupData = {
      name: "Nuovo Gruppo",
      description: "Inserisci una descrizione",
    }
    addGuestGroupAPI(tenant, addGroup)
      .then((newGroupId) => {
        const newGroup: GuestGroupDetails = {
          data: {
            name: addGroup.name,
            description: addGroup.description,
            id: newGroupId,
          },
          isEditable: false,
        }
        setGuestGroupsDetails((currentGuestgroup) => [...currentGuestgroup, newGroup])
        toastSuccess("Nuovo gruppo inserito.")
      })
      .catch(() => toastWarning("Problemi con l'inserimento del gruppo. Riprova!"))
  }

  const updateGuestGroup = (index: number, group: GuestGroupDetails) => {
    const actualGuestGroupDetails = [...guestGroupsDetails]
    const guestType: UpdateGuestGroupData = {
      name: group.data.name,
      description: group.data.description,
    }
    updateGroupTypeAPI(tenant, group.data.id, guestType)
      .then(() => {
        actualGuestGroupDetails[index].isEditable = false
        setGuestGroupsDetails(actualGuestGroupDetails)
        toastSuccess("Gruppo aggiornato.")
      })
      .catch(() => toastWarning("Problemi con l'aggiornamento del gruppo. Riprova!"))
  }

  const deleteGuestGroup = (id: string) => {
    deleteGroupTypeAPI(tenant, id)
      .then(() => {
        const newGuestGroups = guestGroupsDetails.filter((group) => group.data.id !== id)
        setGuestGroupsDetails(newGuestGroups)
        toastSuccess("Gruppo cancellato.")
      })
      .catch(() => toastWarning("Problemi con la cancellazione del gruppo. Riprova!"))
  }

  const enableEditGuestGroup = (index: number) => {
    const actualGuestTGroupDetails = [...guestGroupsDetails]
    actualGuestTGroupDetails[index].isEditable = true
    setGuestGroupsDetails(actualGuestTGroupDetails)
  }

  const updateGroupName = (index: number, value: string, field: EDIT_GROUP_FIELD) => {
    const actualGuestGroupDetails = [...guestGroupsDetails]
    switch (field) {
      case EDIT_GROUP_FIELD.NAME:
        actualGuestGroupDetails[index].data.name = value
        break
      case EDIT_GROUP_FIELD.DESCRIPTION:
        actualGuestGroupDetails[index].data.description = value
        break
      default:
        break
    }
    setGuestGroupsDetails(actualGuestGroupDetails)
  }

  return (
    <>
      {guestGroupsDetails && (
        <>
          <div className="row">
            {guestGroupsDetails.map((group, index) => (
              <div className="col-sm-12, col-md-6 col-lg-4" style={{ marginBottom: "20px" }} key={index}>
                {group.isEditable ? (
                  <CustomCard
                    title={
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <div>
                          <img
                            alt="saveIcon"
                            src={saveIcon}
                            style={{ width: "18px", cursor: "pointer" }}
                            onClick={() => updateGuestGroup(index, group)}
                          />
                        </div>
                      </div>
                    }
                  >
                    <div style={{ marginTop: "5px" }}>
                      <div>
                        <label className="form-label">Inserisci un nome</label>
                        <input
                          className="form-input"
                          type="text"
                          placeholder="Amici Luigi"
                          value={group.data.name}
                          onChange={(e) => updateGroupName(index, e.target.value, EDIT_GROUP_FIELD.NAME)}
                        ></input>
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        <label className="form-label">Inserisci una descrizione</label>
                        <input
                          className="form-input"
                          type="text"
                          placeholder="Gruppo di amici di Luigi"
                          value={group.data.description}
                          onChange={(e) => updateGroupName(index, e.target.value, EDIT_GROUP_FIELD.DESCRIPTION)}
                        ></input>
                      </div>
                    </div>
                  </CustomCard>
                ) : (
                  <CustomCard
                    title={
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: "9" }}>{group.data.name}</div>
                        <div style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
                          <img
                            alt="modifyIcon"
                            src={modifyIcon}
                            style={{ width: "18px", cursor: "pointer", marginRight: "5px" }}
                            onClick={() => enableEditGuestGroup(index)}
                          />
                          <img
                            alt="deleteIcon"
                            src={deleteIcon}
                            style={{ width: "18px", cursor: "pointer" }}
                            onClick={() => deleteGuestGroup(group.data.id)}
                          />
                        </div>
                      </div>
                    }
                  >
                    <div style={{ marginTop: "5px" }}>{group.data.description}</div>
                  </CustomCard>
                )}
              </div>
            ))}
          </div>
          <button
            type="button"
            className="btn btn-primary button-style"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
            }}
            onClick={addGuestGroup}
          >
            <img alt="addInvitationIcon" src={addInvitationIcon} style={{ marginRight: "10px" }} />
            Aggiungi Gruppo
          </button>
          <div className="row"></div>
        </>
      )}
    </>
  )
}
