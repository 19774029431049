import { GuestGroupDetailsData } from "./guestGroupApiTypes"
import { GuestTypeDetailsData } from "./guestTypeApiTypes"

export enum GuestStatus {
  INVITED = "In attesa",
  ACCEPTED = "Confermato",
  DECLINED = "Rifiutato",
  PRESENT = "Present",
  DRAFT = "Bozza",
  NONE = "None",
}

export enum GuestBusStatus {
  ACCEPTED = "Confermato",
  DECLINED = "Rifiutato",
  NULL = "In attesa",
}

type RsvpSettings = {
  showIntolerances: boolean
  showEmail: boolean
  showBusTransferNeeded: boolean
  showArrivalDate: boolean
}

type BaseGuestData = {
  name: string
  surname: string
  rsvpSettings: RsvpSettings
}

export type GuestDetailsData = BaseGuestData & {
  id: string
  status: string
  type: GuestTypeDetailsData
  group: GuestGroupDetailsData
}

export type AddGuestData = BaseGuestData & {
  typeId: string
  groupId: string | undefined
  isDraft: boolean
}

export type UpdateGuestData = AddGuestData

type BaseInvitationData = {
  id: string
  reservationCode: string
  name: string
  createdOn: Date
  status: string
}

export type AddInvitationData = {
  name: string
  guests: AddGuestData[]
}

export type InvitationInfoData = BaseInvitationData & {
  guestsInvited: number
  guestsAccepted: number
  guestsDeclined: number
  qrCodeScansCounter: number
}

export type InvitationDetailsData = BaseInvitationData & {
  guests: GuestDetailsData[]
}

export type PatchInvitationName = {
  name: string
}

export type PatchInvitationStatus = {
  status: string
}

export type PatchTable = {
  tableId: string
}

type FontQrCode = {
  fontColor: string
  fontFamily: string
  fontSize: number
  letterSpacing: number
}

type PageQrCode = {
  isLandscape: boolean
  margin: number
  widthMm: number
  heightMm: number
}

type DarkColorQrCode = {
  alpha: number
  red: number
  green: number
  blue: number
}

type LightColorQrCode = {
  alpha: number
  red: number
  green: number
  blue: number
}

type CardQrCode = {
  widthMm: number
  heightMm: number
  paddingTopMm: number
  paddingBottomMm: number
  paddingRightMm: number
  paddingLeftMm: number
  background: string
  description: string
  layoutSingleColumn: boolean
  showQrCodeLogo: boolean
  qrCodeDarkColor?: DarkColorQrCode
  qrCodeLightColor?: LightColorQrCode
}

export type QrCodeSettings = {
  numberOfCardsPreview?: number
  font: FontQrCode
  page: PageQrCode
  card: CardQrCode
}
