import { WeddingInfo } from "../../../models/weddingApiTypes"
import counteInfoIcon from "../../../icon/counter_info_icon.svg"

interface WeddingCounterInfoScanQrCodeTileProps {
  wedding: WeddingInfo | undefined
}

export default function WeddingCounterInfoScanQrCodeTile({ wedding }: WeddingCounterInfoScanQrCodeTileProps) {
  return (
    <div style={{ height: "100%", padding: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}>
      <img alt="counteInfoIcon" src={counteInfoIcon} style={{ marginRight: "24px" }} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ color: "#718096", fontWeight: "700", fontSize: "12px" }}>Totale Scansioni QR Code</div>
        <div style={{ color: "#16192C", fontWeight: "600", fontSize: "20px" }}>
          {wedding?.counters.qrCodeScanCounters}
        </div>
      </div>
    </div>
  )
}
