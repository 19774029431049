import { useState } from "react"
import { Modal } from "react-bootstrap"
import { downloadTablesLayoutFile } from "../../../api/tableApi"
import { useAppSelector } from "../../../hook/useReduxHooks"
import { RootState } from "../../../store"

interface TableDownloadListModalProps {
  tenant: string | undefined
  show: boolean
  setShow: (show: boolean) => void
}

export default function TableDownloadListModal({ tenant, show, setShow }: TableDownloadListModalProps) {
  const tablesLayout = useAppSelector((state: RootState) => state.table.layout)
  const [checkbox, setCheckbox] = useState(false)

  const downloadTableListFile = async () => {
    downloadTablesLayoutFile(tenant, tablesLayout.id, checkbox, "txt").then((response) => {
      const url = window.URL.createObjectURL(response)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      a.download = "tables.txt"
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} centered={true}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Modal.Title>Lista tavoli</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "30px" }}>
        <>
          Scarica la lista dei tavoli in un comodo file così da poterla condividere con chi ne ha bisogno.
          <br />
          Avrai a disposizione un file con la lista dei tavoli con tanto di invitati seduti ad ogni tavolo.
          <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
            <div style={{ flex: "9" }}>
              <label className="toggle-form-label">Mostra cognome</label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={checkbox}
                onChange={(e) => setCheckbox(e.target.checked)}
              ></input>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary button-style"
            style={{ marginTop: "20px" }}
            onClick={downloadTableListFile}
          >
            Scarica lista
          </button>
        </>
      </Modal.Body>
    </Modal>
  )
}
