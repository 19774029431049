import { useEffect, useState } from "react"
import { VictoryPie } from "victory"
import { WeddingInfo } from "../../../models/weddingApiTypes"

interface WeddingBudgetProps {
  wedding: WeddingInfo | undefined
}

const defaultGraphData = [
  { x: " ", y: 100 },
  { x: " ", y: 0 },
]

export default function WeddingBudget({ wedding }: WeddingBudgetProps) {
  const [graphData, setGraphicData] = useState(defaultGraphData)

  useEffect(() => {
    wedding &&
      setGraphicData([
        { x: " ", y: wedding.counters.budget.actual },
        { x: " ", y: wedding.counters.budget.budgeted - wedding.counters.budget.actual },
      ])
  }, [wedding])

  return (
    <>
      {wedding && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <VictoryPie
            animate={{ easing: "exp" }}
            startAngle={-90}
            endAngle={90}
            cornerRadius={10}
            height={300}
            padAngle={2}
            innerRadius={145}
            origin={{ x: 200, y: 220 }}
            colorScale={["#7f6bff", "#e4eaff"]}
            data={graphData}
          />
          <div style={{ marginTop: "7px", textAlign: "center" }}>
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              <span>&#8364;</span> {wedding.counters.budget.budgeted - wedding.counters.budget.actual} rimasti
            </div>
            <hr
              style={{
                border: "1px solid #e4eaff",
                color: "#e4eaff",
                backgroundColor: "#e4eaff",
                marginTop: "18px",
                marginBottom: "0px",
                marginLeft: "auto",
                marginRight: "auto",
                width: "50%",
              }}
            />
            <div style={{ fontSize: "14px", fontWeight: "normal", color: "#718096" }}>
              di <span>&#8364;</span> {wedding.counters.budget.budgeted} <span>&#40;</span>costo stimato{" "}
              <span>&#8364;</span> {wedding.counters.budget.estimated}
              <span>&#41;</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
