import InvitationForm from "../components/invitation/InvitationForm"
import Sidebar from "../components/Sidebar"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { RsvpSettings } from "../models/weddingApiTypes"
import { getWeddingsRsvpSettingAPI } from "../api/weddingApi"

interface AddInvitationProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function AddInvitation({ sidebar = true, showSidebar }: AddInvitationProps) {
  const { tenant } = useParams<string>()
  const [rsvpSettings, setRsvpSettings] = useState<RsvpSettings | undefined>()

  useEffect(() => {
    tenant && getWeddingsRsvpSettingAPI(tenant).then((result) => setRsvpSettings(result))
  }, [tenant])

  return (
    <>
      <div className="wrapper">
        <Sidebar sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
        {rsvpSettings && (
          <div id="content">
            <InvitationForm
              tenant={tenant}
              invitation={{
                name: "",
                id: undefined,
                guests: [
                  {
                    name: "",
                    surname: "",
                    id: undefined,
                    isModified: false,
                    typeId: "",
                    groupId: undefined,
                    rsvpSettings: {
                      showBusTransferNeeded: rsvpSettings.weddingRsvpSettings.showBusTransferNeeded,
                      showArrivalDate: rsvpSettings.weddingRsvpSettings.showArrivalDate,
                      showEmail: rsvpSettings.weddingRsvpSettings.showEmail,
                      showIntolerances: rsvpSettings.weddingRsvpSettings.showIntolerances,
                    },
                    isDraft: false,
                  },
                ],
              }}
              isUpdate={false}
            />
          </div>
        )}
      </div>
    </>
  )
}
