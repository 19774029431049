import { Field, Form, Formik } from "formik"
import { useNavigate } from "react-router-dom"
import { addWeddingAPI } from "../../api/weddingApi"
import { AddWedding, TenantRsvpSettings } from "../../models/weddingApiTypes"
import { toastSuccess, toastWarning } from "../../utils/toastMethod"

interface ProvisioningThirdStepProps {
  tenant: string | undefined
  setNextStep: (newStep: number) => void
  setNewWeddingInfo: (newWeddingInfo: AddWedding) => void
  actualWeddingInfo: AddWedding
  rsvpTenantSettings: TenantRsvpSettings
}

export default function ProvisioningThirdStep({
  tenant,
  setNextStep,
  setNewWeddingInfo,
  actualWeddingInfo,
  rsvpTenantSettings,
}: ProvisioningThirdStepProps) {
  const navigate = useNavigate()
  const isRsvpTenantSettingsEnables =
    rsvpTenantSettings.intolerancesEnabled ||
    rsvpTenantSettings.busEnabled ||
    rsvpTenantSettings.arrivalDateEnabled ||
    rsvpTenantSettings.emailEnabled

  return (
    <>
      <Formik
        initialValues={{
          nameFirstOwner: actualWeddingInfo.nameFirstOwner,
          surnameFirstOwner: actualWeddingInfo.surnameFirstOwner,
          nameSecondOwner: actualWeddingInfo.nameSecondOwner,
          surnameSecondOwner: actualWeddingInfo.surnameSecondOwner,
          weddingDate: actualWeddingInfo.weddingDate,
          location: actualWeddingInfo.location,
          rsvpSettings: {
            isRsvpEnabled: actualWeddingInfo.rsvpSettings.isRsvpEnabled,
            showArrivalDate: actualWeddingInfo.rsvpSettings.showArrivalDate,
            showBusTransferNeeded: actualWeddingInfo.rsvpSettings.showBusTransferNeeded,
            showEmail: actualWeddingInfo.rsvpSettings.showEmail,
            showIntolerances: actualWeddingInfo.rsvpSettings.showIntolerances,
          },
        }}
        onSubmit={async (values: AddWedding) => {
          if (isRsvpTenantSettingsEnables) {
            setNewWeddingInfo(values)
            setNextStep(3)
          } else {
            setNewWeddingInfo(values)
            addWeddingAPI(tenant, values)
              .then(() => {
                toastSuccess("Benvenuto nella tua dashboard.")
                navigate(`/${tenant}/dashboard`)
              })
              .catch(() => {
                toastWarning("Qualcosa è andato storto. Riprova!")
              })
          }
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div>
              <label className="provisioning-form-label" htmlFor="location">
                Location Ricevimento
              </label>
              <Field
                className="provisioning-form-input"
                id="location"
                name="location"
                placeholder="Inserisci il nome della vostra location"
                type="text"
              />
              {errors.location && touched.location ? (
                <div className="provisioning-form-input-error">{errors.location}</div>
              ) : null}
            </div>

            <div className="provisoning-button-box">
              <button
                type="button"
                className="btn btn-primary provisoning-button"
                style={{ flex: "5", margin: "5px", backgroundColor: "#a6b7d4", borderColor: "#a6b7d4" }}
                onClick={() => setNextStep(1)}
              >
                Indietro
              </button>
              {isRsvpTenantSettingsEnables ? (
                <button
                  type="submit"
                  className="btn btn-primary provisoning-button"
                  style={{ flex: "5", margin: "5px" }}
                >
                  Continua
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary provisoning-button"
                  style={{ flex: "5", margin: "5px" }}
                >
                  Fine
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
