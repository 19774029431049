import { ReactNode } from "react"
import "../../style/custom_style.css"

interface BigTileProps {
  children?: ReactNode
  header?: ReactNode
  backgroundColor?: string
}

export default function BigTile({ header, children, backgroundColor }: BigTileProps) {
  return (
    <div className="big-tile" style={{ backgroundColor }}>
      {header ? <div className="tile-header ">{header}</div> : null}
      {children ? (
        <div className="big-tile-body" style={{ backgroundColor }}>
          {children}
        </div>
      ) : null}
    </div>
  )
}
