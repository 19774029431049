import { useState, PointerEvent } from "react"
import { useAppDispatch, useAppSelector } from "../../../hook/useReduxHooks"
import { ShapeBaseDataWithId, TableDetailsData, TableType, UpdateShapeData } from "../../../models/tableApiTypes"
import { updateTableCoordinate, updateTableShape } from "../tableSlice"
import { RootState } from "../../../store"
import TableCircle from "./TableCircle"
import TableRect from "./TableRect"

interface PointerDown {
  x: number
  y: number
}

interface DragElement {
  xOffset: number
  yOffset: number
  activeElement: boolean
}

interface TableProps {
  tableDetails: TableDetailsData
  setChildActive: (isActive: boolean) => void
  isPublicViewer: boolean
}

export default function Table({ tableDetails, setChildActive, isPublicViewer }: TableProps) {
  const dispatch = useAppDispatch()
  const [element, setElement] = useState<DragElement>({
    activeElement: false,
    xOffset: 0,
    yOffset: 0,
  })
  const [positionTablePointerDown, setPositionTablePointerDown] = useState<PointerDown>({
    x: tableDetails.shape.x,
    y: tableDetails.shape.y,
  })
  const elementSelected = useAppSelector((state: RootState) => state.table.toolPalet)
  const isTableSelected = elementSelected.toolPaletTableData?.id === tableDetails.id

  const handlePointerDown = (e: PointerEvent<SVGElement>) => {
    setChildActive(true)
    const el = e.currentTarget
    const bbox = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - bbox.left
    const y = e.clientY - bbox.top
    el.setPointerCapture(e.pointerId)
    setElement({ ...element, xOffset: x, yOffset: y, activeElement: true })
    setPositionTablePointerDown({
      x: tableDetails.shape.x,
      y: tableDetails.shape.y,
    })
  }

  const handlePointerUp = () => {
    setChildActive(false)
    setElement({ ...element, activeElement: false })
    if (tableDetails.shape.x !== positionTablePointerDown.x || tableDetails.shape.y !== positionTablePointerDown.y) {
      const newTableShape: UpdateShapeData = {
        x: tableDetails.shape.x,
        y: tableDetails.shape.y,
        rotationDegrees: tableDetails.shape.rotationDegrees,
        width: tableDetails.shape.width,
        height: tableDetails.shape.height,
        childs: [],
      }
      tableDetails.seats.forEach(function (seat) {
        const seatShape: ShapeBaseDataWithId = {
          x: seat.shape.x,
          y: seat.shape.y,
          width: seat.shape.width,
          height: seat.shape.height,
          rotationDegrees: seat.shape.rotationDegrees,
          id: seat.id,
        }
        newTableShape.childs.push(seatShape)
      })
      dispatch(
        updateTableShape({
          tableShapeId: tableDetails.shape.id,
          tableShape: newTableShape,
        })
      )
    }
  }

  const handlePointerMove = (e: PointerEvent<SVGElement>) => {
    if (element.activeElement) {
      const bbox = e.currentTarget.getBoundingClientRect()
      const x = e.clientX - bbox.left
      const y = e.clientY - bbox.top
      dispatch(
        updateTableCoordinate({
          tableShapeId: tableDetails.shape.id,
          newX: tableDetails.shape.x - (element.xOffset - x),
          newY: tableDetails.shape.y - (element.yOffset - y),
        })
      )
    }
  }

  if (tableDetails.shape.type === TableType.TABLE_RECT) {
    return (
      <TableRect
        posX={tableDetails.shape.x}
        posY={tableDetails.shape.y}
        tableDetails={tableDetails}
        isTableSelected={isTableSelected}
        handlePointerDown={!isPublicViewer ? (event) => handlePointerDown(event) : undefined}
        handlePointerUp={!isPublicViewer ? handlePointerUp : undefined}
        handlePointerMove={!isPublicViewer ? (event) => handlePointerMove(event) : undefined}
      />
    )
  } else if (tableDetails.shape.type === TableType.TABLE_CIRCLE) {
    return (
      <TableCircle
        posX={tableDetails.shape.x}
        posY={tableDetails.shape.y}
        tableDetails={tableDetails}
        isTableSelected={isTableSelected}
        handlePointerDown={!isPublicViewer ? (event) => handlePointerDown(event) : undefined}
        handlePointerUp={!isPublicViewer ? handlePointerUp : undefined}
        handlePointerMove={!isPublicViewer ? (event) => handlePointerMove(event) : undefined}
      />
    )
  } else return null
}
