import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { updateTableInfoAPI, updateTablesCanvaShapesAPI } from "../../../api/tableApi"
import { useAppDispatch, useAppSelector } from "../../../hook/useReduxHooks"
import { RootState } from "../../../store"
import {
  setTableHideEmptyChairState,
  setTableShowGuestSurnameState,
  setUpdateStateFlag,
  TOOL_PALET_VIEW_TYPE,
  UPDATE_TYPE,
} from "../tableSlice"
import ToolPaletSeat from "./ToolPaletSeat"
import ToolPaletTable from "./ToolPaletTable"

export default function ToolPalet() {
  const { tenant } = useParams<string>()
  const globalVar = useAppSelector((state: RootState) => state)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (globalVar.table.updateConditions.updateFlag) {
        switch (globalVar.table.updateConditions.updateType) {
          case UPDATE_TYPE.UPDATE_SHAPE:
            updateTablesCanvaShapesAPI(
              tenant,
              globalVar.table.updateShape.updateShapeid,
              globalVar.table.updateShape.updateShapeData
            )
            break
          case UPDATE_TYPE.UPDATE_TABLE_INFO:
            updateTableInfoAPI(
              tenant,
              globalVar.table.updateLogic.updateElementid,
              globalVar.table.updateLogic.updateTableInfoData
            )
            break
          default:
            break
        }
        dispatch(setUpdateStateFlag({ updateState: false }))
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [
    tenant,
    dispatch,
    globalVar.table.updateConditions.updateFlag,
    globalVar.table.updateConditions.updateType,
    globalVar.table.updateShape,
    globalVar.table.updateLogic,
  ])

  return (
    <div className="table_tools">
      {globalVar.table.toolPalet.toolPaletViewType === TOOL_PALET_VIEW_TYPE.TABLE ? (
        <>
          {globalVar.table.toolPalet.toolPaletTableData !== undefined ? (
            <ToolPaletTable tenant={tenant} tableDetails={globalVar.table.toolPalet.toolPaletTableData} />
          ) : null}
        </>
      ) : globalVar.table.toolPalet.toolPaletViewType === TOOL_PALET_VIEW_TYPE.SEAT ? (
        <>
          {globalVar.table.toolPalet.toolPaletSeatData !== undefined ? (
            <ToolPaletSeat tenant={tenant} seatDetails={globalVar.table.toolPalet.toolPaletSeatData} />
          ) : null}
        </>
      ) : (
        <div className="tool_palet_element">
          Clicca su un tavolo o una sedia e divertiti posizionando i tuoi invitati
          <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
            <div style={{ flex: "9" }}>
              <label className="toggle-form-label">Mostra cognome invitati</label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={globalVar.table.showGuestSurname}
                onChange={(e) => dispatch(setTableShowGuestSurnameState({ showGuestSurname: e.target.checked }))}
              ></input>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "5px" }}>
            <div style={{ flex: "9" }}>
              <label className="toggle-form-label">Nascondi sedie vuote</label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={globalVar.table.hideEmptyChair}
                onChange={(e) => dispatch(setTableHideEmptyChairState({ hideEmptyChair: e.target.checked }))}
              ></input>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
