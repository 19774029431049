import { useState } from "react"
import { GuestRsvp } from "../../models/guestRsvpApiTypes"
import RsvpDwonloadListModal from "./RsvpDwonloadListModal"
import downloadIcon from "../../icon/download_icon.svg"
import { toastWarning } from "../../utils/toastMethod"

interface RsvpDwonloadListCtaProps {
  tenant: string | undefined
  guests: GuestRsvp[]
}

export default function RsvpDwonloadListCta({ tenant, guests }: RsvpDwonloadListCtaProps) {
  const [show, setShow] = useState(false)

  const showModal = () => {
    if (guests.length === 0) toastWarning("Seleziona degli invitati prima di scaricare la lista!")
    else setShow(true)
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary button-style"
        style={{ marginRight: "10px" }}
        onClick={showModal}
      >
        <img alt="downloadIcon" src={downloadIcon} style={{ marginRight: "10px" }} />
        Lista invitati
      </button>
      <RsvpDwonloadListModal tenant={tenant} guests={guests} show={show} setShow={setShow} />
    </>
  )
}
