import { InvitationInfoData } from "../../models/invitationsApiTypes"
import { CustomTooltip } from "../CustomTooltip"
import { useNavigate } from "react-router-dom"
import { deleteRsvpAPI } from "../../api/invitationsApi"
import { toastSuccess, toastWarning } from "../../utils/toastMethod"
import rsvpIcon from "../../icon/rsvp_icon.svg"
import resetRsvpIcon from "../../icon/reset_rsvp_icon.svg"
import modifyIcon from "../../icon/modify_icon.svg"

interface InvitationsActions {
  tenant: string | undefined
  reservationCode: string
  actualPage: number
  invitation: InvitationInfoData
  invitations: InvitationInfoData[] | undefined
  setInvitations: (newValue: InvitationInfoData[] | undefined) => void
}

export default function InvitationActions({
  tenant,
  reservationCode,
  invitation,
  actualPage,
  invitations,
  setInvitations,
}: InvitationsActions) {
  const navigate = useNavigate()

  const resetRsvp = async (tenant: string | undefined, invitation: InvitationInfoData) => {
    if (tenant !== undefined) {
      const confirm = window.confirm(`Vuoi davvero resettare le risposte per l'invito ${invitation.name}?`)
      if (confirm) {
        const newInvitations = invitations?.map((i) => {
          if (i.id === invitation.id) {
            const guestsInvited = i.guestsInvited > 0 ? i.guestsInvited : i.guestsAccepted + i.guestsDeclined
            return { ...i, guestsAccepted: 0, guestsDeclined: 0, guestsInvited }
          }
          return i
        })
        setInvitations(newInvitations)

        deleteRsvpAPI(tenant, invitation.id)
          .then(() => {
            toastSuccess("Reset completato.")
          })
          .catch(() => {
            // In case of errors reset to the original invitations value
            setInvitations(invitations)
            toastWarning("Qualcosa è andato storto. Riprova!")
          })
      }
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <CustomTooltip text="RSVP" show={true}>
        <a
          style={{ paddingRight: "5px" }}
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_RSVP_URL}/confirm?wedding=${tenant}&code=${reservationCode}`}
        >
          <img alt="RsvpIcon" src={rsvpIcon} style={{ width: "30px" }} />
        </a>
      </CustomTooltip>
      <CustomTooltip text="Reset risposte" show={true}>
        <img
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Reset RSVP"
          alt="ResetRsvpIcon"
          src={resetRsvpIcon}
          style={{ cursor: "pointer", paddingRight: "5px" }}
          onClick={() => resetRsvp(tenant, invitation)}
        />
      </CustomTooltip>
      <CustomTooltip text="Modifica Invito" show={true}>
        <img
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Modifica invito"
          alt="ModifyLogo"
          src={modifyIcon}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/${tenant}/invitation/${invitation.id}?page=${actualPage}`)}
        />
      </CustomTooltip>
    </div>
  )
}
