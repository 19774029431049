import { WeddingInfo } from "../../../models/weddingApiTypes"
import ProgressBarChart from "./ProgressBarChart"

interface WeddingGuestGroupCountersTileProps {
  wedding: WeddingInfo
}

export default function WeddingGuestGroupCountersTile({ wedding }: WeddingGuestGroupCountersTileProps) {
  const groupsOrdered = wedding.counters.guests.groups.sort((a, b) => {
    if (a.counters.totals > b.counters.totals) {
      return -1
    }
    if (a.counters.totals < b.counters.totals) {
      return 1
    }
    return 0
  })

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        maxHeight: "350px",
      }}
    >
      <div style={{ color: "#000000", fontWeight: "600", fontSize: "12px" }}>Gruppo Invitati</div>
      <div>
        {groupsOrdered.map((group, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flex: "2",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "50px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <svg width="15" height="15">
                    <circle cx="7" cy="6" r="4" fill="#a4a7ab" />
                  </svg>
                  <div style={{ fontSize: "12px", fontWeight: "600", color: "#27272E" }}>{group.counters.totals}</div>
                </div>
                <div style={{ fontSize: "10px", fontWeight: "400", color: "#718096" }}>{group.name}</div>
              </div>

              <div style={{ flex: "5" }}>
                <ProgressBarChart
                  parentTile="guestGroup"
                  graphName={group.name}
                  attendingGuest={group.counters.attending}
                  invitedGuest={group.counters.invited}
                  declinedGuest={group.counters.declined}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
