import { ChangeEvent } from "react"
import { Modal } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { downloadInvitationsFile, uploadInvitationsFile } from "../../../api/fileApi"
import { toastWarning } from "../../../utils/toastMethod"

interface ImportFileProps {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ImportFile({ showModal, setShowModal }: ImportFileProps) {
  const { tenant } = useParams<string>()
  const navigate = useNavigate()

  const selectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0]
      const form = new FormData()
      form.append("file", file)
      uploadInvitationsFile(tenant, form).then(() => {
        setShowModal(false)
        navigate(`/${tenant}/invitations`)
      })
    }
  }

  const downloadFile = async () => {
    downloadInvitationsFile(tenant)
      .then((response) => {
        const url = window.URL.createObjectURL(response)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = "importa_inviti_template.xlsx"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(() => toastWarning("Qualcosa è andato storto. Riprova!"))
  }

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered={true}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Modal.Title>Importa invitati da Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <div style={{ fontSize: "16px", color: "#27272E", paddingBottom: "15px" }}>
            Vuoi importare gli invitati al tuo matrimonio velocemente? Non ti preoccupare, con Wamoo è molto facile.
          </div>
          <div style={{ display: "flex", flexDirection: "row", paddingBottom: "15px" }}>
            <div style={{ flex: "2" }}>
              <div className="import-box">
                <div className="import-box-number">1</div>
              </div>
            </div>
            <div style={{ flex: "10" }}>
              <a href="#downloadTemplate" onClick={downloadFile}>
                Scarica
              </a>
              &nbsp;il template Excel di Wamoo e aggiungi i tuoi inviti rispettando il formato.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", paddingBottom: "15px" }}>
            <div style={{ flex: "2" }}>
              <div className="import-box">
                <div className="import-box-number">2</div>
              </div>
            </div>
            <div style={{ flex: "10" }}>
              Il nome dell&apos;invitato è obbligatorio; il cognome e il gruppo sono opzionali.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: "2" }}>
              <div className="import-box">
                <div className="import-box-number">3</div>
              </div>
            </div>
            <div style={{ flex: "10" }}>Salva il file e caricalo utilizzando il pulsante Aggiungi File.</div>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <div style={{ flex: "4" }}>
              <label htmlFor="importFile" className="btn btn-primary" style={{ fontWeight: "600" }}>
                + Aggiungi file
              </label>
              <input
                type="file"
                id="importFile"
                accept=".xls, .xlsx"
                style={{ display: "none" }}
                onChange={selectFile}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
