import { FileTypeData } from "../models/fileApiTypes"
import { toastPromise } from "../utils/toastMethod"
import axiosDashboard from "./clientApi"

export async function uploadInvitationsFile(tenant: string | undefined, form: FormData) {
  return new Promise<FileTypeData>((resolve) => {
    toastPromise(
      axiosDashboard
        .post(`/dashboard/${tenant}/invitations/import`, form, {
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent.loaded + " " + progressEvent.total)
          },
          timeout: 60000,
        })
        .then((response) => resolve(response.data)),
      "Caricamento in corso",
      "Caricamento completato",
      "Qualcosa è andato storto"
    )
  })
}

export async function downloadInvitationsFile(tenant: string | undefined) {
  return axiosDashboard
    .get(`/dashboard/${tenant}/invitations/files/import-template`, { responseType: "blob" })
    .then((response) => response.data)
}
