interface RsvpStatusCellProps {
  backgroundColor: string
  color: string
  text: string
}

export default function RsvpStatusCell({ backgroundColor, color, text }: RsvpStatusCellProps) {
  return (
    <div
      style={{
        backgroundColor,
        color,
        borderRadius: "4px",
        padding: "4px",
        minWidth: "100px",
        textAlign: "center",
      }}
    >
      <span style={{ padding: "10px" }}>{text}</span>
    </div>
  )
}
