import {
  AddWedding,
  PatchRsvpEnabledSettings,
  PatchWeddingSettings,
  QrCodeSettings,
  UpdateWedding,
} from "../models/weddingApiTypes"
import { toastPromise } from "../utils/toastMethod"
import axiosDashboard from "./clientApi"

export async function addWeddingAPI(tenant: string | undefined, payload: AddWedding) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .post(`/dashboard/${tenant}/wedding`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getWeddingAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/wedding`).then((response) => response.data)
}

export async function updateWeddingAPI(tenant: string | undefined, payload: UpdateWedding) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .put(`/dashboard/${tenant}/wedding`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getWeddingsRolesAPI() {
  return axiosDashboard.get(`/dashboard/user/weddingsroles`).then((response) => response.data)
}

export async function getWeddingsRsvpEnabledSettingAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/wedding/settings/rsvp/enabled`).then((response) => response.data)
}

export async function updateWeddingsRsvpEnabledSettingAPI(
  tenant: string | undefined,
  payload: PatchRsvpEnabledSettings
) {
  return new Promise<string>((resolve, reject) => {
    axiosDashboard
      .patch(`/dashboard/${tenant}/wedding/settings/rsvp/enabled`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err))
  })
}

export async function getWeddingsRsvpTenantSettingAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/wedding/settings/rsvp/tenant`).then((response) => response.data)
}

export async function getWeddingsRsvpSettingAPI(tenant: string | undefined) {
  return axiosDashboard.get(`/dashboard/${tenant}/wedding/settings/rsvp`).then((response) => response.data)
}

export async function updateShowIntolerancesAPI(tenant: string | undefined, payload: PatchWeddingSettings) {
  return new Promise<string>((resolve) => {
    toastPromise(
      axiosDashboard
        .patch(`/dashboard/${tenant}/wedding/settings/rsvp/showintolerances`, payload, {
          responseType: "blob",
          timeout: 20000,
        })
        .then((response) => resolve(response.data)),
      "Modifica in corso",
      "Modifica completata",
      "Qualcosa è andato storto"
    )
  })
}

export async function updateShowBusTransferAPI(tenant: string | undefined, payload: PatchWeddingSettings) {
  return new Promise<string>((resolve) => {
    toastPromise(
      axiosDashboard
        .patch(`/dashboard/${tenant}/wedding/settings/rsvp/showbustransfer`, payload, {
          responseType: "blob",
          timeout: 20000,
        })
        .then((response) => resolve(response.data)),
      "Modifica in corso",
      "Modifica completata",
      "Qualcosa è andato storto"
    )
  })
}

export async function updateShowArrivalDateAPI(tenant: string | undefined, payload: PatchWeddingSettings) {
  return new Promise<string>((resolve) => {
    toastPromise(
      axiosDashboard
        .patch(`/dashboard/${tenant}/wedding/settings/rsvp/showarrivaldate`, payload, {
          responseType: "blob",
          timeout: 20000,
        })
        .then((response) => resolve(response.data)),
      "Modifica in corso",
      "Modifica completata",
      "Qualcosa è andato storto"
    )
  })
}

export async function updateShowEmailAPI(tenant: string | undefined, payload: PatchWeddingSettings) {
  return new Promise<string>((resolve) => {
    toastPromise(
      axiosDashboard
        .patch(`/dashboard/${tenant}/wedding/settings/rsvp/showemail`, payload, {
          responseType: "blob",
          timeout: 20000,
        })
        .then((response) => resolve(response.data)),
      "Modifica in corso",
      "Modifica completata",
      "Qualcosa è andato storto"
    )
  })
}

export async function downloadAlbumPhotoQrCodesFile(tenant: string | undefined, payload: QrCodeSettings) {
  return new Promise<Blob>((resolve) => {
    toastPromise(
      axiosDashboard
        .post(`/dashboard/${tenant}/wedding/photos/qrcode`, payload, {
          responseType: "blob",
          timeout: 60000,
        })
        .then((response) => resolve(response.data)),
      "Download in corso",
      "Download completato",
      "Qualcosa è andato storto"
    )
  })
}
