import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { AddWedding } from "../../models/weddingApiTypes"

interface ProvisioningFirstStepProps {
  setNextStep: (newStep: number) => void
  setNewWeddingInfo: (newWeddingInfo: AddWedding) => void
  actualWeddingInfo: AddWedding
}

const provisioningSchema = Yup.object().shape({
  nameFirstOwner: Yup.string().min(2, "*Nome troppo corto").max(30, "*Nome troppo lungo").required("*Inserisci nome"),
  surnameFirstOwner: Yup.string()
    .min(2, "*Cognome troppo corto")
    .max(30, "*Cognome troppo lungo")
    .required("*Inserisci cognome"),
  nameSecondOwner: Yup.string().min(2, "*Nome troppo corto").max(30, "*Nome troppo lungo").required("*Inserisci nome"),
  surnameSecondOwner: Yup.string()
    .min(2, "*Cognome troppo corto")
    .max(30, "*Cognome troppo lungo")
    .required("*Inserisci cognome"),
})

export default function ProvisioningFirstStep({
  setNextStep,
  setNewWeddingInfo,
  actualWeddingInfo,
}: ProvisioningFirstStepProps) {
  return (
    <>
      <Formik
        initialValues={{
          nameFirstOwner: actualWeddingInfo.nameFirstOwner,
          surnameFirstOwner: actualWeddingInfo.surnameFirstOwner,
          nameSecondOwner: actualWeddingInfo.nameSecondOwner,
          surnameSecondOwner: actualWeddingInfo.surnameSecondOwner,
          weddingDate: actualWeddingInfo.weddingDate,
          location: actualWeddingInfo.location,
          rsvpSettings: {
            isRsvpEnabled: actualWeddingInfo.rsvpSettings.isRsvpEnabled,
            showArrivalDate: actualWeddingInfo.rsvpSettings.showArrivalDate,
            showBusTransferNeeded: actualWeddingInfo.rsvpSettings.showBusTransferNeeded,
            showEmail: actualWeddingInfo.rsvpSettings.showEmail,
            showIntolerances: actualWeddingInfo.rsvpSettings.showIntolerances,
          },
        }}
        validationSchema={provisioningSchema}
        onSubmit={async (values: AddWedding) => {
          setNewWeddingInfo(values)
          setNextStep(1)
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div>
              <label className="provisioning-form-label" htmlFor="nameFirstOwner">
                Nome sposo/a
              </label>
              <Field
                className="provisioning-form-input"
                id="nameFirstOwner"
                name="nameFirstOwner"
                placeholder="Inserisci il nome"
                type="text"
              />
              {errors.nameFirstOwner && touched.nameFirstOwner ? (
                <div className="provisioning-form-input-error">{errors.nameFirstOwner}</div>
              ) : null}
            </div>

            <div>
              <label className="provisioning-form-label" htmlFor="surnameFirstOwner">
                Cognome sposo/a
              </label>
              <Field
                className="provisioning-form-input"
                id="surnameFirstOwner"
                name="surnameFirstOwner"
                placeholder="Inserisci il cognome"
                type="text"
              />
              {errors.surnameFirstOwner && touched.surnameFirstOwner ? (
                <div className="provisioning-form-input-error">{errors.surnameFirstOwner}</div>
              ) : null}
            </div>

            <div>
              <label className="provisioning-form-label" htmlFor="nameSecondOwner">
                Nome sposo/a
              </label>
              <Field
                className="provisioning-form-input"
                id="nameSecondOwner"
                name="nameSecondOwner"
                placeholder="Inserisci il nome"
                type="text"
              />
              {errors.nameSecondOwner && touched.nameSecondOwner ? (
                <div className="provisioning-form-input-error">{errors.nameSecondOwner}</div>
              ) : null}
            </div>

            <div>
              <label className="provisioning-form-label" htmlFor="surnameSecondOwner">
                Cognome sposo/a
              </label>
              <Field
                className="provisioning-form-input"
                id="surnameSecondOwner"
                name="surnameSecondOwner"
                placeholder="Inserisci il cognome"
                type="text"
              />
              {errors.surnameSecondOwner && touched.surnameSecondOwner ? (
                <div className="provisioning-form-input-error">{errors.surnameSecondOwner}</div>
              ) : null}
            </div>

            <div className="provisoning-button-box">
              <button type="submit" className="btn btn-primary provisoning-button-single">
                Continua
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
