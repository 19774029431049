import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  GuestData,
  SeatDetailsData,
  TableBaseData,
  TableDetailsData,
  TablesLayoutInfo,
  UpdateShapeData,
} from "../../models/tableApiTypes"

export enum UPDATE_TYPE {
  NONE = "NONE",
  UPDATE_SHAPE = "UPDATE_SHAPE",
  UPDATE_TABLE_INFO = "UPDATE_TABLE_INFO",
}

export enum TOOL_PALET_VIEW_TYPE {
  NONE = "NONE",
  TABLE = "TABLE",
  SEAT = "SEAT",
}

interface ToolPalet {
  toolPaletViewType: TOOL_PALET_VIEW_TYPE
  toolPaletTableData?: TableDetailsData
  toolPaletSeatData?: SeatDetailsData
}

interface UpdateConditions {
  updateFlag: boolean
  updateType: UPDATE_TYPE
}

interface UpdateShape {
  updateShapeid: string
  updateShapeData: UpdateShapeData
}

interface UpdateLogic {
  updateElementid: string
  updateTableInfoData: TableBaseData
}

interface TablesLayout {
  showGuestSurname: boolean
  hideEmptyChair: boolean
  layout: TablesLayoutInfo
  tables: TableDetailsData[]
  toolPalet: ToolPalet
  updateConditions: UpdateConditions
  updateShape: UpdateShape
  updateLogic: UpdateLogic
}

const tableLayoutInitialValue: TablesLayoutInfo = {
  id: "",
  name: "",
  description: "",
  approved: false,
  sharingEnabled: false,
}

const tableDataInitialValue: TableDetailsData = {
  id: "",
  layoutId: "",
  name: "",
  description: "",
  seats: [],
  shape: {
    x: 0,
    y: 0,
    rotationDegrees: 0,
    width: 0,
    height: 0,
    id: "",
    parentShapeId: "",
    title: "",
    type: "",
  },
}

const seatDataInitialValue: SeatDetailsData = {
  id: "",
  order: 0,
  tableId: "",
  shape: {
    x: 0,
    y: 0,
    rotationDegrees: 0,
    width: 0,
    height: 0,
    id: "",
    parentShapeId: "",
    title: "",
    type: "",
  },
  guest: {
    id: "",
    name: "",
    surname: "",
    status: "",
    group: "",
    invitation: "",
    type: "",
    rsvp: {
      rsvpDate: new Date(),
      arrivalDate: new Date(),
      intolerances: [],
      email: "",
      busTransferNeeded: false,
      accepted: false,
    },
  },
}

const updateShapeInitialValue: UpdateShape = {
  updateShapeid: "",
  updateShapeData: {
    x: 0,
    y: 0,
    rotationDegrees: 0,
    width: 0,
    height: 0,
    childs: [],
  },
}

const updateElementInitialValue: UpdateLogic = {
  updateElementid: "",
  updateTableInfoData: {
    name: "",
    description: "",
  },
}

const initialState: TablesLayout = {
  showGuestSurname: false,
  hideEmptyChair: false,
  layout: tableLayoutInitialValue,
  tables: [],
  toolPalet: {
    toolPaletViewType: TOOL_PALET_VIEW_TYPE.NONE,
    toolPaletTableData: tableDataInitialValue,
    toolPaletSeatData: seatDataInitialValue,
  },
  updateConditions: {
    updateFlag: false,
    updateType: UPDATE_TYPE.NONE,
  },
  updateShape: updateShapeInitialValue,
  updateLogic: updateElementInitialValue,
}

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setTableShowGuestSurnameState: (state: TablesLayout, action: PayloadAction<{ showGuestSurname: boolean }>) => {
      state.showGuestSurname = action.payload.showGuestSurname
    },
    setTableHideEmptyChairState: (state: TablesLayout, action: PayloadAction<{ hideEmptyChair: boolean }>) => {
      state.hideEmptyChair = action.payload.hideEmptyChair
    },
    setTableLayoutState: (state: TablesLayout, action: PayloadAction<{ tablesLayout: TablesLayoutInfo }>) => {
      state.layout = action.payload.tablesLayout
    },
    setTablesState: (state: TablesLayout, action: PayloadAction<{ tables: TableDetailsData[] }>) => {
      state.tables = action.payload.tables
    },
    addNewTable: (state: TablesLayout, action: PayloadAction<{ tables: TableDetailsData }>) => {
      state.tables.push(action.payload.tables)
    },
    updateTableInfo: (state: TablesLayout, action: PayloadAction<{ tableId: string; tableInfo: TableBaseData }>) => {
      const table = state.tables.find((table) => table.id === action.payload.tableId)
      if (table !== undefined) {
        const tableIndex = state.tables.indexOf(table)
        state.tables[tableIndex].name = action.payload.tableInfo.name
        state.tables[tableIndex].description = action.payload.tableInfo.description
        if (state.toolPalet.toolPaletTableData !== undefined) {
          state.toolPalet.toolPaletTableData.name = action.payload.tableInfo.name
          state.toolPalet.toolPaletTableData.description = action.payload.tableInfo.description
        }
        state.updateConditions.updateFlag = true
        state.updateConditions.updateType = UPDATE_TYPE.UPDATE_TABLE_INFO
        state.updateLogic.updateElementid = action.payload.tableId
        state.updateLogic.updateTableInfoData = action.payload.tableInfo
      }
    },
    updateTableShape: (
      state: TablesLayout,
      action: PayloadAction<{ tableShapeId: string; tableShape: UpdateShapeData }>
    ) => {
      return {
        ...state,
        updateConditions: {
          updateFlag: true,
          updateType: UPDATE_TYPE.UPDATE_SHAPE,
        },
        updateShape: {
          ...state.updateShape,
          updateShapeid: action.payload.tableShapeId,
          updateShapeData: action.payload.tableShape,
        },
      }
    },
    updateTableCoordinate: (
      state: TablesLayout,
      action: PayloadAction<{ tableShapeId: string; newX: number; newY: number }>
    ) => {
      const table = state.tables.find((table) => table.shape.id === action.payload.tableShapeId)
      if (table !== undefined) {
        const tableIndex = state.tables.indexOf(table)
        state.tables[tableIndex].shape.x = action.payload.newX
        state.tables[tableIndex].shape.y = action.payload.newY
      }
    },
    updateTableRotationDegree: (
      state: TablesLayout,
      action: PayloadAction<{ tableShapeId: string; newRotationDegree: number }>
    ) => {
      const table = state.tables.find((table) => table.shape.id === action.payload.tableShapeId)
      if (table !== undefined) {
        const tableIndex = state.tables.indexOf(table)
        state.tables[tableIndex].shape.rotationDegrees = action.payload.newRotationDegree
      }
    },
    setToolPaletView: (state: TablesLayout, action: PayloadAction<{ newToolPaletView: ToolPalet }>) => {
      return {
        ...state,
        toolPalet: {
          ...state.toolPalet,
          toolPaletViewType: action.payload.newToolPaletView.toolPaletViewType,
          toolPaletTableData: action.payload.newToolPaletView.toolPaletTableData,
          toolPaletSeatData: action.payload.newToolPaletView.toolPaletSeatData,
        },
      }
    },
    setUpdateStateFlag: (state: TablesLayout, action: PayloadAction<{ updateState: boolean }>) => {
      return {
        ...state,
        updateConditions: {
          updateFlag: action.payload.updateState,
          updateType: UPDATE_TYPE.NONE,
        },
      }
    },
    deleteTable: (state: TablesLayout, action: PayloadAction<{ tableId: string }>) => {
      return {
        ...state,
        tables: state.tables.filter((table) => table.id !== action.payload.tableId),
        toolPalet: {
          toolPaletTableData: undefined,
          toolPaletSeatData: undefined,
          toolPaletViewType: TOOL_PALET_VIEW_TYPE.NONE,
        },
      }
    },
    updateTableSeatGuest: (
      state: TablesLayout,
      action: PayloadAction<{
        guest: GuestData
        seatDetails: SeatDetailsData
        tableShapeId: string
        tableSeatId: string
      }>
    ) => {
      const table = state.tables.find((table) => table.shape.id === action.payload.tableShapeId)
      if (table !== undefined) {
        const tableIndex = state.tables.indexOf(table)
        const seat = state.tables[tableIndex].seats.find((seat) => seat.id === action.payload.tableSeatId)
        if (seat !== undefined) {
          const seatIndex = state.tables[tableIndex].seats.indexOf(seat)
          state.tables[tableIndex].seats[seatIndex].guest = action.payload.guest
          if (state.toolPalet.toolPaletSeatData !== undefined) {
            state.toolPalet.toolPaletSeatData.guest = action.payload.guest
          }
        }
      }
    },
    deleteTableSeatGuest: (
      state: TablesLayout,
      action: PayloadAction<{ tableShapeId: string; tableSeatId: string }>
    ) => {
      const table = state.tables.find((table) => table.shape.id === action.payload.tableShapeId)
      if (table !== undefined) {
        const tableIndex = state.tables.indexOf(table)
        const seat = state.tables[tableIndex].seats.find((seat) => seat.id === action.payload.tableSeatId)
        if (seat !== undefined) {
          const seatIndex = state.tables[tableIndex].seats.indexOf(seat)
          state.tables[tableIndex].seats[seatIndex].guest = null
          state.toolPalet.toolPaletViewType = TOOL_PALET_VIEW_TYPE.NONE
          state.toolPalet.toolPaletSeatData = undefined
        }
      }
    },
  },
})

export const {
  setTableShowGuestSurnameState,
  setTableHideEmptyChairState,
  setTableLayoutState,
  setTablesState,
  addNewTable,
  updateTableInfo,
  updateTableShape,
  updateTableCoordinate,
  updateTableRotationDegree,
  setUpdateStateFlag,
  setToolPaletView,
  deleteTable,
  updateTableSeatGuest,
  deleteTableSeatGuest,
} = tableSlice.actions

export default tableSlice.reducer
