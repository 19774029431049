/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useReducer, useState } from "react"
import Select from "react-select"
import HeaderBar from "../HeaderBar"
import {
  ACTION_TYPES,
  invitationFormReducer,
  checkRequiredField,
  InvitationFormReducer,
  GuestFormReducer,
} from "./reducer/invitationFormReducer"
import {
  addGuestAPI,
  addInvitationAPI,
  deleteGuestAPI,
  deleteInvitationAPI,
  updateGuestAPI,
  updateInvitationNameAPI,
} from "../../api/invitationsApi"
import { useLocation, useNavigate } from "react-router-dom"
import { getGuestTypeDetailsAPI } from "../../api/guestTypeApi"
import { GuestTypeDetailsData } from "../../models/guestTypeApiTypes"
import { getGuestGroupsDetailsAPI } from "../../api/guestGroupApi"
import { GuestGroupDetailsData } from "../../models/guestGroupApiTypes"
import { getWeddingAPI } from "../../api/weddingApi"
import { WeddingInfo } from "../../models/weddingApiTypes"
import { PatchInvitationName } from "../../models/invitationsApiTypes"
import { toastSuccess, toastWarning } from "../../utils/toastMethod"
import modifyLogo from "../../icon/modify_icon.svg"
import saveLogo from "../../icon/save_icon.svg"

interface SelectOptions {
  value: string
  label: string
}

interface InvitationFormProps {
  tenant: string | undefined
  invitation: InvitationFormReducer
  isUpdate: boolean
}

export default function InvitationForm({ tenant, invitation, isUpdate }: InvitationFormProps) {
  const [state, dispatch] = useReducer(invitationFormReducer, {
    isInvitationNameChangedManually: isUpdate,
    isEmpty: false,
    isInvitationNameUpdating: false,
    invitation,
  })
  const [selectTypesOptions, setSelectTypesOptions] = useState<SelectOptions[]>([])
  const [selectGroupsOptions, setSelectGroupsOptions] = useState<SelectOptions[]>([])
  const [wedding, setWedding] = useState<WeddingInfo | undefined>()
  const navigate = useNavigate()
  const search = useLocation().search
  const page = new URLSearchParams(search).get("page") ?? "0"

  useEffect(() => {
    tenant &&
      getGuestTypeDetailsAPI(tenant, true)
        .then((result: GuestTypeDetailsData[]) => {
          const options: SelectOptions[] = []
          result.forEach((res: GuestTypeDetailsData) => {
            options.push({
              value: res.id,
              label: res.name,
            })
          })
          setSelectTypesOptions([...options])
        })
        .catch((error) => alert(error))
    tenant &&
      getGuestGroupsDetailsAPI(tenant)
        .then((result: GuestGroupDetailsData[]) => {
          const options: SelectOptions[] = []
          result.forEach((res: GuestGroupDetailsData) => {
            options.push({
              value: res.id,
              label: res.name,
            })
          })
          setSelectGroupsOptions([...options])
        })
        .catch((error) => alert(error))
    tenant &&
      getWeddingAPI(tenant)
        .then((result) => {
          setWedding(result)
        })
        .catch((error) => alert(error))
  }, [tenant])

  const saveInvitation = async () => {
    if (checkRequiredField(state.invitation.guests)) {
      toastWarning("Inserisci tutti i dati mancanti!.")
      dispatch({
        type: ACTION_TYPES.SHOW_ERROR,
        payload: true,
      })
      return
    }
    addInvitationAPI(tenant, state.invitation)
      .then(() => {
        toastSuccess("Invito inserito correttamente.")
        navigate(`/${tenant}/invitations`)
      })
      .catch(() => {
        toastWarning("Qualcosa è andato storto. Riprova!")
      })
  }

  const updateGuest = async (guest: GuestFormReducer, invitationId: string | undefined, guestIndex: number) => {
    if (checkRequiredField(state.invitation.guests)) {
      toastWarning("Inserisci tutti i dati mancanti!.")
      dispatch({
        type: ACTION_TYPES.SHOW_ERROR,
        payload: true,
      })
      return
    }
    if (guest.id !== undefined) {
      updateGuestAPI(tenant, guest.id, guest)
        .then(() => {
          toastSuccess("Invitato aggiornato.")
        })
        .catch(() => {
          toastWarning("Qualcosa è andato storto. Riprova!")
        })
    } else if (invitationId !== undefined) {
      addGuestAPI(tenant, invitationId, guest)
        .then((res) => {
          dispatch({
            type: ACTION_TYPES.UPDATE_GUEST_ID,
            arrayIndex: guestIndex,
            payload: res,
          })
          toastSuccess("Invitato inserito.")
        })
        .catch(() => {
          toastWarning("Qualcosa è andato storto. Riprova!")
        })
    } else {
      toastWarning("Qualcosa è andato storto")
    }
  }

  const removeGuest = async (guest: GuestFormReducer) => {
    if (guest.id === undefined) {
      dispatch({
        type: ACTION_TYPES.REMOVE_ITEM,
        payload: guest,
      })
    } else {
      deleteGuestAPI(tenant, guest.id)
        .then(() => {
          dispatch({
            type: ACTION_TYPES.REMOVE_ITEM,
            payload: guest,
          })
          toastSuccess("Invitato rimosso.")
        })
        .catch(() => {
          toastWarning("Qualcosa è andato storto. Riprova!")
        })
    }
  }

  const updateInvitationName = async (invitationId: string | undefined, newName: string) => {
    if (invitationId === undefined) {
      toastWarning("Qualcosa è andato storto")
    } else {
      const updateInvitaionName: PatchInvitationName = {
        name: newName,
      }
      updateInvitationNameAPI(tenant, invitationId, updateInvitaionName)
        .then(() => {
          dispatch({
            type: ACTION_TYPES.UPDATE_INPUT_NAME,
            payload: false,
          })
          toastSuccess("Nome invito modificato.")
        })
        .catch(() => {
          dispatch({
            type: ACTION_TYPES.UPDATE_INPUT_NAME,
            payload: false,
          })
          toastWarning("Qualcosa è andato storto. Riprova!")
        })
    }
  }

  const deleteInvitation = async (invitationId: string | undefined) => {
    if (invitationId === undefined) {
      toastWarning("Qualcosa è andato storto")
    } else {
      deleteInvitationAPI(tenant, invitationId)
        .then(() => {
          toastSuccess("Invito eliminato correttamente.")
          navigate(`/${tenant}/invitations?page=${page}`)
        })
        .catch(() => {
          toastWarning("Qualcosa è andato storto. Riprova!")
        })
    }
  }

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided: any) => ({
      ...provided,
      border: "1px solid #ebf2fa",
      boxShadow: "none",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 16,
      color: state.selectProps.menuColor,
      padding: 10,
      fontSize: 14,
    }),
    option: (provided: any) => ({
      ...provided,
      borderRadius: 16,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: 40,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: 14,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: 14,
      color: "#b5b5bd",
    }),
  }

  return (
    <main>
      <div>
        {isUpdate ? (
          <HeaderBar
            title={
              <>
                {state.isInvitationNameUpdating ? (
                  <input
                    className="form-input"
                    style={{ fontWeight: "inherit", fontSize: "inherit" }}
                    type="text"
                    placeholder="Nome Invito"
                    value={state.invitation.name}
                    onChange={(e) =>
                      dispatch({
                        type: ACTION_TYPES.CHANGE_INPUT_NAME,
                        payload: e.currentTarget.value,
                      })
                    }
                  ></input>
                ) : (
                  state.invitation.name
                )}
              </>
            }
            subtitleLogo={
              state.isInvitationNameUpdating ? (
                <img alt="saveLogo" src={saveLogo} style={{ marginRight: "10px" }} />
              ) : (
                <img alt="modifyLogo" src={modifyLogo} style={{ marginRight: "10px" }} />
              )
            }
            subtitle={
              state.isInvitationNameUpdating ? (
                <span
                  style={{ color: "#718096", fontSize: "14px", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => updateInvitationName(state.invitation.id, state.invitation.name)}
                >
                  Salva
                </span>
              ) : (
                <span
                  style={{ color: "#718096", fontSize: "14px", fontWeight: "bold", cursor: "pointer" }}
                  onClick={() =>
                    dispatch({
                      type: ACTION_TYPES.UPDATE_INPUT_NAME,
                      payload: true,
                    })
                  }
                >
                  Modifica
                </span>
              )
            }
          >
            <button
              type="button"
              className="btn btn-danger"
              style={{ marginRight: "10px" }}
              onClick={() => deleteInvitation(state.invitation.id)}
            >
              Elimina Invito
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate(`/${tenant}/invitations?page=${page}`)}
            >
              Chiudi
            </button>
          </HeaderBar>
        ) : (
          <HeaderBar
            title={
              <input
                className="form-input"
                style={{ fontWeight: "inherit", fontSize: "inherit" }}
                type="text"
                placeholder="Nome Invito"
                value={state.invitation.name}
                onChange={(e) =>
                  dispatch({
                    type: ACTION_TYPES.CHANGE_INPUT_NAME,
                    payload: e.currentTarget.value,
                  })
                }
              ></input>
            }
          >
            <button type="button" className="btn btn-primary" style={{ marginRight: "10px" }} onClick={saveInvitation}>
              Salva e chiudi
            </button>
            <button type="button" className="btn btn-primary" onClick={() => navigate(`/${tenant}/invitations`)}>
              Chiudi
            </button>
          </HeaderBar>
        )}
      </div>
      {wedding && selectTypesOptions && selectGroupsOptions && (
        <div className="container">
          {state.invitation.guests?.map((guest, index) => (
            <div key={index}>
              <div className="container form-container">
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="col">
                    <label className="form-label">Nome Invitato</label>
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Mario"
                      value={guest.name}
                      onChange={(e) =>
                        dispatch({
                          type: ACTION_TYPES.CHANGE_INPUT_GUEST_NAME,
                          arrayIndex: index,
                          payload: e.currentTarget.value,
                        })
                      }
                    ></input>
                    {guest.name.length < 1 && state.isEmpty ? (
                      <div style={{ color: "#ff0000", fontSize: "10px" }}>* Inserisci il nome</div>
                    ) : null}
                  </div>
                  <div className="col">
                    <label className="form-label">Cognome Invitato</label>
                    <input
                      className="form-input"
                      type="text"
                      placeholder="Rossi"
                      value={guest.surname}
                      onChange={(e) =>
                        dispatch({
                          type: ACTION_TYPES.CHANGE_INPUT_GUEST_SURNAME,
                          arrayIndex: index,
                          payload: e.currentTarget.value,
                        })
                      }
                    ></input>
                  </div>
                  <div className="col">
                    <label className="form-label">Tipologia Invitato</label>
                    <Select
                      styles={customStyles}
                      options={selectTypesOptions}
                      value={selectTypesOptions.find((e) => e.value === guest.typeId) ?? null}
                      onChange={(e) =>
                        dispatch({
                          type: ACTION_TYPES.CHANGE_DROPDOWN_TYPE,
                          arrayIndex: index,
                          payload: e !== null ? e.value : "",
                        })
                      }
                    />
                    {guest.typeId.length < 1 && state.isEmpty ? (
                      <div style={{ color: "#ff0000", fontSize: "10px" }}>* Inserisci il tipo</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  {wedding.rsvpSettings.showIntolerances ||
                  wedding.rsvpSettings.showEmail ||
                  wedding.rsvpSettings.showBusTransferNeeded ||
                  wedding.rsvpSettings.showArrivalDate ? (
                    <div className="col">
                      <label className="form-label">Cosa vi serve sapere?</label>
                      {wedding.rsvpSettings.showIntolerances && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ flex: "9" }}>
                              <label className="toggle-form-label">Intolleranze</label>
                            </div>
                            <div style={{ flex: "1" }}>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={guest.rsvpSettings.showIntolerances}
                                  onChange={(e) =>
                                    dispatch({
                                      type: ACTION_TYPES.CHANGE_TOGGLE_INTOLERANCES,
                                      arrayIndex: index,
                                      payload: e.target.checked,
                                    })
                                  }
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {wedding.rsvpSettings.showEmail && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ flex: "9" }}>
                              <label className="toggle-form-label">Email</label>
                            </div>
                            <div style={{ flex: "1" }}>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={guest.rsvpSettings.showEmail}
                                  onChange={(e) =>
                                    dispatch({
                                      type: ACTION_TYPES.CHANGE_TOGGLE_EMAIL,
                                      arrayIndex: index,
                                      payload: e.target.checked,
                                    })
                                  }
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {wedding.rsvpSettings.showBusTransferNeeded && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ flex: "9" }}>
                              <label className="toggle-form-label">Servizio bus navetta</label>
                            </div>
                            <div style={{ flex: "1" }}>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={guest.rsvpSettings.showBusTransferNeeded}
                                  onChange={(e) =>
                                    dispatch({
                                      type: ACTION_TYPES.CHANGE_TOGGLE_BUS,
                                      arrayIndex: index,
                                      payload: e.target.checked,
                                    })
                                  }
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {wedding.rsvpSettings.showArrivalDate && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ flex: "9" }}>
                              <label className="toggle-form-label">Data di arrivo</label>
                            </div>
                            <div style={{ flex: "1" }}>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={guest.rsvpSettings.showArrivalDate}
                                  onChange={(e) =>
                                    dispatch({
                                      type: ACTION_TYPES.CHANGE_TOGGLE_DATE,
                                      arrayIndex: index,
                                      payload: e.target.checked,
                                    })
                                  }
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="col"></div>
                  )}
                  <div className="col">
                    <label className="form-label">Sei sicuro?</label>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: "9" }}>
                          <label className="toggle-form-label">Salva l&apos;invitato come bozza</label>
                        </div>
                        <div style={{ flex: "1" }}>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={guest.isDraft}
                              onChange={(e) =>
                                dispatch({
                                  type: ACTION_TYPES.CHANGE_TOGGLE_DRAFT,
                                  arrayIndex: index,
                                  payload: e.target.checked,
                                })
                              }
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <label className="form-label">Gruppo Invitato</label>
                    <Select
                      styles={customStyles}
                      options={selectGroupsOptions}
                      value={selectGroupsOptions.find((e) => e.value === guest.groupId) ?? null}
                      isClearable
                      onChange={(e) =>
                        dispatch({
                          type: ACTION_TYPES.CHANGE_DROPDOWN_GROUP,
                          arrayIndex: index,
                          payload: e !== null ? e.value : "",
                        })
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {state.invitation.guests.length >= 1 && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "2px solid #e9e7e7",
                          marginTop: "15px",
                          marginRight: "15px",
                        }}
                        onClick={() => {
                          if (isUpdate) {
                            removeGuest(guest)
                          } else {
                            dispatch({
                              type: ACTION_TYPES.REMOVE_ITEM,
                              payload: guest,
                            })
                          }
                        }}
                      >
                        Rimuovi Invitato
                      </button>
                    </div>
                  )}
                  {isUpdate && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                        onClick={() => updateGuest(guest, invitation.id, index)}
                      >
                        Salva
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          <div>
            <button
              type="button"
              className="btn btn-primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
              onClick={() =>
                dispatch({
                  type: ACTION_TYPES.ADD_ITEM,
                  payload: {
                    showIntolerances: wedding.rsvpSettings.showIntolerances,
                    showArrivalDate: wedding.rsvpSettings.showArrivalDate,
                    showEmail: wedding.rsvpSettings.showEmail,
                    showBusTransferNeeded: wedding.rsvpSettings.showBusTransferNeeded,
                  },
                })
              }
            >
              + Aggiungi Invitato
            </button>
          </div>
        </div>
      )}
    </main>
  )
}
