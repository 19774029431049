import { useEffect, useState } from "react"
import { addGuestTypeAPI, deleteGuestTypeAPI, getGuestTypeDetailsAPI, updateGuestTypeAPI } from "../../api/guestTypeApi"
import { AddGuestTypeData, GuestTypeDetailsData, UpdateGuestTypeData } from "../../models/guestTypeApiTypes"
import { CustomCard } from "./../CustomCard"
import { toastSuccess, toastWarning } from "../../utils/toastMethod"
import modifyIcon from "../../icon/modify_icon.svg"
import deleteIcon from "../../icon/delete-icon.svg"
import addInvitationIcon from "../../icon/add_icon.svg"
import saveIcon from "../../icon/save_icon.svg"

enum EDIT_TYPE_FIELD {
  NAME = "name",
  DESCRIPTION = "description",
  COST = "cost",
}

interface GuestTypeInfoProps {
  tenant: string | undefined
}

interface GuestTypeDetails {
  data: GuestTypeDetailsData
  isEditable: boolean
}

export default function GuestTypeInfo({ tenant }: GuestTypeInfoProps) {
  const [guestTypesDetails, setGuestTypesDetails] = useState<GuestTypeDetails[]>([])

  useEffect(() => {
    getGuestTypeDetailsAPI(tenant, false)
      .then((result) => {
        setGuestTypesDetails([])
        result.forEach(function (r: GuestTypeDetailsData) {
          const newTypeDetails: GuestTypeDetails = {
            data: {
              name: r.name,
              description: r.description,
              cost: r.cost,
              id: r.id,
              isDefault: r.isDefault,
            },
            isEditable: false,
          }
          setGuestTypesDetails((typesDetails) => [...typesDetails, newTypeDetails])
        })
      })
      .catch(() => console.error("ERROR"))
  }, [tenant])

  const addGuestType = () => {
    const addType: AddGuestTypeData = {
      name: "Nuovo Tipo",
      cost: 100,
      description: "Inserisci una descrizione",
    }
    addGuestTypeAPI(tenant, addType)
      .then((newTypeId) => {
        const newType: GuestTypeDetails = {
          data: {
            name: addType.name,
            cost: addType.cost,
            description: addType.description,
            id: newTypeId,
            isDefault: false,
          },
          isEditable: false,
        }
        setGuestTypesDetails((currentGuestType) => [...currentGuestType, newType])
        toastSuccess("Nuovo tipo inserito.")
      })
      .catch(() => toastWarning("Problemi durante l'aggiunta di un tipo. Riprova!"))
  }

  const deleteGuestType = (id: string) => {
    deleteGuestTypeAPI(tenant, id)
      .then(() => {
        const newGuestType = guestTypesDetails.filter((type) => type.data.id !== id)
        setGuestTypesDetails(newGuestType)
        toastSuccess("Tipo cancellato.")
      })
      .catch((error) => {
        if (error.response.data.Messages[0] === "guestType.isDefault") {
          toastWarning("Non puoi cancellare il tipo di default.")
        } else if (error.response.data.Messages[0] === "guestType.isLast") {
          toastWarning("Non puoi cancellare il tipo l'utlimo tipo.")
        } else {
          toastWarning("Problemi durante la cancellazione di un tipo. Riprova!")
        }
      })
  }

  const updateGuestType = (index: number, type: GuestTypeDetails) => {
    const actualGuestTypeDetails = [...guestTypesDetails]
    const updateGuestType: UpdateGuestTypeData = {
      name: type.data.name,
      description: type.data.description,
      cost: type.data.cost,
    }
    updateGuestTypeAPI(tenant, type.data.id, updateGuestType)
      .then(() => {
        actualGuestTypeDetails[index].isEditable = false
        setGuestTypesDetails(actualGuestTypeDetails)
        toastSuccess("Tipo aggiornato.")
      })
      .catch(() => toastWarning("Problemi durante l'aggiornamento del tipo. Riprova!"))
  }

  const enableEditGuestType = (index: number) => {
    const actualGuestTypeDetails = [...guestTypesDetails]
    actualGuestTypeDetails[index].isEditable = true
    setGuestTypesDetails(actualGuestTypeDetails)
  }

  const updateTypeName = (index: number, value: string, field: EDIT_TYPE_FIELD) => {
    const actualGuestTypeDetails = [...guestTypesDetails]
    switch (field) {
      case EDIT_TYPE_FIELD.NAME:
        actualGuestTypeDetails[index].data.name = value
        break
      case EDIT_TYPE_FIELD.DESCRIPTION:
        actualGuestTypeDetails[index].data.description = value
        break
      case EDIT_TYPE_FIELD.COST:
        actualGuestTypeDetails[index].data.cost = Number(value)
        break
      default:
        break
    }
    setGuestTypesDetails(actualGuestTypeDetails)
  }

  return (
    <>
      {guestTypesDetails && (
        <>
          <div className="row">
            {guestTypesDetails.map((type, index) => (
              <div className="col-sm-12, col-md-6 col-lg-4" style={{ marginBottom: "20px" }} key={index}>
                {type.isEditable ? (
                  <CustomCard
                    title={
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <div>
                          <img
                            alt="saveIcon"
                            src={saveIcon}
                            style={{ width: "18px", cursor: "pointer" }}
                            onClick={() => updateGuestType(index, type)}
                          />
                        </div>
                      </div>
                    }
                  >
                    <div>
                      <label className="form-label">Inserisci un nome</label>
                      <input
                        className="form-input"
                        type="text"
                        placeholder="Mario"
                        value={type.data.name}
                        onChange={(e) => updateTypeName(index, e.target.value, EDIT_TYPE_FIELD.NAME)}
                      ></input>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <label className="form-label">Inserisci una descrizione</label>
                      <input
                        className="form-input"
                        type="text"
                        placeholder="Mario"
                        value={type.data.description}
                        onChange={(e) => updateTypeName(index, e.target.value, EDIT_TYPE_FIELD.DESCRIPTION)}
                      ></input>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <label className="form-label">Inserisci un costo</label>
                      <input
                        className="form-input"
                        type="number"
                        placeholder="0"
                        value={type.data.cost}
                        onChange={(e) => updateTypeName(index, e.target.value, EDIT_TYPE_FIELD.COST)}
                      ></input>
                    </div>
                  </CustomCard>
                ) : (
                  <CustomCard
                    title={
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: "9" }}>{type.data.name}</div>
                        <div style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
                          <img
                            alt="modifyIcon"
                            src={modifyIcon}
                            style={{ width: "18px", cursor: "pointer", marginRight: "5px" }}
                            onClick={() => enableEditGuestType(index)}
                          />
                          {!type.data.isDefault && (
                            <img
                              alt="deleteIcon"
                              src={deleteIcon}
                              style={{ width: "18px", cursor: "pointer" }}
                              onClick={() => deleteGuestType(type.data.id)}
                            />
                          )}
                        </div>
                      </div>
                    }
                  >
                    <div style={{ marginTop: "5px" }}>{type.data.description}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "5px",
                      }}
                    >
                      {type.data.isDefault && (
                        <div
                          style={{
                            backgroundColor: "#f8d7da",
                            color: "#9d1c59",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "6px",
                            padding: "5px",
                            marginTop: "5px",
                            marginRight: "5px",
                            textAlign: "center",
                          }}
                        >
                          <span>Default</span>
                        </div>
                      )}
                      <div
                        style={{
                          backgroundColor: "#e4ecf7",
                          color: "#505780",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "6px",
                          padding: "5px",
                          marginTop: "5px",
                          textAlign: "center",
                        }}
                      >
                        <span>{type.data.cost} &#8364;</span>
                      </div>
                    </div>
                  </CustomCard>
                )}
              </div>
            ))}
          </div>
          <button
            type="button"
            className="btn btn-primary button-style"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
            }}
            onClick={addGuestType}
          >
            <img alt="addInvitationIcon" src={addInvitationIcon} style={{ marginRight: "10px" }} />
            Aggiungi Tipo
          </button>
          <div className="row"></div>
        </>
      )}
    </>
  )
}
