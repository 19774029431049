import { useEffect, useState } from "react"
import "../../../style/splash_screen.css"
import "../../../style/provisioning_style.css"

export default function AuthInProgress() {
  const [longLoading, setlongLoading] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setlongLoading(true)
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className="splash-screen">
      <div className="splash-screen-container">
        <div className="splash-screen-circle-tick"></div>
        <div className="splash-screen-content">
          <h3>Accesso in corso</h3>
          <p className="text-muted">
            {!longLoading && <small>Attendere prego</small>}
            {longLoading && (
              <small>
                Sembra che l&apos;accesso richieda più tempo del previsto
                <br />
                Se presente, effettua l&apos;accesso nel popup.
              </small>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}
