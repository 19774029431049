import { useState } from "react"
import { Modal } from "react-bootstrap"
import { GuestRsvp, SendEmail } from "../../models/guestRsvpApiTypes"
import { toastWarning } from "../../utils/toastMethod"
import { sendEmailApi } from "../../api/guestRsvp"

interface RsvpSendEmailModalProps {
  tenant: string | undefined
  guests: GuestRsvp[]
  show: boolean
  setShow: (show: boolean) => void
}

export default function RsvpSendEmailModal({ tenant, guests, show, setShow }: RsvpSendEmailModalProps) {
  const [advancedOptions, setAdvancedOptions] = useState<boolean>(false)
  const [sendEmail, setSendEmail] = useState<SendEmail>({
    guests: [],
    subject: "",
    title: "",
    message: "",
    button: "",
    link: "",
  })

  const hideModal = () => {
    setAdvancedOptions(false)
    setShow(false)
    setSendEmail({ guests: [], subject: "", title: "", message: "", button: "", link: "" })
  }

  const isValidUrl = (url: string | undefined) => {
    if (url === undefined) return false
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    )
    return pattern.test(url)
  }

  const sendEmailAsync = async () => {
    if (sendEmail.subject.length === 0) {
      toastWarning("Inserisci l'oggetto dell'email!")
    } else if (sendEmail.title.length === 0) {
      toastWarning("Inserisci il titolo dell'email!")
    } else if (sendEmail.message.length === 0) {
      toastWarning("Inserisci un messaggio!")
    } else if (sendEmail.button?.length !== 0 && sendEmail.link?.length === 0) {
      toastWarning("Se inserisci il titolo del pulsante devi inserire anche il link associato!")
    } else if (sendEmail.button?.length !== 0 && !isValidUrl(sendEmail.link)) {
      toastWarning("Il link inserito non è valido!")
    } else {
      guests.forEach(function (r: GuestRsvp) {
        sendEmail.guests.push(r.id)
      })
      const guestWithoutEmail = guests.filter((x) => x.rsvp.email === null).length
      if (guestWithoutEmail > 0) {
        const confirm = window.confirm(
          "Attenzione, " +
            guestWithoutEmail +
            " invitati che hai selezionato non hanno un'email associata. " +
            "Vuoi inviarla comunque agli invitati che ne hanno una valida?"
        )
        if (confirm) sendEmailApi(tenant, sendEmail).then(() => hideModal())
      } else sendEmailApi(tenant, sendEmail).then(() => hideModal())
    }
  }

  return (
    <Modal show={show} onHide={() => hideModal()} centered={true}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Modal.Title>Invia un messaggio</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "30px", maxHeight: "600px", overflowY: "auto" }}>
        <>
          Fai sapere qualcosa di importante ai tuoi invitati.
          <br />
          Il messaggio verrà inviato ai soli invitati che ti hanno fornito l&#39;email durante la procedura RSVP.
          <label style={{ marginTop: "20px" }} className="form-label">
            Oggetto
          </label>
          <input
            className="form-input"
            type="text"
            placeholder="Matrimonio Mario e Lucia"
            onChange={(e) => setSendEmail({ ...sendEmail, subject: e !== null ? e.currentTarget.value : "" })}
          />
          <label style={{ marginTop: "10px" }} className="form-label">
            Titolo
          </label>
          <input
            className="form-input"
            type="text"
            placeholder="E' arrivato il grande giorno!!!"
            onChange={(e) => setSendEmail({ ...sendEmail, title: e !== null ? e.currentTarget.value : "" })}
          />
          <label style={{ marginTop: "10px" }} className="form-label">
            Messaggio
          </label>
          <textarea
            className="form-input"
            style={{ height: "250px" }}
            placeholder="Ciao, non vediamo l'ora di festeggiare con voi il nostro grande giorno."
            onChange={(e) => setSendEmail({ ...sendEmail, message: e !== null ? e.currentTarget.value : "" })}
          />
          <div
            className="form-label"
            onClick={() => setAdvancedOptions(!advancedOptions)}
            style={{ cursor: "pointer", marginTop: "20px" }}
          >
            <u>Opzioni avanzate</u>
          </div>
          {advancedOptions ? (
            <>
              <div style={{ marginTop: "10px" }}>
                Se lo desideri puoi inserire nella mail un pulsante con relativo link associato (per esempio il sito web
                del tuo matrimonio).
              </div>
              <label style={{ marginTop: "20px" }} className="form-label">
                Titolo del pulsante
              </label>
              <input
                className="form-input"
                type="text"
                placeholder="Visita il nostro sito web"
                onChange={(e) => setSendEmail({ ...sendEmail, button: e !== null ? e.currentTarget.value : "" })}
              />
              <label style={{ marginTop: "10px" }} className="form-label">
                Link da visitare
              </label>
              <input
                className="form-input"
                type="text"
                placeholder="https://sethsummer.wamoo.app/"
                onChange={(e) => setSendEmail({ ...sendEmail, link: e !== null ? e.currentTarget.value : "" })}
              />
            </>
          ) : null}
          <div style={{ marginTop: "20px" }}>Hai selezionato {guests?.length} invitati</div>
          <button
            type="button"
            className="btn btn-primary button-style"
            style={{ marginTop: "20px" }}
            onClick={sendEmailAsync}
          >
            Invia messaggio
          </button>
        </>
      </Modal.Body>
    </Modal>
  )
}
