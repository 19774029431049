import { Tooltip } from "bootstrap"
import React, { useEffect, useRef } from "react"

interface CustomTooltipProps {
  children: JSX.Element
  text: string
  show: boolean
}
export const CustomTooltip = ({ children, text, show }: CustomTooltipProps) => {
  const childRef = useRef(undefined as unknown as Element)

  useEffect(() => {
    const t = new Tooltip(childRef.current, {
      title: text,
      placement: "right",
      trigger: "hover",
    })
    if (show) return () => t.dispose()
    else return t.disable()
  }, [show, text])

  return React.cloneElement(children, { ref: childRef })
}
