import { WeddingInfo } from "../../../models/weddingApiTypes"
import counteInfoIcon from "../../../icon/counter_info_icon.svg"

interface WeddingCounterInfoInvitationsProps {
  wedding: WeddingInfo | undefined
}

export default function WeddingCounterInfoInvitations({ wedding }: WeddingCounterInfoInvitationsProps) {
  return (
    <div style={{ height: "100%", padding: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}>
      <img alt="counteInfoIcon" src={counteInfoIcon} style={{ marginRight: "24px" }} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ color: "#718096", fontWeight: "700", fontSize: "12px" }}>Totale Inviti</div>
        <div style={{ color: "#16192C", fontWeight: "600", fontSize: "20px" }}>
          {wedding?.counters.invitations.counters.confirmed}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", marginLeft: "24px" }}>
        <div style={{ color: "#718096", fontWeight: "700", fontSize: "12px" }}>Inviti Bozza</div>
        <div style={{ color: "#16192C", fontWeight: "600", fontSize: "20px" }}>
          {wedding?.counters.invitations.counters.draft}
        </div>
      </div>
    </div>
  )
}
