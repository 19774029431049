import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { WeddingInfo } from "../models/weddingApiTypes"
import { getWeddingAPI } from "../api/weddingApi"
import HeaderBar from "../components/HeaderBar"
import WeddingBudgetTile from "../components/dashboard/components/WeddingBudgetTile"
import ImportFile from "../components/dashboard/components/ImportFile"
import Sidebar from "../components/Sidebar"
import MenuIcon from "../components/MenuIcon"
import BigTile from "../components/tile/BigTile"
import TileHeader from "../components/tile/TileHeader"
import invitaionLogo from "../icon/invitation_dash_icon.svg"
import budgetLogo from "../icon/budget_dash_icon.svg"
import addInvitationIcon from "../icon/add_icon.svg"
import WeddingEventRecapTile from "../components/dashboard/components/WeddingEventRecapTile"
import WeddingInvitationsRecapTile from "../components/dashboard/components/WeddingInvitationsRecapTile"
import WeddingImportInvitationsTile from "../components/dashboard/components/WeddingImportInvitationsTile"
import WeddingCountersInfoTile from "../components/dashboard/components/WeddingCountersInfoTile"
import WeddingGuestTypeCountersTile from "../components/dashboard/components/WeddingGuestTypeCountersTile"
import WeddingGuestGroupCountersTile from "../components/dashboard/components/WeddingGuestGroupCountersTile"

interface DashboardProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function Dashboard({ sidebar = true, showSidebar }: DashboardProps) {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [wedding, setWedding] = useState<WeddingInfo | undefined>()
  const { tenant } = useParams<string>()

  useEffect(() => {
    tenant &&
      getWeddingAPI(tenant)
        .then((result) => setWedding(result))
        .catch(function (error) {
          if (error.response) {
            if (error.response.data?.Exception === "wedding.notExists") navigate(`/${tenant}/wedding-provisioning`)
          }
        })
  }, [navigate, tenant])

  return (
    <>
      {wedding && (
        <div className="wrapper">
          <Sidebar sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
          <div id="content">
            <HeaderBar
              title={
                <>
                  <MenuIcon sidebar={sidebar} showSidebar={showSidebar} />
                  Bentornati, {wedding?.nameFirstOwner} e {wedding?.nameSecondOwner}!
                </>
              }
            >
              <button
                type="button"
                className="btn btn-info button-style"
                style={{ marginRight: "10px" }}
                onClick={() => setShow(true)}
              >
                <img alt="addInvitationIcon" src={addInvitationIcon} style={{ marginRight: "10px" }} />
                Importa inviti
              </button>
              <button
                type="button"
                className="btn btn-primary button-style"
                onClick={() => navigate(`/${tenant}/invitation/add`)}
              >
                <img alt="addInvitationIcon" src={addInvitationIcon} style={{ marginRight: "10px" }} />
                Aggiungi Invito
              </button>
            </HeaderBar>
            <div>
              <ImportFile showModal={show} setShowModal={(newShow) => setShow(newShow)} />
              <div className="container">
                {wedding.counters.guests.counters.totals === 0 ? (
                  <div className="row">
                    <div className="col-sm-12, col-md-6 col-lg-4" style={{ paddingBottom: "24px" }}>
                      <BigTile backgroundColor="#4C6FFF">
                        <WeddingEventRecapTile wedding={wedding} />
                      </BigTile>
                    </div>
                    <div className="col-sm-12, col-md-6 col-lg-4" style={{ paddingBottom: "24px" }}>
                      <BigTile
                        header={
                          <TileHeader avatar={<img alt="InvitaionLogo" src={invitaionLogo} />} title="Invitati" />
                        }
                      >
                        <WeddingImportInvitationsTile
                          setShow={setShow}
                          navigateToAddInvitation={() => navigate(`/${tenant}/invitation/add`)}
                        />
                      </BigTile>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-sm-12, col-md-6 col-lg-4" style={{ paddingBottom: "24px" }}>
                        <BigTile backgroundColor="#4C6FFF">
                          <WeddingEventRecapTile wedding={wedding} />
                        </BigTile>
                      </div>
                      <div className="col-sm-12, col-md-6 col-lg-4" style={{ paddingBottom: "24px" }}>
                        <BigTile
                          header={<TileHeader avatar={<img alt="BudgetLogo" src={budgetLogo} />} title="Budget" />}
                        >
                          <WeddingBudgetTile wedding={wedding} />
                        </BigTile>
                      </div>
                      <div className="col-sm-12, col-md-6 col-lg-4" style={{ marginBottom: "24px" }}>
                        <WeddingCountersInfoTile wedding={wedding} />
                      </div>
                    </div>
                    <div className="row" style={{ backgroundColor: "#FFFFFF", borderRadius: "20px", margin: "0px" }}>
                      <div
                        className="col-sm-12, col-md-6 col-lg-4"
                        style={{ paddingBottom: "24px", marginTop: "24px" }}
                      >
                        <BigTile
                          header={
                            <TileHeader avatar={<img alt="InvitaionLogo" src={invitaionLogo} />} title="Invitati" />
                          }
                        >
                          <WeddingInvitationsRecapTile wedding={wedding} />
                        </BigTile>
                      </div>
                      <div
                        className="col-sm-12, col-md-6 col-lg-4"
                        style={{ paddingBottom: "24px", marginTop: "24px" }}
                      >
                        <BigTile backgroundColor="#F7FAFC">
                          <WeddingGuestTypeCountersTile wedding={wedding} />
                        </BigTile>
                      </div>
                      <div
                        className="col-sm-12, col-md-6 col-lg-4"
                        style={{ paddingBottom: "24px", marginTop: "24px" }}
                      >
                        <BigTile backgroundColor="#F7FAFC">
                          <WeddingGuestGroupCountersTile wedding={wedding} />
                        </BigTile>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
