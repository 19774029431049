import { useEffect, useMemo, useState } from "react"
import { GuestRsvp, GuestRsvpRaw } from "../../models/guestRsvpApiTypes"
import { getGuestRsvpAPI } from "../../api/guestRsvp"
import { GuestBusStatus, GuestStatus } from "../../models/invitationsApiTypes"
import { RsvpSettings } from "../../models/weddingApiTypes"
import { getWeddingsRsvpSettingAPI } from "../../api/weddingApi"
import { GuestGroupDetailsData } from "../../models/guestGroupApiTypes"
import { useLocation } from "react-router-dom"
import Select from "react-select"
import CustomTable from "./../CustomTable"
import Intolerances from "./Intolerances"
import RsvpStatusCell from "./RsvpStatusCell"
import "../../style/custom_style.css"

enum TABLE_HEADER {
  ID = "ID",
  NAME = "NOME",
  SURNAME = "COGNOME",
  TYPE = "TIPO",
  GROUP = "GRUPPO",
  RSVP = "RSVP",
  BUS = "BUS",
  MAIL = "INDIRIZZO MAIL",
  INTOLERANCES = "INTOLLERANZE",
  ARRIVAL_DATE = "DATA ARRIVO",
  RSVPDATE = "DATA RSVP",
}

interface SelectOptions {
  value: string
  label: string
}

interface RowDateProps {
  date: Date | undefined
  isArrivalDate: boolean
}

const ResponseDate = ({ date, isArrivalDate }: RowDateProps) => {
  if (date !== null && date !== undefined) {
    const dateFormatted = new Date(date).toLocaleDateString("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    const hourFormatted = new Date(date).toLocaleTimeString("it-IT", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    return (
      <div style={{ fontSize: "12.5px" }}>
        <div>{dateFormatted}</div>
        {isArrivalDate === false ? <div>{hourFormatted}</div> : null}
      </div>
    )
  } else return null
}

interface RsvpInfoProps {
  tenant: string | undefined
  setSelectedRows: (rows: GuestRsvp[]) => void
}

export default function RsvpInfo({ tenant, setSelectedRows }: RsvpInfoProps) {
  const [actualPage, setActualPage] = useState(0)
  const [rsvpinfo, setRsvpInfo] = useState<GuestRsvp[]>()
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([])
  const search = useLocation().search
  const guestType = new URLSearchParams(search).get("guestType") ?? undefined
  const guestGroup = new URLSearchParams(search).get("guestGroup") ?? undefined
  const rsvp = new URLSearchParams(search).get("rsvp") ?? undefined

  useEffect(() => {
    getWeddingsRsvpSettingAPI(tenant).then((result: RsvpSettings) => {
      const hiddenColumns = ["surname"]
      if (!result.weddingRsvpSettings.showIntolerances) hiddenColumns.push("rsvp.intolerances")
      if (!result.weddingRsvpSettings.showBusTransferNeeded) hiddenColumns.push("rsvp.busTransferNeeded")
      if (!result.weddingRsvpSettings.showArrivalDate) hiddenColumns.push("rsvp.arrivalDate")
      if (!result.weddingRsvpSettings.showEmail) hiddenColumns.push("rsvp.email")
      setHiddenColumns(hiddenColumns)
    })
    getGuestRsvpAPI(tenant)
      .then((result) => {
        setDefaultGuestGroup(result)
        const guests: GuestRsvp[] = []
        result.forEach((res: GuestRsvpRaw) => {
          guests.push({
            id: res.id,
            name: res.name,
            surname: res.surname,
            status:
              res.status === "Invited"
                ? GuestStatus.INVITED
                : res.status === "Accepted"
                ? GuestStatus.ACCEPTED
                : res.status === "Declined"
                ? GuestStatus.DECLINED
                : res.status === "Present"
                ? GuestStatus.PRESENT
                : res.status === "Draft"
                ? GuestStatus.DRAFT
                : GuestStatus.NONE,
            type: res.type,
            group: res.group,
            rsvpSettings: res.rsvpSettings,
            rsvp: {
              accepted: res.rsvp.accepted,
              arrivalDate: res.rsvp.arrivalDate,
              email: res.rsvp.email,
              intolerances: res.rsvp.intolerances,
              rsvpDate: res.rsvp.rsvpDate,
              busTransferNeeded:
                res.rsvp.busTransferNeeded === null
                  ? GuestBusStatus.NULL
                  : res.rsvp.busTransferNeeded === true
                  ? GuestBusStatus.ACCEPTED
                  : GuestBusStatus.DECLINED,
            },
          })
        })
        setRsvpInfo([...guests])
      })
      .catch((e) => console.error(e))
  }, [tenant])

  const setDefaultGuestGroup = (guests: GuestRsvp[]) => {
    guests.forEach((guest) => {
      if (guest.group === null) {
        const defaultGroup: GuestGroupDetailsData = {
          id: "",
          name: "Senza gruppo",
          description: "",
        }
        guest.group = defaultGroup
      }
    })
  }

  const SelectColumnFilter = (columns: { column: any }) => {
    const customStyles = {
      container: (provided: any) => ({
        ...provided,
        width: "100%",
      }),
      control: (provided: any) => ({
        ...provided,
        border: "1px solid #ebf2fa",
        borderRadius: 10,
        boxShadow: "none",
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        borderRadius: 16,
        color: state.selectProps.menuColor,
        padding: 8,
        fontSize: 12,
      }),
      option: (provided: any) => ({
        ...provided,
        borderRadius: 10,
        fontWeight: 100,
        letterSpacing: 0,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        height: 40,
      }),
      singleValue: (provided: any) => ({
        ...provided,
        fontSize: 12,
        fontWeight: 100,
        letterSpacing: 0,
      }),
      placeholder: (provided: any) => ({
        ...provided,
        fontSize: 12,
        fontWeight: 100,
        letterSpacing: 0,
        color: "#b5b5bd",
      }),
    }
    const options: any = useMemo(() => {
      const sets = new Set()
      columns.column.preFilteredRows.forEach((row: any) => {
        sets.add(row.values[columns.column.id])
      })
      return [...sets.values()]
    }, [columns.column.id, columns.column.preFilteredRows])

    const selectOptions: SelectOptions[] = []
    options.forEach(function (option: string) {
      const selectOption: SelectOptions = {
        value: option,
        label: option,
      }
      selectOptions.push(selectOption)
    })

    return (
      <Select
        isClearable={true}
        styles={customStyles}
        options={selectOptions}
        value={selectOptions.find((e) => e.value === columns.column.filterValue) ?? null}
        onChange={(e) => {
          columns.column.setFilter(e !== null ? e.value : "")
        }}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: TABLE_HEADER.NAME,
        accessor: "name",
        Cell: (props: { row: { original: GuestRsvp } }) => (
          <div style={{ fontWeight: "bold" }}>
            {props.row.original.name} {props.row.original.surname}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.SURNAME,
        accessor: "surname",
        Cell: (props: { row: { original: GuestRsvp } }) => (
          <div style={{ fontWeight: "bold" }}>{props.row.original.surname}</div>
        ),
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.TYPE,
        accessor: "type.name",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.GROUP,
        accessor: "group.name",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.RSVP,
        accessor: "status",
        Cell: (row: { cell: { value: string } }) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {row.cell.value !== null && row.cell.value === GuestStatus.INVITED ? (
              <RsvpStatusCell backgroundColor="#ffeed6" color="#85646c" text={GuestStatus.INVITED} />
            ) : row.cell.value === GuestStatus.ACCEPTED ? (
              <RsvpStatusCell backgroundColor="#d4edda" color="#155724" text={GuestStatus.ACCEPTED} />
            ) : row.cell.value === GuestStatus.DECLINED ? (
              <RsvpStatusCell backgroundColor="#f8d7da" color="#881c24" text={GuestStatus.DECLINED} />
            ) : row.cell.value === GuestStatus.DRAFT ? (
              <RsvpStatusCell backgroundColor="#e2e3e5" color="#383d53" text={GuestStatus.DRAFT} />
            ) : row.cell.value === GuestStatus.PRESENT ? (
              <RsvpStatusCell backgroundColor="#d1ecf1" color="#0c5460" text={GuestStatus.PRESENT} />
            ) : row.cell.value === GuestStatus.NONE ? (
              <RsvpStatusCell backgroundColor="#d6d8d9" color="#1b1e21" text={GuestStatus.NONE} />
            ) : null}
          </div>
        ),
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.BUS,
        accessor: "rsvp.busTransferNeeded",
        Cell: (row: { cell: { value: string } }) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {row.cell.value === GuestBusStatus.NULL ? (
              <RsvpStatusCell backgroundColor="#ffeed6" color="#85646c" text={GuestBusStatus.NULL} />
            ) : row.cell.value === GuestBusStatus.ACCEPTED ? (
              <RsvpStatusCell backgroundColor="#d4edda" color="#155724" text={GuestBusStatus.ACCEPTED} />
            ) : (
              <RsvpStatusCell backgroundColor="#f8d7da" color="#881c24" text={GuestBusStatus.DECLINED} />
            )}
          </div>
        ),
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.MAIL,
        accessor: "rsvp.email",
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.INTOLERANCES,
        accessor: "rsvp.intolerances",
        Cell: (row: { cell: { value: string[] } }) => <Intolerances intolerances={row.cell.value} />,
        disableSortBy: true,
      },
      {
        Header: TABLE_HEADER.ARRIVAL_DATE,
        accessor: "rsvp.arrivalDate",
        Cell: (row: { cell: { value: Date } }) => <ResponseDate date={row.cell.value} isArrivalDate={true} />,
        sortType: (a: any, b: any) => {
          if (a.original.rsvp.arrivalDate !== null || b.original.rsvp.arrivalDate !== null) {
            const date1 = new Date(a.original.rsvp.arrivalDate).getTime()
            const date2 = new Date(b.original.rsvp.arrivalDate).getTime()
            if (date1 < date2) return 1
            else if (date1 > date2) return -1
            else return 0
          }
        },
      },
      {
        Header: TABLE_HEADER.RSVPDATE,
        accessor: "rsvp.rsvpDate",
        Cell: (row: { cell: { value: Date } }) => <ResponseDate date={row.cell.value} isArrivalDate={false} />,
        sortType: (a: any, b: any) => {
          if (a.original.rsvp.rsvpDate !== null || b.original.rsvp.rsvpDate !== null) {
            const date1 = new Date(a.original.rsvp.rsvpDate).getTime()
            const date2 = new Date(b.original.rsvp.rsvpDate).getTime()
            if (date1 < date2) return 1
            else if (date1 > date2) return -1
            else return 0
          }
        },
      },
    ],
    []
  )

  const data = useMemo(() => rsvpinfo, [rsvpinfo])

  return (
    <>
      {data && hiddenColumns && hiddenColumns.length > 0 && (
        <CustomTable
          tableWidth="1600px"
          columns={columns}
          data={data}
          actualPage={actualPage}
          setActualPage={setActualPage}
          isColumnFilterEnabled={true}
          hiddenColumns={hiddenColumns}
          sortBy={[
            {
              id: "rsvp.rsvpDate",
              desc: false,
            },
          ]}
          filters={[
            guestType !== undefined
              ? {
                  id: "type.name",
                  value: guestType,
                }
              : {},
            guestGroup !== undefined
              ? {
                  id: "group.name",
                  value: guestGroup,
                }
              : {},
            rsvp !== undefined
              ? {
                  id: "status",
                  value: rsvp,
                }
              : {},
          ]}
          setSelectedRows={setSelectedRows}
        />
      )}
    </>
  )
}
