import { useState } from "react"
import { Dropdown } from "react-bootstrap"
import { TableType } from "../../../models/tableApiTypes"
import TableModal from "./TableModal"

interface TableCtaProps {
  tenant: string | undefined
}

export default function TableCta({ tenant }: TableCtaProps) {
  const [modalToShow, setModalToShow] = useState<TableType>()
  const [modalTitle, setModalTitle] = useState("")
  const [show, setShow] = useState(false)

  const onSelectHanlde = (e: any) => {
    if (e === "#/add-rect-table") {
      setModalToShow(TableType.TABLE_RECT)
      setModalTitle("Aggiungi un tavolo rettangolare")
    }
    if (e === "#/add-circle-table") {
      setModalToShow(TableType.TABLE_CIRCLE)
      setModalTitle("Aggiungi un tavolo rotondo")
    }
    setShow(true)
  }

  return (
    <>
      <Dropdown onSelect={onSelectHanlde}>
        <Dropdown.Toggle id="dropdown-basic" className="btn btn-info">
          Aggiungi tavolo
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <Dropdown.Item href="#/add-square-table">Tavolo quadrato</Dropdown.Item> */}
          <Dropdown.Item href="#/add-rect-table">Tavolo rettangolare</Dropdown.Item>
          <Dropdown.Item href="#/add-circle-table">Tavolo rotondo</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {modalToShow && (
        <TableModal show={show} showModal={setShow} title={modalTitle} modalToShow={modalToShow} tenant={tenant} />
      )}
    </>
  )
}
