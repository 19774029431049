import { PointerEvent, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../hook/useReduxHooks"
import { TableDetailsData } from "../../../models/tableApiTypes"
import { setToolPaletView, TOOL_PALET_VIEW_TYPE } from "../tableSlice"
import { RootState } from "../../../store"
import TableSeat from "./TableSeat"

const RX_BORDER = 5
const RY_BORDER = 5

interface TableRectProps {
  posX: number
  posY: number
  tableDetails: TableDetailsData
  isTableSelected: boolean
  handlePointerDown?: (event: PointerEvent<SVGElement>) => void
  handlePointerUp?: () => void
  handlePointerMove?: (event: PointerEvent<SVGElement>) => void
}

export default function TableRect({
  posX,
  posY,
  tableDetails,
  isTableSelected,
  handlePointerDown,
  handlePointerUp,
  handlePointerMove,
}: TableRectProps) {
  const [hover, setHover] = useState(false)
  const hideEmptyChair = useAppSelector((state: RootState) => state.table.hideEmptyChair)
  const dispatch = useAppDispatch()

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const handleClick = () => {
    dispatch(
      setToolPaletView({
        newToolPaletView: { toolPaletViewType: TOOL_PALET_VIEW_TYPE.TABLE, toolPaletTableData: tableDetails },
      })
    )
  }

  return (
    <>
      <g
        className="table_cursor"
        id="tableRect"
        transform={`translate(${posX}, ${posY}) rotate(${tableDetails.shape.rotationDegrees})`}
        onPointerDown={handlePointerDown !== undefined ? (event) => handlePointerDown(event) : undefined}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove !== undefined ? (event) => handlePointerMove(event) : undefined}
      >
        <rect
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onPointerDown={handleClick}
          style={
            isTableSelected
              ? { fill: "#e7e7e7", opacity: "100%", stroke: hover ? "#818181" : undefined }
              : {
                  fill: hover ? "#e7e7e7" : "#fafafa",
                  opacity: hover ? "100%" : "40%",
                  stroke: "#818181",
                  strokeWidth: "1",
                }
          }
          x={0}
          y={0}
          rx={RX_BORDER}
          ry={RY_BORDER}
          width={tableDetails.shape.width}
          height={tableDetails.shape.height}
        ></rect>
        <text
          className="table_name_text"
          style={{ fill: "#000000", fontSize: "1.5em", fontWeight: "bold" }}
          x={tableDetails.shape.width / 2}
          y={tableDetails.shape.height / 2}
          textAnchor={"middle"}
          dominantBaseline={"middle"}
        >
          {tableDetails.name}
        </text>
      </g>

      <g
        className="table_seat_cursor"
        transform={`translate(${posX}, ${posY}) rotate(${tableDetails.shape.rotationDegrees})`}
        onPointerDown={handlePointerDown !== undefined ? (event) => handlePointerDown(event) : undefined}
        onPointerUp={handlePointerUp}
      >
        {tableDetails.seats.map((seat, index) => {
          return (
            <>
              {hideEmptyChair && seat.guest === null ? null : (
                <TableSeat
                  key={index}
                  seatDetails={seat}
                  rotateDegreeNumberSeat={-tableDetails.shape.rotationDegrees}
                />
              )}
            </>
          )
        })}
      </g>
    </>
  )
}
