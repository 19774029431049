import { ReactNode } from "react"
import "../style/custom_style.css"

interface TileHeaderProps {
  handleButtonClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  avatar?: ReactNode
  title?: string
  color?: string
  fontWeight?: string
  width?: string
  backgroundColor?: string
  borderColor?: string
}

export default function CustomButton({
  handleButtonClick,
  avatar,
  title,
  color,
  fontWeight,
  width,
  backgroundColor,
  borderColor,
}: TileHeaderProps) {
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "flex", flexDirection: "row", justifyContent: "center", width, backgroundColor, borderColor }}
        onClick={handleButtonClick}
      >
        {avatar ? <div className="custom-button-image">{avatar}</div> : null}
        {title ? <div style={{ color, fontWeight }}>{title}</div> : null}
      </button>
    </div>
  )
}
