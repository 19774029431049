import addInvitationIcon from "../../../icon/add_icon.svg"

interface WeddingImportInvitationsTileProps {
  setShow: (show: boolean) => void
  navigateToAddInvitation: () => void
}

export default function WeddingImportInvitationsTile({
  setShow,
  navigateToAddInvitation,
}: WeddingImportInvitationsTileProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
        padding: "20px",
      }}
    >
      <div style={{ fontWeight: "600", fontSize: "28px", lineHeight: "30px" }}>Non c&apos;è festa senza invitati</div>
      <div style={{ fontWeight: "400", fontSize: "14px", color: "#425466", marginTop: "10px" }}>
        Aggiungi i tuoi amici e parenti per visualizzare statistiche e andamento budget.
      </div>
      <div style={{ justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
        <button type="button" className="btn btn-primary button-style" onClick={navigateToAddInvitation}>
          <img alt="addInvitationIcon" src={addInvitationIcon} style={{ marginRight: "10px" }} />
          Aggiungi Invito
        </button>
        <button
          type="button"
          className="btn btn-info button-style"
          style={{
            marginTop: "10px",
            width: "100%",
            paddingLeft: "0px",
          }}
          onClick={() => setShow(true)}
        >
          <img alt="addInvitationIcon" src={addInvitationIcon} style={{ marginRight: "10px" }} />
          Importa inviti
        </button>
      </div>
    </div>
  )
}
