import { useLocation, useNavigate, useParams } from "react-router-dom"
import HeaderBar from "../components/HeaderBar"
import InvitationsInfo from "../components/invitation/InvitationsInfo"
import addInvitationIcon from "../icon/add_icon.svg"
import downloadIcon from "../icon/download_icon.svg"
import { useEffect, useState } from "react"
import { getWeddingsRsvpEnabledSettingAPI, updateWeddingsRsvpEnabledSettingAPI } from "../api/weddingApi"
import { PatchRsvpEnabledSettings } from "../models/weddingApiTypes"
import { downloadInvitationsQrCodesFile } from "../api/invitationsApi"
import { CustomTooltip } from "../components/CustomTooltip"
import Sidebar from "../components/Sidebar"
import MenuIcon from "../components/MenuIcon"
import { QrCodeSettings } from "../models/invitationsApiTypes"

interface InvitationsProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function Invitations({ sidebar = true, showSidebar }: InvitationsProps) {
  const { tenant } = useParams<string>()
  const [rsvpEnabled, setRsvpEnabled] = useState<boolean>(false)
  const navigate = useNavigate()
  const search = useLocation().search
  const page = new URLSearchParams(search).get("page") ?? undefined

  useEffect(() => {
    getWeddingsRsvpEnabledSettingAPI(tenant)
      .then((result) => {
        setRsvpEnabled(result)
      })
      .catch(() => console.error("ERROR"))
  }, [tenant])

  const updateRsvpEnabled = async (newValue: boolean) => {
    const newRsvpEnabledValue: PatchRsvpEnabledSettings = {
      enabled: newValue,
    }
    updateWeddingsRsvpEnabledSettingAPI(tenant, newRsvpEnabledValue)
      .then(() => setRsvpEnabled(newValue))
      .catch(() => setRsvpEnabled(false))
  }

  const downloadQrCodesFile = async () => {
    const qrCodeSetting: QrCodeSettings = {
      /* numberOfCardsPreview: 9, */
      font: {
        fontColor: "#000000",
        fontFamily: "Poppins",
        fontSize: 10,
        letterSpacing: 0.1,
      },
      page: {
        isLandscape: false,
        margin: 20,
        heightMm: 320,
        widthMm: 450,
      },
      card: {
        widthMm: 120,
        heightMm: 85,
        paddingTopMm: 15,
        paddingBottomMm: 15,
        paddingRightMm: 15,
        paddingLeftMm: 15,
        background: "#ffffff",
        description: "INQUADRA IL QRCODE PER CONFERMARE LA PRESENZA O VISITA IL NOSTRO SITO WEB.",
        layoutSingleColumn: false,
        showQrCodeLogo: false,
        qrCodeDarkColor: {
          alpha: 255,
          red: 0,
          green: 0,
          blue: 0,
        },
      },
    }
    downloadInvitationsQrCodesFile(tenant, qrCodeSetting).then((response) => {
      const url = window.URL.createObjectURL(response)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      a.download = "inviations_qrcodes.pdf"
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  return (
    <>
      <div className="wrapper">
        <Sidebar sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
        <div id="content">
          <HeaderBar
            title={
              <>
                <MenuIcon sidebar={sidebar} showSidebar={showSidebar} />
                Lista Inviti
              </>
            }
          >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <label className="toggle-form-label" style={{ paddingRight: "10px" }}>
                Abilita RSVP
              </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={rsvpEnabled}
                  onChange={(e) => updateRsvpEnabled(e.target.checked)}
                ></input>
              </div>
            </div>
            <CustomTooltip text="Scarica i QR code da consegnare ai tuoi invitati." show={true}>
              <button
                type="button"
                className="btn btn-primary button-style"
                style={{ marginRight: "10px" }}
                onClick={downloadQrCodesFile}
              >
                <img alt="downloadIcon" src={downloadIcon} style={{ marginRight: "10px" }} />
                QR Code
              </button>
            </CustomTooltip>
            <button
              type="button"
              className="btn btn-primary button-style"
              onClick={() => navigate(`/${tenant}/invitation/add`)}
            >
              <img alt="addInvitationIcon" src={addInvitationIcon} style={{ marginRight: "10px" }} />
              Aggiungi Invito
            </button>
          </HeaderBar>
          <div className="container">
            <InvitationsInfo tenant={tenant} page={page} />
          </div>
        </div>
      </div>
    </>
  )
}
