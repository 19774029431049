import { useEffect, useState } from "react"
import { downloadAlbumPhotoQrCodesFile, getWeddingAPI, updateWeddingAPI } from "../../api/weddingApi"
import { QrCodeSettings, UpdateWedding, WeddingInfo } from "../../models/weddingApiTypes"
import { toastSuccess, toastWarning } from "../../utils/toastMethod"
import { CustomTooltip } from "./../CustomTooltip"
import DatePicker from "react-datepicker"
import downloadIcon from "../../icon/download_icon_color.svg"

interface SettingsGeneralProps {
  tenant: string | undefined
}

export default function SettingsGeneral({ tenant }: SettingsGeneralProps) {
  const [weddingInfo, setWeddingInfo] = useState<WeddingInfo | undefined>()

  useEffect(() => {
    tenant &&
      getWeddingAPI(tenant)
        .then((result) => {
          setWeddingInfo(result)
        })
        .catch(() => console.error("ERROR"))
  }, [tenant])

  const updateWeddingInfo = () => {
    if (weddingInfo !== undefined) {
      const updateWedding: UpdateWedding = {
        nameFirstOwner: weddingInfo.nameFirstOwner,
        surnameFirstOwner: weddingInfo.surnameFirstOwner,
        nameSecondOwner: weddingInfo.nameSecondOwner,
        surnameSecondOwner: weddingInfo.surnameSecondOwner,
        location: weddingInfo.location,
        weddingDate: weddingInfo.weddingDate,
        budget: weddingInfo.counters.budget.budgeted,
        photosAlbumUrl: weddingInfo.photosAlbumUrl,
      }
      updateWeddingAPI(tenant, updateWedding)
        .then(() => toastSuccess("Informazioni aggiornate."))
        .catch(() => toastWarning("Problemi durante l'aggiornamento. Riprova!"))
    }
  }

  const isValidUrl = (url: string | undefined) => {
    if (url === undefined) return false
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    )
    return pattern.test(url)
  }

  const downloadPhotosQrCode = () => {
    if (!isValidUrl(weddingInfo?.photosAlbumUrl)) {
      toastWarning("Il link che hai inserito non è valido!")
    } else {
      const qrCodeSetting: QrCodeSettings = {
        showQrCodeLogo: false,
        qrCodeDarkColor: {
          alpha: 255,
          red: 0,
          green: 0,
          blue: 0,
        },
        qrCodeLightColor: {
          alpha: 255,
          red: 255,
          green: 255,
          blue: 255,
        },
      }
      downloadAlbumPhotoQrCodesFile(tenant, qrCodeSetting).then((response) => {
        const url = window.URL.createObjectURL(response)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = "qrcodes_photos.zip"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
    }
  }

  return (
    <>
      {weddingInfo && (
        <div className="container form-container">
          <div className="row" style={{ marginBottom: "25px" }}>
            <div className="col">
              <label className="form-label">Nome sposo/a</label>
              <input
                className="form-input"
                type="text"
                style={{ marginBottom: "5px" }}
                placeholder="Mario"
                value={weddingInfo?.nameFirstOwner}
                onChange={(e) => setWeddingInfo({ ...weddingInfo, nameFirstOwner: e.currentTarget.value })}
              ></input>
              <label className="form-label">Cognome sposo/a</label>
              <input
                className="form-input"
                type="text"
                placeholder="Mario"
                value={weddingInfo?.surnameFirstOwner}
                onChange={(e) => setWeddingInfo({ ...weddingInfo, surnameFirstOwner: e.currentTarget.value })}
              ></input>
            </div>
            <div className="col">
              <label className="form-label">Nome sposo/a</label>
              <input
                className="form-input"
                type="text"
                style={{ marginBottom: "5px" }}
                placeholder="Mario"
                value={weddingInfo?.nameSecondOwner}
                onChange={(e) => setWeddingInfo({ ...weddingInfo, nameSecondOwner: e.currentTarget.value })}
              ></input>
              <label className="form-label">Cognome sposo/a</label>
              <input
                className="form-input"
                type="text"
                placeholder="Mario"
                value={weddingInfo?.surnameSecondOwner}
                onChange={(e) => setWeddingInfo({ ...weddingInfo, surnameSecondOwner: e.currentTarget.value })}
              ></input>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "25px" }}>
            <div className="col">
              <label className="form-label">Location</label>
              <input
                className="form-input"
                type="text"
                placeholder="Mario"
                value={weddingInfo?.location}
                onChange={(e) => setWeddingInfo({ ...weddingInfo, location: e.currentTarget.value })}
              ></input>
            </div>
            <div className="col">
              <label className="form-label">Data</label>
              <DatePicker
                className="form-input"
                selected={weddingInfo?.weddingDate ? new Date(weddingInfo?.weddingDate) : new Date()}
                dateFormat="dd MM yyyy"
                onChange={(date: Date) => setWeddingInfo({ ...weddingInfo, weddingDate: date })}
              />
            </div>
            <div className="col">
              <label className="form-label">Budget</label>
              <input
                className="form-input"
                type="number"
                placeholder="10000"
                value={weddingInfo?.counters.budget.budgeted}
                onChange={(e) =>
                  setWeddingInfo({
                    ...weddingInfo,
                    counters: {
                      ...weddingInfo.counters,
                      budget: {
                        ...weddingInfo.counters.budget,
                        budgeted: parseInt(e.currentTarget.value.length > 0 ? e.currentTarget.value : "1", 10),
                      },
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row" style={{ marginBottom: "25px" }}>
            <div className="col">
              <label className="form-label">Link album foto</label>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  className="form-input"
                  style={{ marginRight: "10px" }}
                  type="text"
                  placeholder="https://drive.google.com/"
                  value={weddingInfo?.photosAlbumUrl}
                  onChange={(e) => setWeddingInfo({ ...weddingInfo, photosAlbumUrl: e.currentTarget.value })}
                />
                <>
                  <CustomTooltip text="Scarica QR Code album foto" show={true}>
                    <div
                      style={{
                        height: "auto",
                        width: "50px",
                        border: "1px solid #ebf2fa",
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img alt="downloadIcon" src={downloadIcon} onClick={downloadPhotosQrCode} />
                    </div>
                  </CustomTooltip>
                </>
              </div>
              <div style={{ fontSize: "12px", opacity: "70%", marginTop: "3px" }}>
                Ricorda che il QR Code dell&apos;album foto sarà valido soltanto il giorno del tuo matrimonio
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                onClick={updateWeddingInfo}
              >
                Aggiorna informazioni
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
