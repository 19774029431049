import SettingsInfo from "../components/settings/SettingsInfo"
import { useParams } from "react-router-dom"
import HeaderBar from "../components/HeaderBar"
import { Nav, Navbar } from "react-bootstrap"
import { useState } from "react"
import Sidebar from "../components/Sidebar"
import MenuIcon from "../components/MenuIcon"

export enum SETTING_ITEM {
  GENERALS = "#generals",
  RSVP = "#rsvp",
  TYPES = "#types",
  GROUPS = "#groups",
  CONSTRIBUTORS = "#contributors",
}

interface SettingsProps {
  sidebar: boolean
  showSidebar: (value: boolean) => void
}

export default function Settings({ sidebar = true, showSidebar }: SettingsProps) {
  const { tenant } = useParams<string>()
  const [selectedSetting, setSelectedSetting] = useState<SETTING_ITEM>(SETTING_ITEM.GENERALS)

  return (
    <>
      <div className="wrapper">
        <Sidebar sidebar={sidebar} showSidebar={(value) => showSidebar(value)} />
        <div id="content">
          <HeaderBar
            title={
              <>
                <MenuIcon sidebar={sidebar} showSidebar={showSidebar} />
                Informazioni del matrimonio
              </>
            }
          />

          <Navbar bg="white" expand="lg" collapseOnSelect={true}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href={SETTING_ITEM.GENERALS} onClick={() => setSelectedSetting(SETTING_ITEM.GENERALS)}>
                  Generali
                </Nav.Link>
                <Nav.Link href={SETTING_ITEM.RSVP} onClick={() => setSelectedSetting(SETTING_ITEM.RSVP)}>
                  RSVP
                </Nav.Link>
                <Nav.Link href={SETTING_ITEM.TYPES} onClick={() => setSelectedSetting(SETTING_ITEM.TYPES)}>
                  Tipi di invitato
                </Nav.Link>
                <Nav.Link href={SETTING_ITEM.GROUPS} onClick={() => setSelectedSetting(SETTING_ITEM.GROUPS)}>
                  Gruppi
                </Nav.Link>
                <Nav.Link
                  href={SETTING_ITEM.CONSTRIBUTORS}
                  onClick={() => setSelectedSetting(SETTING_ITEM.CONSTRIBUTORS)}
                >
                  Collaboratori
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="container">
            <SettingsInfo tenant={tenant} setting={selectedSetting} />
          </div>
        </div>
      </div>
    </>
  )
}
