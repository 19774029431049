import { useEffect, useState } from "react"
import { getTableSeatGuestsAPI, updateSeatGuestTableAPI } from "../../../api/tableApi"
import { useAppDispatch, useAppSelector } from "../../../hook/useReduxHooks"
import { GuestData, SeatDetailsData } from "../../../models/tableApiTypes"
import { RootState } from "../../../store"
import { updateTableSeatGuest } from "../tableSlice"

interface ToolPaletSeatGuestListProps {
  tenant: string | undefined
  seatDetails: SeatDetailsData
}

export default function ToolPaletSeatGuestList({ tenant, seatDetails }: ToolPaletSeatGuestListProps) {
  const [guests, setGuests] = useState<GuestData[]>([])
  const [guestsFiltered, setGuestsFiltered] = useState<GuestData[]>([])
  const [updateGuestList, setUpdateGuestList] = useState<boolean>(false)
  const [serchBox, setSerchBox] = useState("")
  const tablesLayout = useAppSelector((state: RootState) => state.table.layout)
  const dispatch = useAppDispatch()

  useEffect(() => {
    tenant &&
      getTableSeatGuestsAPI(tenant, tablesLayout.id).then((guests) => {
        setGuests(guests)
        setGuestsFiltered(guests)
      })
  }, [tablesLayout.id, tenant, updateGuestList])

  const hanldeClick = (guestId: string) => {
    const guestToSeat = guests.find((guest) => guest.id === guestId)
    if (guestToSeat !== undefined) {
      updateSeatGuestTableAPI(tenant, guestToSeat.id, seatDetails.id).then(() => {
        setUpdateGuestList((update) => !update)
        dispatch(
          updateTableSeatGuest({
            guest: guestToSeat,
            seatDetails,
            tableShapeId: seatDetails.shape.parentShapeId,
            tableSeatId: seatDetails.id,
          })
        )
      })
    }
  }

  const filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value
    if (keyword !== "") {
      const filteredGuest = guests.filter((guest) => {
        return guest.name.toLowerCase().startsWith(keyword.toLowerCase())
      })
      setGuestsFiltered(filteredGuest)
    } else {
      setGuestsFiltered(guests)
    }
    setSerchBox(keyword)
  }

  return (
    <>
      <div style={{ marginBottom: "20px" }}>Posto numero {seatDetails.order}</div>
      <div style={{ marginBottom: "20px" }}>
        Posto libero, scegli un invitato tra i {guests.length} disponibili nella lista sotto.
      </div>

      <div style={{ border: "1px solid #e7e7e7", borderRadius: "4px" }}>
        <div style={{ padding: "10px" }}>
          <input className="form-input" type="search" placeholder="Cerca" value={serchBox} onChange={filter}></input>
        </div>
        {guestsFiltered && guestsFiltered.length > 0 ? (
          <div style={{ padding: "0px 10px 10px", overflowY: "auto", maxHeight: "600px" }}>
            {guestsFiltered.map((guest, index) => {
              return (
                <div
                  key={index}
                  style={{ marginBottom: "10px", cursor: "pointer", backgroundColor: "white" }}
                  onClick={() => hanldeClick(guest.id)}
                >
                  <div style={{ padding: "8px", display: "flex", flexDirection: "column" }}>
                    <div>
                      {guest.name} {guest.surname}
                    </div>
                    <small style={{ opacity: "55%" }}>
                      {guest.type} (Invito: {guest.invitation})
                    </small>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div style={{ padding: "10px" }}>Nessun elemento trovato!</div>
        )}
      </div>
    </>
  )
}
