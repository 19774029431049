import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getWeddingsRolesAPI } from "../api/weddingApi"
import { logout } from "../components/auth/authSlice"
import { WeddingRolesDetails } from "../models/weddingApiTypes"
import { toastWarning } from "../utils/toastMethod"

export default function WeddingsRoles() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [weddingsRoles, setWeddingsRoles] = useState<WeddingRolesDetails[] | undefined>()

  useEffect(() => {
    getWeddingsRolesAPI()
      .then((result) => {
        setWeddingsRoles(result)
        if (result.length === 1) {
          navigate(`/${result[0].weddingIdentifier}/dashboard`)
        }
      })
      .catch(() => toastWarning("Errore nel carimento dei ruoli. Riprova!"))
  }, [navigate])

  return (
    <>
      {weddingsRoles && weddingsRoles.length === 0 && (
        <div>
          <h1>Non hai un ruolo in nessun matrimonio!</h1>
          <button type="button" className="btn btn-primary" onClick={() => dispatch(logout())}>
            Log Out
          </button>
        </div>
      )}
      {weddingsRoles && weddingsRoles.length > 1 && (
        <div className="row">
          {weddingsRoles.map((wedding, index) => (
            <div
              key={index}
              className="card col"
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                borderRadius: "20px",
                height: "200px",
                margin: "20px",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/${wedding.weddingIdentifier}/dashboard`)}
            >
              <div className="card-body">
                <h5 className="card-title welcome-message" style={{ justifyContent: "center" }}>
                  {wedding.weddingIdentifier}
                </h5>
                <h6 className="card-subtitle mb-2 text-muted" style={{ textAlign: "center", padding: "12px" }}>
                  {wedding.role}
                </h6>
                <div className="card-text" style={{ display: "flex", justifyContent: "center" }}>
                  {wedding.nameFirstOwner && wedding.nameSecondOwner && (
                    <h6>
                      {wedding.nameFirstOwner} e {wedding.nameSecondOwner}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
