/* eslint-disable react/jsx-key */
import {
  HeaderProps,
  TableToggleCommonProps,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table"
import { useEffect, useState } from "react"
import "../style/table_style.css"
import TableCheckBox from "./TableCheckBox"

interface GlobalFilterProps {
  globalFilter: any
  setGlobalFilter: any
}

const GlobalFilter = ({ globalFilter, setGlobalFilter }: GlobalFilterProps) => {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 150)

  return (
    <input
      className="provisioning-form-input"
      style={{ width: "30%" }}
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={"Cerca"}
    />
  )
}

interface CustomTableProps {
  tableWidth: string
  columns: any
  data: any
  actualPage: number
  setActualPage: (page: number) => void
  isColumnFilterEnabled: boolean
  hiddenColumns: string[]
  sortBy: any
  filters: any
  setSelectedRows?: (rows: any) => void
}

export default function CustomTable({
  tableWidth,
  columns,
  data,
  actualPage,
  setActualPage,
  isColumnFilterEnabled,
  hiddenColumns,
  sortBy,
  filters,
  setSelectedRows,
}: CustomTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, globalFilter, pageSize /* , selectedRowIds */ },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: actualPage,
        hiddenColumns,
        sortBy,
        filters,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (setSelectedRows !== undefined) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            // eslint-disable-next-line @typescript-eslint/ban-types
            Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<{}>) => (
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: { row: { getToggleRowSelectedProps: () => TableToggleCommonProps } }) => (
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            ),
            maxWidth: 10,
            width: 10,
          },
          ...columns,
        ])
      }
    }
  )

  useEffect(() => {
    const selectedRows: any[] = []
    selectedFlatRows.forEach(function (row: any) {
      selectedRows.push(row.original)
    })
    if (setSelectedRows !== undefined) setSelectedRows(selectedRows)
  }, [selectedFlatRows, setSelectedRows])

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "row", paddingBottom: "20px" }}
        onChange={() => {
          gotoPage(0)
          setActualPage(0)
        }}
      >
        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      </div>

      <div style={{ overflowX: "auto" }}>
        <table {...getTableProps()} style={{ width: tableWidth }}>
          <thead style={{ backgroundColor: "#fafafb" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="table-header-cell">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={
                      column.disableSortBy
                        ? { width: column.width, padding: "15px" }
                        : { width: column.width, padding: "15px", cursor: "pointer" }
                    }
                  >
                    <div style={{ display: "flex" }}>
                      {column.render("Header")}
                      {column.isSorted ? (
                        <div style={{ marginLeft: "auto" }}>{column.isSortedDesc ? "▲" : "▼"}</div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            {isColumnFilterEnabled &&
              headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="table-header-cell">
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        width: column.width,
                        padding: "5px",
                      }}
                    >
                      {column.filter ? column.render("Filter") : null}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ backgroundColor: "#ffffff" }}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="table-body-cell">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} style={{ padding: "15px" }}>
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <select
          className="pagination-select"
          style={{ marginLeft: "5px" }}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostra {pageSize}
            </option>
          ))}
        </select>
        <div className="pagination-element-filtered">
          {rows.length === 0 ? (
            <>0 elementi trovati</>
          ) : (
            <>
              {rows.length > pageSize ? (
                <>
                  {pageIndex * pageSize + 1}-{" "}
                  {pageIndex * pageSize + pageSize > rows.length ? rows.length : pageIndex * pageSize + pageSize} di{" "}
                  {rows.length}
                </>
              ) : (
                <>
                  1-{rows.length} di {rows.length}
                </>
              )}
            </>
          )}
        </div>
        <button
          className="btn btn-light"
          onClick={() => {
            gotoPage(0)
            setActualPage(0)
          }}
          disabled={!canPreviousPage}
        >
          <span>&#10094;</span>
          <span>&#10094;</span>
        </button>
        <button
          className="btn btn-light"
          onClick={() => {
            previousPage()
            setActualPage(pageIndex - 1)
          }}
          disabled={!canPreviousPage}
        >
          <span>&#10094;</span>
        </button>
        <button
          className="btn btn-light"
          style={{ marginLeft: "5px" }}
          onClick={() => {
            nextPage()
            setActualPage(pageIndex + 1)
          }}
          disabled={!canNextPage}
        >
          <span>&#10095;</span>
        </button>
        <button
          className="btn btn-light"
          style={{ marginLeft: "5px" }}
          onClick={() => {
            gotoPage(pageCount - 1)
            setActualPage(0)
          }}
          disabled={!canNextPage}
        >
          <span>&#10095;</span>
          <span>&#10095;</span>
        </button>
      </div>
    </>
  )
}
