import { useState } from "react"
import downloadIcon from "../../../icon/download_icon.svg"
import TableDownloadListModal from "./TableDownloadListModal"

interface TableDownloadListProps {
  tenant: string | undefined
}

export default function TableDownloadList({ tenant }: TableDownloadListProps) {
  const [show, setShow] = useState(false)

  return (
    <>
      <button
        type="button"
        className="btn btn-primary button-style"
        style={{ marginRight: "10px" }}
        onClick={() => setShow(true)}
      >
        <img alt="downloadIcon" src={downloadIcon} style={{ marginRight: "10px" }} />
        Lista tavoli
      </button>
      <TableDownloadListModal tenant={tenant} show={show} setShow={setShow} />
    </>
  )
}
