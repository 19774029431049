import { useState } from "react"
import { GuestRsvp } from "../../models/guestRsvpApiTypes"
import emailIcon from "../../icon/mail_icon.svg"
import RsvpSendEmailModal from "./RsvpSendEmailModal"
import { toastWarning } from "../../utils/toastMethod"

interface RsvpSendEmailCtaProps {
  tenant: string | undefined
  guests: GuestRsvp[]
}

export default function RsvpSendEmailCta({ tenant, guests }: RsvpSendEmailCtaProps) {
  const [show, setShow] = useState(false)

  const showModal = () => {
    if (guests.length === 0) {
      toastWarning("Seleziona degli invitati prima di inviare l'email!")
    } else if (!guests.some((x) => x.rsvp.email !== null)) {
      toastWarning(
        "Nessuno degli invitati selezionati ha un'email associata. Seleziona almeno 1 invitato che ti ha fornito l'email."
      )
    } else setShow(true)
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary button-style"
        style={{ marginRight: "10px" }}
        onClick={showModal}
      >
        <img alt="downloadIcon" src={emailIcon} style={{ marginRight: "10px" }} />
        Invia email
      </button>
      <RsvpSendEmailModal tenant={tenant} guests={guests} show={show} setShow={setShow} />
    </>
  )
}
