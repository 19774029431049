import { put, takeLatest, call } from "redux-saga/effects"
import {
  login as loginAction,
  loginError,
  loginStart,
  loginSuccess,
  logout as logoutAction,
  noLogin,
} from "./authSlice"
import authService from "./authService"
import { AccountInfo, BrowserAuthError, AuthError } from "@azure/msal-browser"
import { appInitAction } from "../../store"

/*
 * Handle login event from UI
 */
function* login() {
  yield put(loginStart())
  try {
    try {
      yield call([authService, authService.login])
    } catch (err) {
      if (err instanceof AuthError && err.errorCode === "access_denied" && err.errorMessage.includes("AADB2C90118"))
        yield call([authService, authService.resetPassword])
    }

    yield setAccount()
  } catch (error) {
    if (error instanceof BrowserAuthError && error.errorCode === "user_cancelled") {
      console.log("Login interrupted by the user")
      yield put(noLogin())
    } else {
      console.error(error)
      yield put(loginError(error as Error))
    }
  }
}

/**
 * APP INIT
 */
function* appInit() {
  yield put(loginStart())
  try {
    yield call([authService, authService.loadModule])
    yield setAccount()
  } catch (error) {
    console.error(error)
    yield put(loginError(error as Error))
  }
}

/**
 * Logout
 */
function* logout() {
  yield call([authService, authService.logout])
}

function* setAccount() {
  const account: AccountInfo | undefined = yield call([authService, authService.getAccount])
  if (account) {
    const idToken: {
      idToken: string
      idTokenClaims: {
        given_name: string
        family_name: string
        emails: Array<string>
      }
    } = yield call([authService, authService.getIdToken])
    const firstName = idToken.idTokenClaims.given_name
    const lastName = idToken.idTokenClaims.family_name
    yield put(
      loginSuccess({
        account,
        user: {
          email: idToken.idTokenClaims.emails[0],
          firstName,
          lastName,
          displayName: firstName + " " + lastName,
        },
      })
    )
  } else yield put(noLogin())
}

/**
 * SAGA event processor
 */
export default function* mainEventProcessor() {
  yield takeLatest(loginAction, login)
  yield takeLatest(appInitAction, appInit)
  yield takeLatest(logoutAction, logout)
}
